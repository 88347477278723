<template>
  <div class="youtube">
    <div class="youtube-header">
      <img loading="lazy" src="https://monglish.b-cdn.net/youtube/image.png">
    </div>
    <div class="youtube-title">
      <p>معرض الفيديوهات لدينا</p>
      <p class="bold">رحلة مميزة عبر الزمن</p>
    </div>
    <div class="youtube-video-container">
      <VideoThumbnail
        v-for="video in paginatedVideos"
        :key="video.video_id"
        :videoId="video.video_id"
        :description="video.description"
        :publish_date="video.publish_date"
      />
    </div>
    <div class="youtube-pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="move">&lt;</button>
      <button
        v-for="page in paginationPages"
        :key="page"
        @click="goToPage(page)"
        :class="{ active: currentPage === page && page !== '...', skip: page === '...' }"
      >
        {{ page }}
      </button>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="move">&gt;</button>
    </div>
  </div>
</template>

<script>
import VideoThumbnail from '../../components/en/YoutubeVideo.vue';
import jsonData from '../../assets/json/youtube-ar.json';

export default {
  components: {
    VideoThumbnail
  },
  data() {
    return {
      accreditationImages: [
        'https://monglish.b-cdn.net/youtube/image.png'
      ],
      currentStartIndex: 0,
      jsonData,
      currentPage: 1,
      videosPerPage: 12
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.jsonData.length / this.videosPerPage);
    },
    paginatedVideos() {
      const start = (this.currentPage - 1) * this.videosPerPage;
      const end = start + this.videosPerPage;
      return this.jsonData.slice(start, end);
    },
    paginationPages() {
      const pages = [];
      const prevPage = this.currentPage - 1;
      const nextPage = this.currentPage + 1;
      const lastPage = this.totalPages;

      if (this.totalPages > 1) {
        if (this.currentPage != 1) {
          pages.push(1);
        }
        if (this.currentPage > 3) {
          pages.push('...');
        }
        if (prevPage > 1) {
          pages.push(prevPage);
        }
        pages.push(this.currentPage);
        if (nextPage < this.totalPages) {
          pages.push(nextPage);
        }
        if (this.currentPage < this.totalPages - 2) {
          pages.push('...');
        }
        if (this.totalPages > 1 && this.currentPage != lastPage) {
          pages.push(lastPage);
        }
      }
      return pages;
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    goToPage(page) {
      if (page === '...') return;
      this.currentPage = page;
      this.scrollToTop();
    }
  }
};
</script>

<style scoped>
.youtube {
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  direction: rtl;
}
</style>
<style src="@/assets/styles/Youtube.css"></style>
