<template>
  <div id="app">
    <component :is="currentNavbar" />
    <WhatsappButton />
    <component :is="currentSideBar" />
    <router-view />
    <component :is="currentFooter" />
  </div>
</template>

<script>
import MonglishNavbarAr from "./components/ar/MonglishNavbar.vue";
import MonglishFooterAr from "./components/ar/MonglishFooter.vue";
import MonglishNavbarEn from "./components/en/MonglishNavbar.vue";
import MonglishFooterEn from "./components/en/MonglishFooter.vue";
import WhatsappButton from "./components/WhatsappButton.vue";
import SideBarAr from "./components/ar/SideBar.vue";
import SideBarEn from "./components/en/SideBar.vue";

export default {
  name: "App",
  data() {
    return {
      currentNavbar: "MonglishNavbarEn",
      currentFooter: "MonglishFooterEn",
      currentSideBar: "SideBarEn"
    };
  },
  watch: {
    $route(to) {
      if (to.path.startsWith("/ar")) {
        this.currentNavbar = "MonglishNavbarAr";
        this.currentFooter = "MonglishFooterAr";
        this.currentSideBar = "SideBarAr";
      } else {
        this.currentNavbar = "MonglishNavbarEn";
        this.currentFooter = "MonglishFooterEn";
        this.currentSideBar = "SideBarEn";
      }
    },
  },
  components: {
    MonglishNavbarAr,
    MonglishFooterAr,
    MonglishNavbarEn,
    MonglishFooterEn,
    WhatsappButton,
    SideBarEn,
    SideBarAr
  },
};
</script>

<style>
@font-face {
  font-family: "DIN Next LT Arabic";
  src: url("https://monglish.b-cdn.net/fonts/DINNextLTArabic-Regular-3.ttf")
    format("truetype");
}
@font-face {
  font-family: "DIN Next LT Arabic-n";
  src: url("https://monglish.b-cdn.net/fonts/DINNextLTArabic-Heavy.ttf")
    format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "DIN Next LT Arabic-n";
  src: url("https://monglish.b-cdn.net/fonts/DINNextLTArabic-Regular-3.ttf")
    format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "DIN Next LT Arabic-n";
  src: url("https://monglish.b-cdn.net/fonts/DINNextLTArabic-Light.ttf")
    format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "DIN Next LT Arabic-n";
  src: url("https://monglish.b-cdn.net/fonts/DINNextLTArabic-UltraLight.ttf")
    format("truetype");
  font-weight: 100;
}
</style>
