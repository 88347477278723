<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>رحلة حول العالم مع فصول السنة بالانجليزي!<br></p>

          <p>مرحبا يا أصدقاء المغامرات اللغوية! هل أنتم مستعدون لرحلة حول العالم؟ لا، لن نستقل طائرة ولا سنركب سفينة،
            إنها رحلة خاصة جداً… رحلة استكشافية للمواسم الأربعة باللغة الإنجليزية!</p>

          <p>سنتعلم معًا أسماء فصول السنة بالانجليزي ونكتشف جمال كل فصل بطريقة مسلية وممتعة! سنتعلم ايضا كيف تصف كل حالة
            طقس خاصة بكل فصل، فاستعدوا لحقائب مليئة بالحب والاستمتاع!</p>

          <p><b>1. الربيع يبتسم: Spring is here!</b></p>

          <p>الربيع هو موسم البدايات الجديدة! تشرق الشمس الدافئة (sunny) وتتلألأ الزهور الملونة (colourful flowers).
            نسمات الهواء لطيفة (gentle breeze) والطيور تغرد بسعادة (birds sing happily). كل شيء حولنا يبدو مليئًا
            بالحيوية (full of life) ونحن نرتدي ملابس خفيفة (light clothes) لنستمتع بالطقس الجميل.، وكأن الطبيعة تقول
            مرحبا للحياة من جديد!</p>

          <p><b>كلمات مفيدة بالانجليزي عن الربيع:</b></p>

          <ul>
            <li aria-level="1">Spring: ربيع</li>
            <li aria-level="1">Flower: زهرة</li>
            <li aria-level="1">Butterfly: فراشة</li>
            <li aria-level="1">Bird: طائر</li>
            <li aria-level="1">Sunny: مشمس</li>
            <li aria-level="1">Rain: مطر</li>
            <li aria-level="1">Windy: عاصف</li>
            <li aria-level="1">Warm: دافئ</li>
            <li aria-level="1">Cool: لطيف</li>
          </ul>

          <p><b>نشاط:</b> اخرجوا إلى الحديقة مع عائلتكم وتابعوا التغيرات الجميلة التي تحدث في فصل الربيع! شاهدوا أنواع
            الزهور المختلفة ولاحظوا كم هي لطيفة!</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog3-1.webp" alt="Blog Image11">

          <p><b>2. صيف حار: Summer fun!</b></p>

          <p>الصيف هو موسم اللعب! يأتي الصيف حاملاً معه أشعة الشمس القوية (hot sun) والبحر المتلألئ (sparkling sea).
            نذهب للسباحة (swimming) وبناء قلاع الرمل (sandcastles) وتناول الآيس كريم اللذيذ (delicious ice cream).
            ليلاً، نجلس تحت النجوم المتلألئة (twinkling stars) ونستمع إلى قصص ومغامرات الأصدقاء والعائلة ونقضي وقتًا
            ممتعًا&nbsp;</p>

          <p><b>كلمات مفيدة بالانجليزي عن الصيف:</b></p>

          <ul>
            <li aria-level="1">Summer: صيف</li>
            <li aria-level="1">Beach: شاطئ</li>
            <li aria-level="1">Sun: شمس</li>
            <li aria-level="1">Hot: حار</li>
            <li aria-level="1">Ice cream: آيس كريم</li>
            <li aria-level="1">Vacation: إجازة</li>
          </ul>

          <p><b>نشاط:</b> قوموا برسم لوحة جميلة عن فصل الصيف! استخدموا الألوان الزاهية لتعبروا عن حرارة الشمس وبرودة
            البحر ومرح الألعاب الصيفية.</p>

          <p><b>3. خريف راقص: Autumn leaves falling!</b></p>

          <p>الخريف هو موسم تغير الأوراق! تصبح الأشجار ملونةً بالدرجات الذهبية والبرتقالية والبنية (golden, orange, and
            brown leaves). نسير على أوراق الشجر المتساقطة ونستمتع بألوانها الرائعة (falling leaves) ونستمتع بطعم الفواكه
            اللذيذة التي تنضج في هذا الفصل (delicious fruits). الطقس لطيف (pleasant weather) والأيام أقصر (shorter
            days)، لكنها مليئة بالسحر والحيوية.</p>

          <p><b>كلمات انجليزية مهمة عن الخريف:</b></p>

          <ul>
            <li aria-level="1">Autumn: خريف</li>
            <li aria-level="1">Leaf: ورقة</li>
            <li aria-level="1">Fall: خريف (يسقط فيه الورق)</li>
            <li aria-level="1">Red: أحمر</li>
            <li aria-level="1">Orange: برتقالي</li>
            <li aria-level="1">Yellow: أصفر</li>
            <li aria-level="1">Windy: عاصف</li>
            <li aria-level="1">Crisp: صافي</li>
          </ul>

          <p><b>نشاط:</b> اجمعوا أوراق الشجر الملونة واصنعوا منها تشكيلات فنية جميلة! استخدموا خيالكم وحوّلوها إلى
            حيوانات أو زهور أو أي شيء يحلو لكم!</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog3-2.webp" alt="Blog Image11">

          <p><b>4. شتاء أبيض: Winter wonderland!</b></p>

          <p>الشتاء هو موسم الثلج! تصبح الأرض بيضاء كأنها مغطاة ببحر من القطن (white snow) . نلعب بالثلج ونصنع رجال
            الثلج ونقرأ الكتب بجوار المدفأة (warm fireplace) . نرتدي الملابس الدافئة ونشرب الشوكولاتة الساخنة (hot
            chocolate) ونستمتع بأجواء الشتاء الدافئة.</p>

          <p><b>كلمات انجليزية مهمة عن الشتاء:</b></p>

          <ul>
            <li aria-level="1">Winter: شتاء</li>
            <li aria-level="1">Snow: ثلج</li>
            <li aria-level="1">Cold: بارد</li>
            <li aria-level="1">Snowman: رجل ثلج</li>
            <li aria-level="1">Hot chocolate: شوكولاتة ساخنة</li>
            <li aria-level="1">Mittens: قفازات</li>
            <li aria-level="1">Snowy: ثلجي</li>
            <li aria-level="1">Icy: جليدي</li>
            <li aria-level="1">Windy: عاصف</li>
            <li aria-level="1">Rainy: ممطر</li>
          </ul>

          <p><b>نشاط:</b> اخبزوا كعكة على شكل شجرة عيد الميلاد أو رجل ثلج! استمتعوا بطعمها اللذيذ وشاركوها مع الأصدقاء
            والعائلة.</p>

          <p>ها قد انتهت رحلتنا الاستكشافية! تعلمنا الكثير من الكلمات الجديدة وقد اكتشفنا فصول السنة الأربعة بالانجليزي
            بطريقة ممتعة! تذكروا أن تعلم اللغة الإنجليزية يمكن أن يكون مسليًا ومفيدًا جدًا. استمروا في ممارسة اللغة
            وتعرفوا على معلومات جديدة كل يوم!</p>

          <p><strong>اتمنى أن تكون هذه الرحلة الصغيرة مع فصول السنة بالانجليزي قد أعجبتكم! لا تترددوا في مشاركة هذا
              المقال مع الأصدقاء. حتى المرة القادمة!&nbsp;</strong></p>

        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "رحلة حول العالم مع فصول السنة بالانجليزي!",
      date: "01/22/2024",
      img: 'https://monglish.b-cdn.net/blogs/blog3.webp',
      category: "عالم القراءة والكتابة",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>