<template>
  <div class="immerse-sec1">
    <div class="immerse-sec1-content">
      <h2>منهج المعايشة</h2>
      <p>اختر منهج المعايشة في مونجلش لتجربة تعليمية استثنائية في اللغة الإنجليزية، حيث تكتسب اللغة بشكل يومي وتطبقها
        بثقة في الحياة العملية والأكاديمية. سواء كنت مبتدئًا أو متقدمًا، نقدم لك دورات تفاعلية ومتنوعة تلائم جدولك
        اليومي، مما يضمن تطوير مهاراتك اللغوية بفعالية.</p>
    </div>
  </div>

  <div class="immerse-sec2">
    <div class="immerse-row">
      <h3>تعرف علي أندية مونجلش التفاعلية</h3>
      <p>اكتشف منهج المعايشة الفريد في مونجلش، حيث نغمركم بالكامل في عالم اللغة الإنجليزية. تجربة التعلم لدينا تتخطى
        الحدود التقليدية، مع التفاعل المكثف والمستمر مع الإنجليزية في سيناريوهات حياتية واقعية، مما يضمن تعزيز الفهم
        العميق وسلاسة اتقان الغة.</p>
    </div>

    <ImmerseClubs />
  </div>

  <div class="video-immerse">
    <div class="content-slider-container">
      <!-- Text Content Section -->
      <div class="text-content">
        <h2>تجربة تعلّم كلها فرحة</h2>
        <p>هل تبحث عن طريقة مبتكرة لتعلم اللغة الإنجليزية؟ أكاديمية مونجلش هي المكان المثالي لك! نقدم لك منهجًا تعليميًا
          متطورًا يعتمد على أحدث التقنيات وأكثرها تفاعلية. من خلال استخدام أساليب تدريس مبتكرة، نجعل التعلم تجربة ممتعة
          ومحفزة. سواء كنت مبتدئًا أو متقدمًا، ستجد في أكاديميتنا كل ما تحتاجه لتحقيق أهدافك. </p>
      </div>
      <!-- Slider Section -->
      <div class="video-slider-component" ref="videoWrapper">
        <div class="slider-container">
          <button class="slider-nav left" @click="prevSlide">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </button>
          <div class="image-container">
            <img :src="currentImage.src" :alt="currentImage.alt" @click="openPopup(currentImage.videoSrc)"
              class="image-thumbnail" />
            <svg height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 491.444 491.444" xml:space="preserve"
              fill="#000000" class="video-icon">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path style="fill:#FF9D52;"
                  d="M0.11,245.721c-1.434,61.225,11.068,122.791,39.645,145.883c80.101,64.726,331.832,64.726,411.932,0c28.577-23.093,41.079-84.659,39.646-145.883H0.11z">
                </path>
                <path style="fill:#F47D21;"
                  d="M451.687,105.91c-72.82-72.82-339.111-72.82-411.932,0C15.231,130.434,1.463,187.925,0.11,245.721h491.223C489.98,187.925,476.213,130.434,451.687,105.91z">
                </path>
                <path style="fill:#FFFFFF;"
                  d="M320.941,232.105L190.51,136.284c-11.159-8.199-26.9-0.231-26.9,13.617v95.82h264.225C327.834,240.6,325.537,235.481,320.941,232.105z">
                </path>
                <path style="fill:#FFFFFF;"
                  d="M190.51,355.161l130.432-95.823c4.595-3.375,6.893-8.496,6.893-13.617H163.609v95.824C163.609,355.391,179.351,363.359,190.51,355.161z">
                </path>
              </g>
            </svg>
          </div>

          <button class="slider-nav right" @click="nextSlide">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Popup Overlay -->
      <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
        <div class="popup-content" @click.stop>
          <button class="close-button" @click="closePopup">&times;</button>
          <iframe v-if="currentVideoSrc" :src="currentVideoSrc" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen class="popup-video"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="immerse-sec4">
    <div class="immerse-sec4-content">
      <div class="immerse-sec4-text">
        <h2>حيث يلتقي التميز التعليمي بالإبتكار والتقدم اللغوي</h2>
        <p>باختيارك أكاديمية مونجلش، تستفيد من منهجية تعليمية مبتكرة، دورات متنوعة تلائم كل الفئات العمرية، وإرشاد
          متخصص يضمن تحقيقك لأعلى مستويات الإنجاز في مسارك اللغوي والمهني.</p>

        <div @click="toggleDescription(1)" class="immerse-sec4-title" :class="{ active: activeDescription === 1 }">
          <span>شمول الدبلومات</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 1">
          في أكاديمية مونجلش الدولية، نركز على التعليم المستدام عبر دبلومات متكاملة لكل الأعمار، مع تحديثات مستمرة
          لتعزيز المسارات الأكاديمية والمهنية للمتدربين. نوفر دورات احترافية تتناسب مع كافة المستويات العمرية، مما
          يعزز مكانة خريجينا في الحياة والعمل. بيئتنا التعليمية الراقية وفريقنا المتخصص يدعمان الطلاب في تحقيق التميز
          اللغوي والمهني.
        </p>

        <div @click="toggleDescription(2)" class="immerse-sec4-title" :class="{ active: activeDescription === 2 }">
          <span>منهجنا مُبتكر</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 2">
          انغمس في رحلة تعلم اللغة الإنجليزية مع منهج المعايشة المبتكر من مونجلش، حيث نجمع بين المتعة والفعالية لتعلم
          الإنجليزية كلغة ثانية بسرعة تفوق التوقعات. في مونجلش، ستلتقي بمعلمين متمرسين يشاركونك خبراتهم بحماس، موفرين
          لك الأدوات اللازمة لإتقان اللغة بكفاءة. نتميز بابتكارنا المستمر، حيث نواكب أحدث الطرق التعليمية ونطبق حلولاً
          مبتكرة للتحديات التي تواجه الطلاب. منهجنا الشامل يتضمن أنشطة متنوعة في الاستماع، القراءة، الكتابة، والتحدث،
          ويقدم تدريبات مصممة لجميع المستويات. نحن نقدم تقييمات مخصصة تساعد الطلاب على التقدم بسلاسة ومتعة عبر مختلف
          مستويات اللغة، مهما كان مستواهم الحالي، ونضمن تطورهم المستمر والسريع في إتقان اللغة الإنجليزية.
        </p>

        <div @click="toggleDescription(3)" class="immerse-sec4-title" :class="{ active: activeDescription === 3 }">
          <span>النوادي التفاعلية</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 3">
          في مونجلش، نفخر بتقديم مجموعة واسعة ومتنوعة من النوادي المتخصصة في تعلم اللغة الإنجليزية، والتي تشمل كل شيء
          من نوادي القراءة، الكتابة، والمحادثة إلى نوادي السينما، المناظرات، والمواهب. نقدم أيضًا نوادي متخصصة مثل
          نادي الصوتيات، الكتاب، القواعد، مسابقات اللهجات، نادي الرسم، وحتى نادي الأم الاجتماعي. كل نادٍ يُعزز من
          الآخر، موفرًا تجربة تعليمية شاملة تضمن الوصول إلى أعلى النتائج وتجعلنا الأكاديمية الرائدة لتعلم الإنجليزية.
          نادينا الاجتماعي الفريد للأمهات يوفر مساحة للتواصل ومشاركة التحديات في بيئة داعمة وترفيهية. هذه النوادي
          الحصرية تُمكّننا من تقديم تجربة تعلم استثنائية، ما يجعل مونجلش الوجهة المثالية لتعلم اللغة الإنجليزية عبر
          الإنترنت.
        </p>
      </div>
      <div class="immerse-sec4-images">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/immerse_sec4.jpg" alt="Immerse Section">
      </div>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="progress" :style="{ height: progressHeight + '%' }"></div>
    </div>
  </div>

  <!--Section Four Content-->
  <div class="kids-sec4">
    <h3>معتمدون دوليا</h3>
    <h2>والجودة العالية في كل خطوة</h2>
    <p>أكاديمية مونجلش الدولية معتمدة دولياً من مؤسسات بارزة مثل المنظمة الدولية لجودة التدريب (IOTQ) وحاصلة على شهادة
      ISO 9001:2015 من الاتحاد الأمريكي للاعتماد (UAF). نحن أيضاً أعضاء في الجمعية الأمريكية للاعتماد (AAA)، الجمعية
      المصرية للجودة (ESQ)، ومنظمة الجودة العربية (AROQA) تحت مظلة طلال أبو غزالة (TAG-ORG)، مما يعكس التزامنا بالمعايير
      العالمية للتعليم العالي.</p>
    <div class="kids-sec4-imgs">
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/6.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/3.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/2.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/1.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/4.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/7.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/5.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/9.webp" />
    </div>
  </div>

  <ImageVideoSlider />
</template>

<script>
import ImmerseClubs from '../../components/ar/ImmerseClubs.vue';
import ImageVideoSlider from '../../components/ar/ImageVideoSlider2.vue';

export default {
  name: 'MonglishImmerse',
  components: {
    ImmerseClubs,
    ImageVideoSlider
  },
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      currentSlide: 0,
      images: [
        {
          videoSrc: 'https://www.youtube.com/embed/4qZMlbkrxLo?autoplay=1',
          src: 'https://monglish.b-cdn.net/video/13.webp',
          alt: 'Video 13 Thumbnail',
        },
        {
          videoSrc: 'https://www.youtube.com/embed/XZGeJQK7yxo?autoplay=1',
          src: 'https://monglish.b-cdn.net/video/14.webp',
          alt: 'Video 14 Thumbnail',
        },
      ],
      activeDescription: null,
      totalSections: 3,
      currentIndex: 0,
      isTransitioning: false,
      videos: [
        {
          src: 'https://www.youtube.com/embed/TOQPuaK2_Xw',
          loaded: false
        },
        {
          src: 'https://www.youtube.com/embed/JqnRuJf3gZE',
          loaded: false
        },
        {
          src: 'https://www.youtube.com/embed/_FOV2Spjsy4',
          loaded: false
        },
      ]
    };
  },
  computed: {
    displayedVideos() {
      const totalVideos = this.videos.length;
      return [
        this.videos[(this.currentIndex - 1 + totalVideos) % totalVideos],
        this.videos[this.currentIndex],
        this.videos[(this.currentIndex + 1) % totalVideos]
      ];
    },
    currentImage() {
      return this.images[this.currentSlide];
    },
  },
  methods: {
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.images.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.images.length) % this.images.length;
    },
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    slide(direction) {
      if (this.isTransitioning) return;
      this.isTransitioning = true;

      const wrapper = this.$refs.videoWrapper;
      const slideWidth = wrapper.clientWidth;
      const totalVideos = this.videos.length;

      if (direction === 'right') {
        this.currentIndex = (this.currentIndex + 1) % totalVideos;
        wrapper.style.transform = `translateX(-${slideWidth}px)`;
      } else {
        this.currentIndex = (this.currentIndex - 1 + totalVideos) % totalVideos;
        wrapper.style.transform = `translateX(${slideWidth}px)`;
      }

      setTimeout(() => {
        wrapper.style.transition = 'none';
        wrapper.style.transform = 'translateX(0)';
        setTimeout(() => {
          wrapper.style.transition = 'transform 0.5s ease-in-out';
          this.isTransitioning = false;
        }, 50);
      }, 500);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapper = this.$refs.videoWrapper;
      wrapper.style.width = '100%';
      wrapper.style.display = 'flex';
      wrapper.style.transition = 'transform 0.5s ease-in-out';
    });
  }
}
</script>

<style scoped>
/*Section 1*/
.immerse-sec1 {
  position: relative;
  color: white;
  text-align: center;
  background-image: url('https://monglish.b-cdn.net/home/immerse_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -85px;
  padding-left: 5%;
  padding-right: 5%;
}

.immerse-sec1-content {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 80%;
  padding-top: 120px;
}

.immerse-sec1 h2 {
  font-size: 64px;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  line-height: 94.08px;
}

.immerse-sec1 p {
  font-size: 22px;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  margin: 0 auto;
  line-height: 32.34px;
}

@media (max-width: 768px) {
  .immerse-sec1 {
    background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
    height: auto;
    padding: 0;
  }

  .immerse-sec1::before {
    content: '';
    background-image: url('https://monglish.b-cdn.net/home/immerse_bg.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 80vh;
    display: block;
  }

  .immerse-sec1-content {
    max-width: 100%;
    text-align: center;
    margin-top: -4em;
  }

  .immerse-sec1 h2 {
    font-size: 1.75rem;
    line-height: 50px;
  }

  .immerse-sec1 p {
    font-size: 1rem;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 23px;
  }
}

/*Section 2*/
.immerse-sec2 {
  padding: 1em 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.immerse-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2em;
  margin: 1em 0;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.immerse-row h3 {
  color: #165e84;
  font-size: 42px;
  font-family: 'DIN Next LT Arabic';
  line-height: 70.56px;
}

.immerse-row p {
  font-size: 20px;
  color: gray;
  font-weight: 300;
  max-width: 70%;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .immerse-row {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }

  .immerse-row h3 {
    width: 25%;
    margin: 0;
    padding-right: 50px;
  }

  .immerse-row p {
    width: 80%;
    margin: 0;
    padding-left: 25px;
  }
}

@media (max-width: 769px) {
  .immerse-row {
    gap: 10px;
  }

  .immerse-row h3 {
    padding: 0 5%;
    font-size: 1.75rem;
    line-height: 40px;
  }

  .immerse-row p {
    font-size: 1.3rem;
    line-height: 27px;
    padding: 0;
  }
}

/*Section 3*/
.video-immerse {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  padding-left: 2em;
  padding-right: 2em;
}

.video-immerse h3 {
  text-align: center;
  font-size: 2.6em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: white;
  padding-bottom: 1em;
}

.immerse-video-frame {
  width: 100%;
  max-width: 890px;
  height: calc(56.25vw);
  max-height: 500px;
  border-radius: 25px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .video-immerse {
    padding: 2em;
  }

  .video-immerse h3 {
    font-size: 2em;
  }

  .immerse-video-frame {
    height: calc(56.25vw);
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .video-immerse {
    padding: 1.5em;
  }

  .video-immerse h3 {
    font-size: 1.6em;
  }

  .immerse-video-frame {
    height: calc(56.25vw);
    max-height: 220px;
  }
}

/*Section 4*/
.immerse-sec4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #4e5354;
  position: relative;
}

.immerse-sec4-content {
  display: flex;
  max-width: 1300px;
  margin: auto;
  padding: 20px;
}

.immerse-sec4-images img {
  width: 100%;
  height: auto;
}

.immerse-sec4-text {
  padding: 20px;
  max-width: 700px;
}

.immerse-sec4-text h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  max-width: 80%;
  color: #165e84;
  font-family: 'DIN Next LT Arabic';
}

.immerse-sec4-text .immerse-sec4-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
  cursor: pointer;
  color: #6f8286;
  font-family: 'DIN Next LT Arabic';
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.immerse-sec4-text .immerse-sec4-title.active {
  color: #ff6f00;
}

.immerse-sec4-text .immerse-sec4-title.active {
  color: #ff6f00;
}

.immerse-sec4-text .caret svg path {
  fill: #6f8286;
  transition: fill 0.3s ease;
}

.immerse-sec4-text .immerse-sec4-title.active .caret svg path {
  fill: #ff6f00;
}

.immerse-sec4-text .caret {
  transition: transform 0.3s ease;
}

.immerse-sec4-text .immerse-sec4-title.active .caret {
  transform: rotate(180deg);
}

.immerse-sec4-text p {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6;
}

@media (max-width: 769px) {
  .immerse-sec4-content {
    width: 100%;
    padding: 5px;
  }

  .immerse-sec4-images img {
    display: none;
  }

  .immerse-sec4-text {
    width: 100%;
    padding: 0;
  }

  .immerse-sec4-text h2 {
    text-align: center;
    max-width: 100%;
    font-size: 1.75rem;
    line-height: 40px;
  }

  .immerse-sec4-title span {
    font-size: 1.3rem;
    line-height: 30px;
  }

  .caret svg {
    width: 16px;
    height: 16px;
  }
}

/*section 6*/
.home-sec4 {
  background-color: #fff;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em;
}

.t1 {
  color: #165e84;
  font-size: calc(25px + 1vw);
  font-weight: 600;
  line-height: 100%;
  padding: 0 1em 1em 1em;
}

.video-slider {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 60%;
  margin: 0 auto;
  border-radius: 25px;
}

.video-wrapper-immerse {
  display: flex;
  width: 50%;
}

.video-frame-home {
  flex: 0 0 100%;
  border: none;
  height: 50vw;
  max-height: 500px;
  overflow: hidden;
}

.slider-nav {
  color: #165e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  color: #2178a6;
}

.slider-nav:active {
  color: #fff;
}

.size-6 {
  width: 24px;
  height: 24px;
}

.player .vp-video-wrapper-immerse .vp-preview {
  border-right: 20px;
}

@media (max-width: 1000px) {
  .home-sec4 {
    border-radius: 0;
  }

  .video-frame-home {
    width: 80%;
    margin-inline: 10%;
    flex: 0 0 80%;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }
}

.content-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  direction: rtl;
  flex-wrap: wrap;
  /* Add wrap to handle content better on smaller screens */
}

.text-content {
  flex: 1;
  padding-right: 5%;
}

.text-content h2 {
  color: white;
  font-size: 48px;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  margin-bottom: 3%;
}

.text-content p {
  color: white;
  font-size: 20px;
  width: 70%;
}

.text-content button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 40%;
  cursor: pointer;
  font-size: 20px;
}

.video-slider-component {
  flex: 1;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-thumbnail {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.image-container:hover .video-icon {
  transform: translate(-50%, -50%) scale(1.5);
}

.slider-nav {
  color: white;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  color: #2178a6;
}

.slider-nav:active {
  color: #fff;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #d9534f;
}

@media (max-width: 768px) {
  h2 {
    font-size: 30px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  img {
    width: 80%;
    height: auto;
  }

  .content-slider-container {
    display: block;
  }

  .text-content {
    padding-right: 0;
    text-align: center;
  }

  .text-content h2 {
    font-size: 36px;
  }

  .text-content p {
    font-size: 18px;
    width: 90%;
    margin: 0 auto;
  }

  .text-content button {
    width: 80%;
    font-size: 18px;
  }

  .image-thumbnail {
    width: 100%;
  }
}

/* Section 5 style */
.kids-sec4 {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  direction: rtl;
  text-align: center;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2em 2em 8em 2em;
}

.kids-sec4 h3 {
  font-size: 2.5rem;
  padding: 3% 0 0 0;
}

.kids-sec4 h2 {
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.kids-sec4 p {
  font-size: 1.2rem;
  padding: 1% 20% 5% 20%;
}

.kids-sec4-imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.kids-sec4-imgs img {
  width: 9%;
}

@media (max-width: 1200px) {
  .kids-sec4 h3 {
    font-size: 2.2rem;
  }

  .kids-sec4 h2 {
    font-size: 2.5rem;
  }

  .kids-sec4 p {
    font-size: 1.1rem;
    padding: 1% 15% 5% 15%;
  }

  .kids-sec4-imgs img {
    width: 12%;
  }
}

@media (max-width: 992px) {
  .kids-sec4 h3 {
    font-size: 2rem;
  }

  .kids-sec4 h2 {
    font-size: 2.2rem;
  }

  .kids-sec4 p {
    font-size: 1rem;
    padding: 1% 10% 5% 10%;
  }

  .kids-sec4-imgs img {
    width: 15%;
  }
}

@media (max-width: 768px) {
  .kids-sec4 h3 {
    font-size: 1.8rem;
  }

  .kids-sec4 h2 {
    font-size: 2rem;
  }

  .kids-sec4 p {
    font-size: 0.9rem;
    padding: 1% 5% 5% 5%;
  }

  .kids-sec4-imgs img {
    width: 20%;
  }
}

@media (max-width: 576px) {
  .kids-sec4 h3 {
    font-size: 1.5rem;
  }

  .kids-sec4 h2 {
    font-size: 1.8rem;
  }

  .kids-sec4 p {
    font-size: 0.85rem;
    padding: 1% 3% 5% 3%;
  }

  .kids-sec4-imgs img {
    width: 25%;
  }
}

@media (max-width: 400px) {
  .kids-sec4 h3 {
    font-size: 1.2rem;
  }

  .kids-sec4 h2 {
    font-size: 1.5rem;
  }

  .kids-sec4 p {
    font-size: 0.75rem;
    padding: 1% 2% 5% 2%;
  }

  .kids-sec4-imgs img {
    width: 30%;
  }
}
</style>
