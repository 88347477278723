<template>
  <div class="blog-slider">
    <a href="/ar/blog">
      <button class="button-link">{{ category }}</button>
    </a>
    <h2 class="blog-view-title" v-html="title"></h2>
    <div class="date">
      <p v-html="date"></p>
    </div>

    <div class="image-container">
      <img loading="lazy" :src="img" alt="Blog Image" class="blog-view-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogHead",
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.blog-slider {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  height: auto; 
  background-image: url('https://monglish.b-cdn.net/blogs/bg.webp');
  background-size: cover;
  overflow: visible;
  position: relative;
  margin-top: -85px;
  direction: rtl;
  padding: 140px 15% 0 15%;
  color: white;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.button-link {
  align-self: flex-start;
  margin-bottom: 10px;
  background-color: #0000003B;
  font-size: bold;
  border-radius: 25px;
  color: white;
  text-align: right;
  padding: 8px 25px 12px 25px;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border: none;
  cursor: pointer;
}
.blog-view-title {
  margin-bottom: 5px;
  font-size: 3.5em;
}
.date {
  margin-bottom: 20px;
  font-size: 1rem;
}
.image-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  margin-bottom: -5%;
}
.blog-view-image {
  width: 100%;
  height: 100%;
  /* max-height: 450px; */
  object-fit: cover;
  border-radius: 25px;
}
.spacer {
  height: 50px;
}
</style>
