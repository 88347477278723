<template>
  <section class="content-slider-container">
    <div class="wrapper">
      <div class="immerse-row">
        <h3>
          خبراء تعليم <br />
          <span>اللغة الانجليزية</span>
        </h3>
        <p>
          خلف كل نجاح يقف فريقنا الاكاديمي المعزز بأعلى معايير الجودة
          والإحترافية. أكثر من 300 شريك من نخبه المعلمين المتخصصين فى توفير
          تجربه تعليميه لا مثيل لها فى مجال تعليم اللغة الإنجليزية. نحن هنا
          لنصنع معًا مستقبلاً مشرقًا بالمعرفة.
        </p>
      </div>

      <div class="teachers-grid">
        <div v-for="teacher in teachers" :key="teacher.id" class="teacher-card">
          <div class="teacher-info">
            <img
              loading="lazy"
              :src="teacher.src"
              :alt="`صورة ${teacher.name}`"
              class="teacher-image"
            />
            <h2>{{ teacher.name }}</h2>
            <p>{{ truncateDesc(teacher.desc) }}</p>
            <a :href="teacher.link" class="btn-view-more" target="_blank">
              الصفحة الشخصية
              <i class="fa-solid fa-circle-arrow-left icon"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.content-slider-container {
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  background-image: url("https://monglish.b-cdn.net/home/bg.jpg");
  background-size: cover;
  background-position: center;
  direction: rtl;
  overflow-y: hidden;
  margin-top: -85px;
}
.wrapper {
  margin-top: 55px;
  background-color: white;
}
.immerse-row {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  gap: 2em;
  margin: 1em 0 2em 0;
  font-family: "DIN Next LT Arabic", sans-serif;
  position: relative;
}
.immerse-row h3 {
  padding-top: 1em;
  color: #165e84;
  font-size: 2.8rem;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.immerse-row h3 span {
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.immerse-row p {
  font-size: 1.375rem;
  color: gray;
  font-weight: 500;
  padding: 0 15%;
  margin: 0 auto;
}
.immerse-row button {
  background-color: transparent;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  color: #165e84;
  font-size: 1.4rem;
  border: 1px solid #165e84;
  border-radius: 25px;
  padding: 0.1em 3em 0.4em 3em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin-left: 50px;
}
.immerse-row button:hover {
  background-color: #165e84;
  color: #fff;
}
@media (max-width: 768px) {
  .immerse-row {
    padding: 0 20px;
    gap: 1em;
  }

  .immerse-row h3 {
    font-size: 2.2rem;
    text-align: center;
    width: 100%;
  }

  .immerse-row p {
    font-size: 1.2rem;
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }

  .immerse-row button {
    position: relative;
    margin-left: 0;
    margin-top: 1em; 
    transform: none; 
    width: auto; 
    padding: 0.5em 2.5em; 
    left: auto;
    top: auto;
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .immerse-row h3 {
    font-size: 1.8rem;
  }

  .immerse-row p {
    font-size: 1.1rem;
  }

  .immerse-row button {
    font-size: 1.2rem;
  }
}

.teachers-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 5%;
}

.teacher-card {
  background-color: #f2f4f5;
  border-radius: 20px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  direction: rtl;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.teacher-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1em;
}

.teacher-info {
  text-align: right;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  width: 100%;
}

.teacher-info h2 {
  font-size: 1.8rem;
  color: #283646;
  margin-bottom: 0.5em;
}

.teacher-info p {
  font-size: 1.2rem;
  color: #77808b;
  margin-bottom: 1em;
}

.btn-view-more {
  background-color: #ffffff;
  color: #000;
  font-size: 1.3rem;
  border: 1px solid #f47d21;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 0.5em 2em;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 0;
}

.btn-view-more:hover {
  background-color: #f47d21;
  color: #ffffff;
}

.icon {
  color: #f47d21;
  margin-right: 10px;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

a.btn-view-more:hover .icon {
  color: #ffffff;
}

@media (max-width: 1200px) {
  .teachers-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 900px) {
  .teachers-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }
}

@media (max-width: 600px) {
  .teachers-grid {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }
}
</style>

<script>
export default {
  data() {
    return {
      teachers: [
        {
          src: "https://monglish.b-cdn.net/experts/13.jpg",
          name: "نورة الشريف",
          desc: "اسمي الدكتورة نورة، وأنا معلمة لغة إنجليزية ذات خبرة تزيد عن 15 عامًا في هذا المجال. أحمل درجة الدكتوراه والماجستير في القيادة والإدارة التربوية من كلية كامبريدج في المملكة المتحدة. تشمل مؤهلاتي المهنية شهادة CELTA وDELTA الوحدة 1 من كامبريدج، وشهادة TKT لتعليم الصغار (YL) من كامبريدج، بالإضافة إلى دبلوم المستوى السادس في TESOL المعتمد من OFQUAL في المملكة المتحدة. كما أنني مدربة معتمدة في إعداد اختبار IELTS، مما يعكس التزامي بالتميز في تعليم اللغة وإعداد الطلاب للاختبارات.",
          link: "/ar/teachers/thirteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/10.jpg",
          name: "عبدالرحمن عيد",
          desc: "مرحبًا.. أنا عبدالرحمن، مدرس لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية اللغات والترجمة بجامعة فاروس، وأحمل دبلومة في تدريس اللغة الإنجليزية كلغة أجنبية من كلية نوتينغ هيل في المملكة المتحدة. لدي خبرة 5 سنوات في تدريس اللغة الإنجليزية لطلاب من خلفيات متنوعة، وأحرص على جلب نقاط قوة ومنظورات فريدة إلى الفصل الدراسي. أنا ملتزم بتمكين طلابي من الوصول إلى أقصى إمكاناتهم، وأجعل التعلم فعالًا وممتعًا من خلال مجموعة متنوعة من الأنشطة التفاعلية والسيناريوهات الواقعية والموارد متعددة الوسائط.",
          link: "/ar/teachers/ten",
        },
        {
          src: "https://monglish.b-cdn.net/experts/26.jpg",
          name: "إسراء عيد",
          desc: "مرحبًا! أنا إسراء، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. أحمل درجة في التربية تخصص لغة إنجليزية وشهادة TEFL، ولدي خبرة تزيد عن عام في تدريس اللغة الإنجليزية عبر الإنترنت. تركز حصصي على التعلم التعاوني ونهج يركز على الطلاب، مما يخلق بيئة ترحيبية تمكّن الطلاب من الممارسة والتعبير عن أنفسهم بثقة. أتطلع لبدء هذه الرحلة الممتعة لتعلم اللغة معكم!",
          link: "/ar/teachers/twenty-six",
        },
        {
          src: "https://monglish.b-cdn.net/experts/11.jpg",
          name: "إسراء محمد",
          desc: "أنا إسراء محمد، معلمة لغة إنجليزية كلغة ثانية (ESL/EFL) في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب، قسم اللغة الإنجليزية، عام 2020، وأعمل كمعلمة لغة إنجليزية منذ 4 سنوات حتى الآن. أحمل شهادة TEFL من جهة معتمدة. أحرص على بناء علاقة قوية مع جميع طلابي في كل حصصي، وهذا ما يجعلهم يعودون باستمرار ويحرصون على تحقيق أفضل النتائج في رحلتهم لتعلم اللغة الإنجليزية. أستخدم تقنيات تدريس تعتمد على الألعاب لتحفيز الطلاب وجعل عملية التعلم ممتعة ومثيرة للاهتمام. إذا كنت تبحث عن طرق تعليم جديدة بعيدًا عن الملل، فإن حصصي الدراسية هي الوجهة المثالية. ماذا تنتظر؟!",
          link: "/ar/teachers/eleven",
        },
        {
          src: "https://monglish.b-cdn.net/experts/28.jpg",
          name: "اماني محمد",
          desc: "أنا أماني، معلمة شغوفة أحب التدريس والتواصل مع الناس والطلاب. تخرجت من كلية التربية، قسم اللغة الإنجليزية، وأعمل في مجال التدريس منذ 11 عامًا. قمت بتدريس العديد من الدورات، مثل TOEFL، ومهارات التحدث، والصوتيات، والمحادثة، لطلاب الجامعات والأطفال. هدفي أن أكون معلمة لا تُنسى، وأن أوصل المعلومات لطلابي بأفضل طريقة ممكنة.",
          link: "/ar/teachers/twenty-eight",
        },
        {
          src: "https://monglish.b-cdn.net/experts/17.jpg",
          name: "عمر جمال",
          desc: "مدرس لغة إنجليزية ذو خبرة ست سنوات، أحرص على تقديم حصص ديناميكية تركز على الطلاب من خلال دمج نظريات اللغة مع أنشطة عملية ممتعة. أخلق بيئة داعمة تتيح للطلاب ممارسة التحدث والاستماع والقراءة والكتابة بثقة. باستخدام دروس تفاعلية وسيناريوهات حياتية وسرد القصص، أسعى لبناء أساس قوي في القواعد والمفردات والنطق، مع التركيز على تطوير مهارات التواصل عبر أنشطة مثل لعب الأدوار والألعاب والمشاريع الجماعية. تُصمم كل حصة وفق احتياجات الطلاب الفردية لضمان تقدمهم بثقة ونجاح في التواصل الأكاديمي واليومي.",
          link: "/ar/teachers/seventeen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/14.jpg",
          name: "جهاد السيد",
          desc: "مرحبًا! أنا جهاد، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية اللغة والترجمة الفورية، قسم اللغة الإنجليزية. لدي خبرة في تدريس اللغة الإنجليزية لمدة سبع سنوات، كما أنني حاصلة على شهادة TEFL. عملت أيضًا كمترجمة ودرّست اللغة العربية للناطقين باللغة الإنجليزية. أستمتع بالعمل مع الأطفال ومساعدتهم على التعبير عن إبداعاتهم. لجعل دروسي ممتعة وجاذبة، أدمج الأنشطة والألعاب المسلية في الحصص. أستفيد من أدوات الذكاء الاصطناعي وأحرص على الاطلاع المستمر على أحدث تقنيات وأساليب التدريس. أتطلع للاستمتاع معًا في رحلتنا التعليمية!",
          link: "/ar/teachers/fourteen",
        },
        {
          id: 16,
          src: "https://monglish.b-cdn.net/experts/16.jpg",
          name: "ناهد مصطفى",
          desc: "مرحبًا، أنا ناهد، معلمة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية التربية، قسم الطفولة، ولدي خبرة تدريسية تمتد إلى 8 سنوات. أؤمن بأن الطلاب هم جوهر العملية التعليمية، وأسعى لخلق بيئة داعمة وجاذبة تجعلهم يلعبون دورًا نشطًا في تعليمهم. بدلًا من القيادة من الأمام، أعمل كمرشدة، أقدم التوجيه وأتابع التقدم وأشجع على النمو. هدفي هو تمكين الطلاب من الاستكشاف وطرح الأسئلة وتطوير مهارات التفكير النقدي، مع الشعور بالأمان للمخاطرة والتعلم من الأخطاء. أسعى إلى إلهام الثقة في كل طالب، ومساعدتهم على اكتشاف إمكانياتهم وتولي مسؤولية رحلتهم الأكاديمية.",
          link: "/ar/teachers/sixteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/10.jpg",
          name: "محمود جمال",
          desc: "مرحبًا.. أنا محمود، معلم لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الزراعة، وأعمل في تدريس اللغة الإنجليزية منذ 7 سنوات. أحمل دبلوم TEFL في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي، حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمس لرؤيتكم جميعًا في فصولي الدراسية.",
          link: "/ar/teachers/fifteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/20.jpg",
          name: "سارة مجدي",
          desc: "أنا سارة، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية، تخرجت من كلية اللغات والترجمة الفورية بجامعة الأزهر. لدي 16 عامًا من الخبرة في تدريس اللغة الإنجليزية في المدارس الوطنية وشبه الدولية والأكاديميات الإلكترونية، وأنا حاصلة على شهادة TEFL من Ofqual. أقود نادي الكتابة حيث أستخدم تقنيات متنوعة لتعزيز مهارات الكتابة لدى الطلاب، مع التركيز على العمل الجماعي والتعاون في بيئة آمنة وداعمة. أتطلع إلى رؤيتكم في فصولي الدراسية!",
          link: "/ar/teachers/tweenty",
        },
        {
          src: "https://monglish.b-cdn.net/experts/27.jpg",
          name: "الاء محجوب",
          desc: "أنا الاء محجوب، معلم لغة إنجليزية معتمد بشهادة CELTA من جامعة كامبريدج، وأعمل في أكاديمية مونجلش الدولية. لدي خبرة تفوق 6 سنوات في تدريس الإنجليزية للمراهقين والبالغين، مع أكثر من 2450 جلسة عبر الإنترنت وتعليم أكثر من 300 طالب من جنسيات متعددة. أقدم دعمًا شاملاً لتحضير امتحانات IELTS، وأساعد الطلاب على اكتساب مهارات اللغة الأساسية للنجاح في مجالاتهم العملية. تتميز حصصي باستخدام تقنيات حديثة تجمع بين المتعة والتفاعل، مع التركيز على تطوير المهارات الأربعة (القراءة، التحدث، الكتابة، والاستماع) من خلال مصادر أصيلة وأنشطة تفاعلية.",
          link: "/ar/teachers/twenty-seven",
        },
        {
          src: "https://monglish.b-cdn.net/experts/12.jpg",
          name: "مريم عادل",
          desc: "مرحبًا.. أنا مريم عادل، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية التربية، قسم اللغة الإنجليزية، ولدي خبرة تدريسية تمتد إلى 8 سنوات. كما أنني حاصلة على دبلومة TEFL في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/twelve",
        },
        {
          src: "https://monglish.b-cdn.net/experts/19.jpg",
          name: "ندى يحيى",
          desc: "مرحبًا... أنا ندى، مدرسة لغة إنجليزية كلغة ثانية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب قسم اللغة الإنجليزية، ولدي خبرة 4 سنوات في تدريس اللغة الإنجليزية، كما أحمل دبلومة في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي، حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/ninteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/21.jpg",
          name: "أميرة عادل",
          desc: "مرحبًا.. أنا أميرة، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب قسم اللغة الإنجليزية، ولدي خبرة في تدريس اللغة الإنجليزية لمدة ثماني سنوات. كما أنني حاصلة على شهادة تدريس اللغة الإنجليزية كلغة أجنبية (TEFL)، وأعمل حاليًا على الحصول على شهادة CELTA. في حصصي الدراسية، أحرص على تضمين أنشطة العمل الجماعي التي تتيح للطلاب تبادل الأفكار والتفاعل في بيئة تعليمية آمنة وخالية من التوتر. أنا متحمسة لرؤيتكم جميعًا في فصولي الدراسية. خلال جلساتي، أحرص على إشراك الجميع وتوفير مساحة لهم للتعبير عن أنفسهم، من خلال مساعدتهم على تبني الطريقة الصحيحة. لا أستطيع الانتظار لرؤيتكم في فصولي والاستمتاع بالكثير من المرح معًا!",
          link: "/ar/teachers/twenty-one",
        },
        {
          src: "https://monglish.b-cdn.net/experts/3.jpg",
          name: "هبة خليفة",
          desc: "مرحبًا، أنا هبة، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب، قسم اللغة الإنجليزية، شعبة الأدب. لدي خبرة تدريسية تمتد إلى 13 عامًا، وأنا مدربة معتمدة بشهادة TEFL. في حصصي الدراسية، أستخدم أنشطة ممتعة ومختلفة لتعليم الطلاب، وأشجعهم على مشاركة أفكارهم والتحدث بحرية دون الخوف من ارتكاب الأخطاء. أعمل على تعليمهم كيفية الثقة بالنفس أثناء استخدام اللغة الإنجليزية. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/three",
        },
        {
          src: "https://monglish.b-cdn.net/experts/9.jpg",
          name: "ولاء فايق",
          desc: "أهلًا بكم.. أنا ولاء فايق.. مدربة في  أكاديمية مونجلش الدولية لتدريس اللغة الإنجليزية .. لقد اتيحت لي فرصة تدريس اللغة الإنجليزية على مدار  ١١ عام في أماكن مختلفة في العالم كالهند والصين وعمان وبولندا.. حصلت خلال السنوات القليلة الماضية على العديد من الشهادات ابتداءً من دبلومة تدريس اللغة الإنجليزية كلغة أجنبية من ثم دبلومة...  من المجلس الثقافي البريطاني ببولندا و أيضاً دبلومة تدريس اللغة العربية كلغة أجنبية و أخيراً شهادات عدة في علاج تعسر القراءة و صعوبات التعلم من جامعة أريزونا.. الصفوف في مونجلش ليس لها مثيل.. فنحن دوماً نشجع طلابنا للتعبير عن أنفسهم بحرية.. و درونا كمعلمين هو إرشاد الطلاب من خلال طرق تعليم مبتكرة.. لا أستطيع الإنتظار لرؤيتكم في صفوفي",
          link: "/ar/teachers/nine",
        },
        {
          src: "https://monglish.b-cdn.net/experts/5.jpg",
          name: "مي الطويل",
          desc: "أنا مي الطويل، معلمة مخلصة تتمتع بخبرة تزيد عن عشر سنوات في مجال التدريس. على مدار مسيرتي المهنية، حظيت بفرصة العمل مع طلاب من مختلف الأعمار والخلفيات، مما ساعدني على تطوير مهاراتي في تصميم دروس ديناميكية وجذابة تلبي أنماط التعلم الفردية. التزامي بالتميز في التدريس يتجلى في سعيي للحصول على شهادة (TEFL)، التي زودتني برؤى قيمة حول منهجيات تعليم اللغة الفعالة. تقوم فلسفتي في التدريس على خلق بيئة صفية شاملة وداعمة تعزز نمو الطلاب وثقتهم بأنفسهم.",
          link: "/ar/teachers/five",
        },
        {
          src: "https://monglish.b-cdn.net/experts/2.jpg",
          name: "فاطمة حسن",
          desc: "مرحبًا.. أنا فاطمة، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية التجارة، قسم اللغة الإنجليزية، وأحمل شهادة TEFL بالإضافة إلى شهادة في تدريس الصغار من كلية نوتينغ هيل. لدي خبرة تدريسية تمتد إلى 13 عامًا. تتميز حصصي بالديناميكية والتركيز على الطلاب. لا أطيق الانتظار لرؤيتكم في فصولي الدراسية!",
          link: "/ar/teachers/two",
        },
        {
          src: "https://monglish.b-cdn.net/experts/18.jpg",
          name: "هاجر فتحي",
          desc: "مرحبًا! أنا هاجر دياب، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الألسن بجامعة عين شمس، ولدي خبرة في تدريس اللغة الإنجليزية لمدة عام. تتميز حصصي بأنها متمركزة حول الطلاب، حيث يُتاح لهم فرصة أكبر للتحدث والتعبير عن أفكارهم وآرائهم بحرية، مع تعزيز الثقة بمهاراتهم اللغوية. تتضمن دروسي أنشطة جماعية في جو ودي وداعم للغاية. لا أطيق الانتظار لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/eighteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/22.jpg",
          name: "نورة حافظ",
          desc: "أنا نورة، معلمة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب، قسم اللغة الإنجليزية، ولدي خبرة تدريسية تمتد إلى 4 سنوات، بالإضافة إلى دبلومة في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي، حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/twenty-two",
        },
        {
          src: "https://monglish.b-cdn.net/experts/29.jpg",
          name: "الشيماء سمير",
          desc: "مرحبًا! أنا شيماء، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب قسم اللغة الإنجليزية، وأمتلك خبرة تدريسية لمدة 7 سنوات. كما أنني حاصلة على دبلومة عامة في التربية لتدريس اللغة الإنجليزية. شغفي هو تعليم اللغة الإنجليزية، وأحب اللعب وقضاء وقت ممتع مع الأطفال. أؤمن بأن التدريس من خلال الألعاب والمناقشات أكثر فعالية ومتعة. حصصي الدراسية تعتمد على أنشطة العمل الجماعي، حيث يتبادل الطلاب الأفكار ويعبرون عن أنفسهم بشكل أفضل دون خوف. أنتظركم للانضمام إلى فصولي الدراسية!",
          link: "/ar/teachers/twenty-nine",
        },
        {
          src: "https://monglish.b-cdn.net/experts/25.jpg",
          name: "ايمان محمد",
          desc: "مرحبًا.. أنا إيمان، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الزراعة، ولدي خبرة في تدريس اللغة الإنجليزية لمدة سنتين ونصف، كما أنني حاصلة على شهادة TEFL. أنا معلمة مجتهدة وشغوفة بالتدريس، وأسعى دائمًا لتطوير نفسي لتقديم خدمة تفوق التوقعات. حصصي الدراسية تتميز بأنشطة إبداعية، ويستمتع الطلاب كثيرًا أثناء التعلم معي.",
          link: "/ar/teachers/twenty-five",
        },
        {
          src: "https://monglish.b-cdn.net/experts/23.jpg",
          name: "فاطمة عبدالقادر",
          desc: "مرحبًا.. أنا فاطمة عبدالقادر، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب قسم اللغة الإنجليزية، ولدي خبرة 13 عامًا في تدريس اللغة الإنجليزية. كما أنني حاصلة على شهادة CELTA في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/twenty-three",
        },
        {
          src: "https://monglish.b-cdn.net/experts/30.jpg",
          name: "مني العليم",
          desc: "مرحبًا... أنا منى، مدرسة لغة إنجليزية حاصلة على شهادة CELTA من أكاديمية مونجلش الدولية. تخرجت من كلية التربية، قسم اللغة الإنجليزية، ولدي خبرة تدريسية تمتد إلى 5 سنوات. تتميز حصصي الدراسية بأنشطة العمل الجماعي حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية خالية من التوتر. أسعى لخلق بيئة داعمة وجاذبة يشعر فيها الجميع بالراحة عند مشاركة أفكارهم وآرائهم. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/thirty",
        },
        {
          src: "https://monglish.b-cdn.net/experts/24.jpg",
          name: "هدير عبد الغفار",
          desc: "مرحبًا! أنا هدير عبد الغفار، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. لدي خبرة تزيد عن ثلاث سنوات وشهادة TEFL، وأنا شغوفة بتمكين المتعلمين الصغار في رحلتهم لتعلم اللغة الإنجليزية. تخرجت بفخر من كلية الألسن، جامعة عين شمس، حيث تخصصت في اللغة الصينية مع تخصص فرعي في اللغة الإسبانية، مما يضيف منظورًا عالميًا لحصصي الدراسية. تتميز دروسي بالتفاعل والديناميكية، حيث أركز على الإبداع وبيئة داعمة تقتصر على استخدام اللغة الإنجليزية فقط. أتطلع لمساعدتكم للوصول إلى مستويات جديدة من الثقة والنجاح في اللغة الإنجليزية. متحمسة للانطلاق في هذه الرحلة التعليمية معكم جميعًا!",
          link: "/ar/teachers/twenty-four",
        },
        {
          src: "https://monglish.b-cdn.net/experts/1.jpg",
          name: "نائلة عبدالله",
          desc: "أهلًا بكم.. أنا نائلة.. مدربة معتمدة  لتدريس اللغة الإنجليزية كلغة أجنبية من الولايات المتحدة الأمريكية و أعمل في أكاديمية مونجلش الدولية.. أحب خلق بيئة تعلم مليئة بالمرح و مساعدة الطلاب في رحلتهم نحو مستقبل مشرق من خلال إجادة اللغة الإنجليزية.. متحمسة لمساعدتكم في رحلة تعلم اللغة الإنجليزية",
          link: "/ar/teachers/one",
        },
        {
          src: "https://monglish.b-cdn.net/experts/6.jpg",
          name: "كايتلين أنيت",
          desc: "مرحبا اسمي كاتلين.. أنا مُعملة هنا في أكاديمية مونجلش الدولية ولدي أكثر من 10 سنوات من الخبرة في كل من أمريكا ومصر.. متخصصة في التعليم في مرحلة الطفولة المبكرة وشغفي هو جعل عملية التعليم ممتعة للأطفال.. في صفوفي أستخدم الألعاب التفاعلية والأغاني والرقص مع الأطفال.. أهم شيء بالنسبة لي هو أن يستمتع الأطفال في الصف وهذا ما أفعله هنا في مونجلش.. لا أستطيع الانتظار لمقابلتكم في صفوفي.. أراكم هناك",
          link: "/ar/teachers/six",
        },
        {
          src: "https://monglish.b-cdn.net/experts/8.jpg",
          name: "منى عبدالقوي",
          desc: "مرحبًا بالجميع، أنا السيدة منى، معلمة لغة إنجليزية في أكاديمية مونجليش الدولية. حصلت على درجة البكالوريوس في اللغويات والأدب الإنجليزي من جامعة الإسكندرية. في فصولي الدراسية، أسعى دائمًا إلى جعل التعلم ممتعًا من خلال دمج مقاطع الفيديو والألعاب والأغاني، مما يجعل طلابي متحمسين ومنخرطين. لا أستطيع الانتظار لرؤيتكم جميعًا في فصولي!",
          link: "/ar/teachers/eight",
        },
        {
          src: "https://monglish.b-cdn.net/experts/4.jpg",
          name: "خلود رفعت",
          desc: "مرحبًا.. أنا خلود، مدربة IELTS ومدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. تخرجت من كلية الآداب، قسم اللغة الإنجليزية، ولدي خبرة في تدريس اللغة الإنجليزية لمدة 8 سنوات، بالإضافة إلى دبلومة في تدريس اللغة الإنجليزية كلغة أجنبية. تتميز حصصي الدراسية بأنشطة العمل الجماعي حيث يتبادل الطلاب الأفكار ويتعاونون في بيئة تعليمية آمنة وخالية من التوتر. متحمسة لرؤيتكم جميعًا في فصولي الدراسية!",
          link: "/ar/teachers/four",
        },
      ],
    };
  },
  methods: {
    navigateToTeachers() {
      this.$router.push("/ar/teachers");
    },
    truncateDesc(desc) {
      if (desc.length > 200) {
        return desc.slice(0, 145) + "...";
      }
      return desc;
    },
  },
};
</script>