<template>
  <main class="section1">
    <div class="section1-content">
      <div class="text-content">
        <div class="welcome flex flex-col">
          <div>Welcome to the official website of Monglish Global Academy</div>
          <div>UK, USA, RUSSIA, CENTRAL ASIA, DUBAI, EGYPT.</div>
        </div>
        <h1 class="title">Learn English with Our Unique Immerse Approach</h1>
        <ul class="circle-list-en">
          <li v-for="(item, index) in features" :key="index">
            <i class="fa-solid fa-circle-check"></i> {{ item }}
          </li>
        </ul>
        <div class="one-line from-row-to-column">
          <MainButton @click="goToPackages()">English Courses</MainButton>
          <a href="/en/accredations">
            <button class="info-btn one-line">
              Explore
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
        <h2 class="title-secondary">Accreditations and Memberships</h2>
        <div class="accreditations-wrapper mt-2">
          <Carousel ref="carousel" :itemsToShow="8" :wrapAround="true" :mouseDrag="true" :autoplay="4000"
            :transition="500" :gap="20">
            <Slide v-for="(image, index) in accreditationImages" :key="index" class="carousel-slide">
              <img loading="lazy" :src="image.src" :alt="image.alt" class="reason-image" />
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="image-content hidden lg:block">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/Desktop.png"
          alt="Desktop version of the Monglish Global Network homepage" />
      </div>
    </div>

    <div class="text-center mt-20">
      <h2 class="title-2">Success Partners</h2>
      <div class="companies">
        <img v-for="(company, index) in companyLogos" :key="index" :src="company.src" :alt="company.alt" />
      </div>
    </div>
  </main>
</template>

<script>
import MainButton from "../Elements/MainButton.vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "ViewOne",
  components: { Carousel, Slide, MainButton },
  data() {
    return {
      accreditationImages: this.loadImages('accreditations', 10),
      companyLogos: this.loadImages('companies2', 12),
      features: [
        "Globally Accredited",
        "Daily Study Clubs",
        "Learn 4x faster  than others!"
      ],
      currentStartIndex: 0,
      isTransitioning: false,
      transitionTime: 500,
    };
  },
  mounted() {
    setInterval(() => {
      this.moveCarousel();
    }, 4000);
  },
  methods: {
    moveCarousel() {
      this.isTransitioning = true;
      this.currentStartIndex += 1;
    },
    handleTransitionEnd() {
      this.isTransitioning = false;
      this.currentStartIndex = 0;

      const firstImage = this.accreditationImages.shift();
      this.accreditationImages.push(firstImage);
    },
    goToPackages() {
      this.$router.push({ name: "PackagesViewEn" });
    },
    loadImages(folder, count) {
      return Array.from({ length: count }, (_, index) => ({
        src: `https://monglish.b-cdn.net/${folder}/${index + 1}.webp`,
        alt: `Image ${index + 1} from ${folder} folder`,
      }));
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewOne.css" scoped></style>