<template>
    <div class="terms-section">
      <h3 class="title">سياسة الخصوصية</h3>
      <p>
        تصف سياسة الخصوصية سياسات مونجلش أكاديمي المحدودة في المملكة المتحدة، 71-75 شلتون ستريت، كوفنت جاردن، المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية، البريد الإلكتروني: office@monglish.uk، الهاتف: +442038850766 بشأن جمع واستخدام وكشف معلوماتك التي نجمعها عند استخدامك لموقعنا (https://monglish.co.uk). من خلال الوصول أو استخدام الخدمة، فإنك توافق على جمع واستخدام وكشف معلوماتك وفقًا لهذه السياسة. إذا كنت لا توافق على ذلك، يرجى عدم الوصول أو استخدام الخدمة.
  قد نقوم بتعديل هذه السياسة في أي وقت دون أي إشعار مسبق لك وسوف نقوم بنشر السياسة المعدلة على الخدمة. ستكون السياسة المعدلة فعالة بعد مرور 180 يومًا من تاريخ نشر السياسة المعدلة على الخدمة، وسيُعتبر الوصول أو استخدام الخدمة المستمر بعد ذلك الوقت موافقة منك على السياسة المعدلة. لذا نوصي بمراجعة هذه الصفحة بشكل دوري.
      </p>
  
      <h2 class="sub-title">المعلومات التي نجمعها:</h2>
      <h2 class="sub-title">سنقوم بجمع ومعالجة المعلومات الشخصية التالية عنك:</h2>
      <ul class="list">
        <li>الاسم</li>
        <li>البريد الإلكتروني</li>
        <li>الهاتف المحمول</li>
        <li>معلومات الدفع</li>
      </ul>
  
      <h2 class="sub-title">كيفية جمع معلوماتك:</h2>
      <ul class="list">
        <li>عندما يقوم المستخدم بملء استمارة التسجيل أو يقدم معلومات شخصية بطرق أخرى</li>
        <li>التفاعل مع الموقع</li>
        <li>من مصادر عامة</li>
      </ul>
  
      <h2 class="sub-title">كيفية استخدام معلوماتك:</h2>
      <ul class="list">
        <li>التسويق / الترويج</li>
        <li>إنشاء حساب المستخدم</li>
        <li>الشهادات وجمع تقييمات العملاء</li>
        <li>تجميع ملاحظات العملاء</li>
        <li>فرض الشروط والأحكام</li>
        <li>معالجة الدفع</li>
        <li>الدعم</li>
        <li>معلومات إدارية</li>
        <li>إعلان مستهدف</li>
        <li>إدارة طلبات العملاء</li>
        <li>إدارة حساب المستخدم</li>
      </ul>
  
      <p>
        إذا كنا نرغب في استخدام معلوماتك لأي غرض آخر، سنطلب موافقتك وسنستخدم معلوماتك فقط بعد الحصول على موافقتك، وبعد ذلك، فقط للأغراض التي وافقت عليها، ما لم يكن هناك إلزام قانوني آخر.
      </p>
  
      <h2 class="sub-title">كيف نشارك معلوماتك:</h2>
      <h2 class="sub-title">لن نقوم بنقل معلوماتك الشخصية إلى أي طرف ثالث دون الحصول على موافقتك، إلا في حالات محددة كما هو موضح أدناه:</h2>
      <ul class="list">
        <li>خدمة الإعلان</li>
        <li>التحليلات</li>
        <li>جمع البيانات ومعالجتها</li>
      </ul>
      <p>
        نتطلب من هذه الجهات استخدام المعلومات الشخصية التي ننقلها إليهم فقط للغرض الذي تم نقله لها وعدم الاحتفاظ بها لفترة أطول مما هو مطلوب لتحقيق الهدف المحدد. قد نكشف أيضًا عن معلوماتك الشخصية في الحالات التالية:
        <br>(1) للامتثال للقانون أو اللوائح أو أمر قضائي أو عملية قانونية أخرى؛
        <br>(2) لفرض اتفاقياتك معنا، بما في ذلك هذه سياسة الخصوصية؛
        <br>(3) للرد على ادعاءات أن استخدامك للخدمة ينتهك حقوق طرف ثالث.
        إذا تم دمج الخدمة أو شركتنا مع شركة أخرى، ستكون معلوماتك واحدة من الأصول التي يتم نقلها إلى المالك الجديد.
      </p>
  
      <h2 class="sub-title">الاحتفاظ بمعلوماتك:</h2>
      <p>
        سنحتفظ بمعلوماتك الشخصية معنا لمدة تتراوح بين 90 يومًا إلى 2 سنة بعد أن تظل حسابات المستخدم غير نشطة أو طالما كان ذلك ضروريًا لتحقيق الأغراض التي تم جمعها من أجلها كما هو مفصل في هذه سياسة الخصوصية. قد نحتاج أيضًا إلى الاحتفاظ ببعض المعلومات لفترات أطول مثل الحفاظ على السجلات / التقارير وفقًا للقانون المعمول به أو لأسباب أخرى مشروعة مثل إنفاذ الحقوق القانونية، ومنع الاحتيال، وما إلى ذلك. قد يتم الاحتفاظ بالمعلومات المتبقية بشكل مجهول ومعلومات مجمعة، ولا تحدد هويتك (بشكل مباشر أو غير مباشر)، لفترة غير محددة.
      </p>
  
      <h2 class="sub-title">حقوقك:</h2>
      <p>
        اعتمادًا على القانون الذي ينطبق، قد يكون لديك الحق في الوصول وتصحيح أو مسح بياناتك الشخصية أو استلام نسخة من بياناتك الشخصية، وتقييد أو الاعتراض على معالجة بياناتك بشكل فعّال، وطلب منا مشاركة (نقل) معلوماتك الشخصية إلى جهة أخرى، وسحب أي موافقة قدمتها لنا لمعالجة بياناتك، والحق في تقديم شكوى إلى سلطة إشرافية وغيرها من الحقوق التي قد تكون ذات صلة وفقًا للقوانين ذات الصلة. لممارسة هذه الحقوق، يمكنك الكتابة إلينا على office@monglish.uk. سنستجيب لطلبك وفقًا للقانون المعمول به.
        يمكنك أيضًا الاختيار من عدم تلقي التسويق المباشر أو التحليل الذي نقوم به لأغراض تسويقية عن طريق الكتابة إلينا على office@monglish.uk.
        يرجى مراعاة أنه إذا لم تسمح لنا بجمع أو معالجة المعلومات الشخصية المطلوبة أو سحبت الموافقة على معالجتها للأغراض المطلوبة، قد لا تتمكن من الوصول أو استخدام الخدمات التي تم البحث عن معلوماتك لأجلها.
      </p>
  
      <h2 class="sub-title">الأمان:</h2>
      <p>
        أمان معلوماتك أمر مهم بالنسبة لنا، وسنستخدم تدابير الأمان المعقولة لمنع فقدان أو سوء استخدام أو تغيير غير المصرح به لمعلوماتك تحت سيطرتنا. ومع ذلك، نظرًا للمخاطر الكامنة، لا يمكننا ضمان الأمان المطلق وبالتالي، لا يمكننا ضمان أي معلومات تقوم بنقلها إلينا، وتفعل ذلك على مسؤوليتك الشخصية.
      </p>
  
      <h2 class="sub-title">روابط الطرف الثالث واستخدام معلوماتك:</h2>
      <p>
        قد تحتوي خدمتنا على روابط إلى مواقع ويب أخرى لا نديرها نحن. لا تتناول هذه سياسة الخصوصية الممارسات الأخرى لأي أطراف ثالثة، بما في ذلك أي طرف ثالث يدير أي موقع ويب أو خدمة يمكن الوصول إليها.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy',
  };
  </script>
  
  <style scoped>
  .terms-section {
    width: 80%;
    margin: auto;
    padding: 80px !important;
    border-radius: 20px;
    background: white;
    margin-top: -130px;
    margin-bottom: 100px;
  }
  .title {
    color: #165e84;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
  .sub-title {
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
  }
  .list {
    list-style: unset;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 1.5em;
    margin-right: 30px;
  }
  @media only screen and (max-width: 768px) {
    .terms-section {
      padding: 20px !important;
    }
    .title {
      font-size: 28px;
    }
  }
  </style>
  