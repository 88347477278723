<template>
<main class="main" dir="ltr">
  <div class="title">
    <div class="t1">Our Accreditations</div>
    <div class="t2">Whether it's the ISO 9001:2015 quality certification earned by our UK headquarters, the International Accreditation of Training Quality for all our locations from IOTQ USA, or the certification of our Mongi-Immerse methodology by the Library of Congress in Washington, Monglish Academy is a name that stands for quality in every way!
    </div>
  </div>

  <div class="carousel-cont">
    <Carousel
        ref="carousel"
        :itemsToShow="1"
        :wrapAround="true"
        :mouseDrag="true"
        :autoplay="4000"
        :transition="500"
    >
      <Slide v-for="(Certificate, index) in Certificates" :key="index">
        <div class="Certificate-info">
          <img loading="lazy" :src="Certificate.img" alt="Certificate Image" class="Certificate-image" />
          <div class="description">
            <div class="t1">{{ Certificate.title }}</div>
              <div class="t2">{{ Certificate.title_2 }}</div>
          </div>
          <div class="color"></div>
        </div>
      </Slide>
    </Carousel>
  </div>
</main>
</template>
This accreditation is granted based on commitment to 6 standards
for quality in training courses
We hold the accreditation logo from the International Organization for Training Quality (IOTQ) - USA

The certificate was obtained based on the registration of our exclusive language teaching curriculum “Mongi-Immersion”.
Holds a certificate of registration at the Library of Congress, Washington (USA)
This accreditation is granted based on commitment to
10 standards in the quality-based institutional management system
The certificate is authenticated by SAARA (affiliated with the accreditation body UAF USA)
<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      Certificates: [
        {
          img: 'https://monglish.b-cdn.net/certificate/1.webp',
          title: "This accreditation is granted based on commitment to 6 standards\n" +
              "for quality in training courses",
          title_2: 'We hold the accreditation logo from the International Organization for Training Quality (IOTQ) - USA'
        },
        {
          img: 'https://monglish.b-cdn.net/certificate/2.webp',
          title: 'This accreditation is granted based on commitment to\n' +
              '10 standards in the quality-based institutional management system',
          title_2: 'The certificate is authenticated by SAARA (affiliated with the accreditation body UAF USA)'
        },
        {
          img: 'https://monglish.b-cdn.net/certificate/3.webp',
          title:'The certificate was obtained based on the registration of our exclusive language teaching curriculum “Mongi-Immersion”.',
          title_2: 'Holds a certificate of registration at the Library of Congress, Washington (USA)\n'
        },
      ]
    };
  },
};
</script>


<style scoped>
.main {
  width: 100%;
  height: fit-content;
  color: white;
  text-align: center;
  background-image: url('https://monglish.b-cdn.net/home/HomeCertificate.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: "DIN Next LT Arabic-n", Sans-serif;
  overflow-x: hidden;
}
.main {
  padding-top: 50px;
}
.title .t1 {
  font-size: 50px;
}
.t1 {
  font-weight: bold;
  max-width: 80%;
  margin: 0 auto;
}
.t2 {
  font-size: 20px;
  font-weight: 300;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.color {
  background-color: #f47e23;
  height: 50%;
  width: 100%;
  top: 25%;
  right: 0;
  position: absolute;
  z-index: -1;
}
.Certificate-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 75px;
  text-align: start;
  gap: 20px;
}
.Certificate-info div {
  direction: ltr;
  margin: 0;
  margin-inline-end: auto;
}
.description .t1 {
  font-size: 30px;
}
.Certificate-info img {
  border-radius: 30px;
  margin-inline-start: 100px;
  height: 600px;
}
.description {
  width: 100%;
}
@media (max-width: 1000px) {
  .color {
    background-color: #ffffff00;
  }
  .Certificate-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 75px;
    text-align: center;
    justify-content: center;
    gap: 20px;
  }
  .Certificate-info div {
    direction: ltr;
    margin: auto;
  }
  .description .t1 {
    font-size: 30px;
  }
  .Certificate-info img {
    border-radius: 30px;
    height: 400px;
    margin: auto;
  }
  .description {
    width: 100%;
  }
}
</style>