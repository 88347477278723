<template>
  <BlogHead :title="title" :img="img" :date="date" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <div v-for="post in blogPosts" :key="post.id" class="blog-post">
            <h3 class="blog-post-title" v-html="post.title"></h3>
            <div class="blog-post-body" v-html="post.content"></div>
            <div v-if="post.img">
              <img loading="lazy" :src="post.img" alt='blogone' class='blog-post-img' />
            </div>
          </div>
        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/en/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "Parents and Children Healthy Relationships",
      date: "29/02/2024",
      img: 'https://monglish.b-cdn.net/blogs/blog4.webp',
      blogPosts: [
        // This is where you'll add your blog post data
        {
          id: 1,
          title: "",
          content: "<p>Building healthy relationships between parents and children is essential for the proper growth and development of children. These relationships help promote mutual trust and respect between the parties involved and contribute to the development of children’s social and emotional skills.</p>",
        },
        {
          id: 2,
          title: "Parents can encourage positive behavior in their children by following some strategies, including:",
          content: "<ol class='list-num'><li>Effective communication: Effective communication is one of the most important factors contributing to building healthy relationships. Through effective communication with their children, parents can understand their needs and feelings, and build mutual trust with them.</li><li>Providing emotional support: Children need emotional support from their parents, which helps them feel safe and loved. Parents can provide emotional support to their children by talking to them with love and care, expressing their love for them, and spending time with them.</li><li>Setting clear boundaries: Children need clear boundaries to know what is allowed and what is not. Parents can set clear boundaries for their children by explaining expectations to them and specifying the consequences of positive and negative behaviors.</li><li>Reinforcing positive behavior: Parents can reinforce positive behavior in their children by praising them when they engage in positive behaviors and providing rewards for them.</li></ol>",
        },
        {
          id: 3,
          title: "",
          content: "<h4>Here are some examples of how to reinforce positive behavior in children:</h4><ul><li>When a child cooperates with their sibling in playing, parents can praise the child for their cooperation and tell them that they are proud of them.</li><li>When a child tidies up their toys by themselves, parents can reward them with a piece of candy or a small toy.</li><li>When a child shows respect to elders, parents can commend them for their respect and tell them that they are setting a good example.</li></ul>",
        },
        {
          id: 4,
          title: "",
          content: "<h4>Encouraging positive behavior in children contributes to:</h4><ul><li>Building mutual trust and respect between parents and children.</li><li>Enhancing children’s social and emotional skills.</li><li>Helping children grow and develop properly.</li></ul>",
        },
        {
          id: 5,
          title: "",
          content: "<h4>In addition to the mentioned examples, there are many other ways that parents can use to encourage positive behavior in their children. For example, parents can:</h4><ul><li>Engage in activities that their child enjoys, such as playing, drawing, or reading.</li><li>Grant the child independence and responsibility, such as allowing them to choose their clothes or prepare a snack for themselves.</li><li>Focus on the child’s strengths and highlight them to the child.</li></ul>",
        },
        {
          id: 6,
          title: "",
          content: "<p><strong>By encouraging positive behavior in their children, parents can build healthy relationships with them and help their children grow and develop properly.</strong></p><p><strong>I hope you found the article helpful! Feel free to share this article with friends and family. Until next time!</strong></p>",
        },


      ],
      blogCards: [
        {
          id: 1,
          title: 'Learning English in Early Ages',
          image: 'https://monglish.b-cdn.net/blogs/blogone.webp',
          link: '/en/blog/learning-english-in-early-ages',
        },
        {
          id: 2,
          title: "Celebrating Children’s Language Progress.",
          image: 'https://monglish.b-cdn.net/blogs/blog-2.webp',
          link: '/en/blog/celebrating-childrens-language-progress',
        },
        {
          id: 3,
          title: 'Seasons of The Year',
          image: 'https://monglish.b-cdn.net/blogs/blog3.webp',
          link: '/en/blog/seasons-of-the-year',
        },
        {
          id: 4,
          title: 'Parents and Children Healthy Relationships',
          image: 'https://monglish.b-cdn.net/blogs/blog4.webp',
          link: '/en/blog/parents-and-children-healthy-relationships',
        },
        {
          id: 5,
          title: 'Activities For Positive Behavior',
          image: 'https://monglish.b-cdn.net/blogs/blog5.webp',
          link: '/en/blog/activities-for-positive-behavior',
        },
        {
          id: 6,
          title: 'Benefits Of Bilingual For Children',
          image: 'https://monglish.b-cdn.net/blogs/blog6.webp',
          link: '/en/blog/benefits-of-bilingual-for-children',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: ltr;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

 

.blog-view {
  margin-left: 2.5%;
  text-align: left;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: left;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}
</style>
