<template>
  <div class="vision-section">
    <div class="counter" v-for="(counter, index) in counters" :key="index">
      <div class="number">{{ formattedNumber(counter.current) }}</div>
      <div class="label">{{ counter.label }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VisionTwo',
  data() {
    return {
      counters: [
        { label: 'Registered trainees', end: 22850, current: 0 },
        { label: 'Satisfaction rate', end: 98.8, current: 0 },
        { label: 'Online coaches', end: 250, current: 0 },
        { label: 'Sessions ended', end: 2100542, current: 0 },
      ],
    };
  },
  mounted() {
    this.startCounters();
  },
  methods: {
    startCounters() {
      this.counters.forEach(counter => {
        this.animateValue(counter, 0, counter.end, 4000);
      });
    },
    animateValue(counter, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        counter.current = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          counter.current = end;
        }
      };
      window.requestAnimationFrame(step);
    },
    formattedNumber(value) {
      if (value === 98.8) {
        return `%${value.toFixed(1)}`;
      } else if (value === 51) {
        return `+${value}`;
      } else {
        return value.toLocaleString();
      }
    },
  },
};
</script>

<style scoped>
.vision-section {
  background-size: cover;
  padding: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto; 
  color: white;
  margin-top: -200px;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.counter {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1em;
  text-align: center;
  color: white;
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
  margin-right: 20px; 
}
.counter span:first-child {
  display: block;
}
.number {
  font-size: 36px;
  font-weight: bold;
}
.label {
  font-size: 18px;
}
@media (max-width: 768px) {
  .vision-section {
    flex-direction: column; 
    margin-bottom: 5px;
    margin-top: -308px;
  }
  .counter {
    margin-right: 0; 
    padding: 5px;
    margin-bottom: 5px;
  }
  .number {
   font-size: 24px;
   font-weight: bold;
  }
  .label {
   font-size: 12px;
  }
}
</style>
