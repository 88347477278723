<template>
  <div class="slider-3dd">
    <div class="kids-sec8-row">
      <h4>
        Your Future Begins Here<br /><span
          >Interactive Learning, Smart Investment</span
        >
      </h4>
      <p>
        Our students achieve remarkable success and excel in their fields
        through our unique curriculum, which equips them with the skills needed
        to unlock future opportunities.
      </p>
    </div>

    <div class="carousel-container">
      <button class="slider-nav left" @click="moveLeft">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>

      <transition-group name="slide" tag="div" class="carousel-track">
        <div
          v-for="(item, index) in generateItems()"
          :key="index"
          :class="['item', 'level' + item.level]"
        >
          <img
            loading="lazy"
            :src="item.src"
            :alt="'Image ' + index"
            @click="openPopup(item.videoSrc)"
          />
        </div>
      </transition-group>

      <button class="slider-nav right" @click="moveRight">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>

    <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <iframe
          v-if="currentVideoSrc"
          :src="currentVideoSrc"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="popup-video"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: "",
      active: 0,
      items: [
        {
          src: "https://monglish.b-cdn.net/video/15.webp",
          videoSrc: "https://www.youtube.com/embed/rzHfZxBceFo?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/16.webp",
          videoSrc: "https://www.youtube.com/embed/0-wqA_sC2ZY?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/17.webp",
          videoSrc: "https://www.youtube.com/embed/DzvbteTOIk8?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/18.webp",
          videoSrc: "https://www.youtube.com/embed/uphkfMfFFj4?autoplay=1",
        },
        {
          src: "https://monglish.b-cdn.net/video/19.webp",
          videoSrc: "https://www.youtube.com/embed/cmB1pPqc-EE?autoplay=1",
        },
      ],
      autoSlideInterval: null,
    };
  },
  methods: {
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = "";
    },
    generateItems() {
      const items = [];
      const isMobile = window.innerWidth <= 768;

      if (isMobile) {
        items.push({
          src: this.items[this.active].src,
          videoSrc: this.items[this.active].videoSrc,
          level: 0,
        });
      } else {
        for (let i = this.active - 2; i <= this.active + 2; i++) {
          let index = i;
          if (i < 0) index = this.items.length + i;
          if (i >= this.items.length) index = i % this.items.length;

          items.push({
            src: this.items[index].src,
            videoSrc: this.items[index].videoSrc,
            level: this.active - i,
          });
        }
      }

      return items;
    },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    autoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 4000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    },
  },
  mounted() {
    this.autoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.slider-3dd {
  background-color: #fff;
  padding: 3% 1%;
}

.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  color: #165e84;
  direction: ltr;
}
.kids-sec8-row h4 {
  font-size: 3vw;
}
.kids-sec8-row span {
  font-size: 3.2vw;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 75%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
  .kids-sec8-row h4 {
    width: 35%;
    margin: 0;
    padding-left: 1em;
    font-size: 48px;
  }
  .kids-sec8-row h4 span {
    font-size: 48px;
  }
  .kids-sec8-row p {
    width: 60%;
    margin: 0;
    padding-right: 20px;
  }
}
@media (max-width: 769px) {
  .kids-sec8-row {
    flex-direction: column;
    text-align: center;
  }
  .kids-sec8-row h4 {
    width: 100%;
    padding-left: 0;
    font-size: 28px;
  }
  .kids-sec8-row h4 span {
    width: 100%;
    padding-left: 0;
    font-size: 28px;
  }
  .kids-sec8-row p {
    width: 100%;
    padding-right: 0;
    font-size: 20px;
  }
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
}

.carousel-track {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-enter-active,
.slide-leave-active {
  animation: slideIn 0.8s ease forwards;
}

.slide-leave-to {
  animation: slideOut 0.8s ease forwards;
}

h2 {
  color: #165e84;
  font-size: 48px;
  font-weight: 500;
  font-family: "DIN Next LT Arabic";
  text-align: center;
  padding: 0 0 2% 0;
}

.slider-nav {
  color: #156e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: #ebebeb;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  background-color: #b4b4b4;
}

.slider-nav:active {
  color: #fff;
  background-color: #156e84;
}

.item {
  position: relative;
  text-align: center;
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.level-2 {
  transform: translateX(-61px) rotateY(-10deg) scale(0.6);
  opacity: 0.5;
}

.level-1 {
  transform: translateX(-1px) rotateY(-5deg) scale(0.8);
  opacity: 0.7;
}

.level0 {
  transform: translateX(0) rotateY(0deg) scale(1);
  opacity: 1;
}

.level1 {
  transform: translateX(1px) rotateY(5deg) scale(0.8);
  opacity: 0.7;
}

.level2 {
  transform: translateX(61px) rotateY(10deg) scale(0.6);
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #fff;
}

.close-button:hover {
  color: #d9534f;
}

@media (max-width: 768px) {
  h2 {
    font-size: 30px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  img {
    width: 80%;
    height: auto;
  }
}
</style>
