<template>
  <main class="main" dir="rtl">
    <div class="title">
      <div class="t1">اعتماداتنا دليل جودتنا</div>
      <div class="t2">
        ثُعد اسم أكاديمية مونجلش الدولية رمزًا للجودة في كل شيء، فبدءًا من اعتمادنا المرموق في جودة التدريب من IOTQ USA، ومرورًا بحصولنا على شهادة الأيزو للجودة في نظام الإدارة ISO 9001: 2015، ووصولًا لتسجيل منهجنا الحصري عالميًا في مكتبة الكونجرس بواشنطن، تدُل الشواهد على تميّز يندُر وجوده في مجال التدريب الرقمي.
      </div>
    </div>

    <div class="carousel-cont">
      <Carousel
        ref="carousel"
        :itemsToShow="1"
        :wrapAround="true"
        :mouseDrag="true"
        :autoplay="4000"
        :transition="500"
      >
        <Slide v-for="(Certificate, index) in Certificates" :key="index">
          <div class="Certificate-info">
            <img loading="lazy" :src="Certificate.img" alt="Certificate Image" class="Certificate-image" />
            <div class="description">
              <div class="t1">{{ Certificate.title }}</div>
              <div class="t2">{{ Certificate.title_2 }}</div>
              <!-- Add the button below the text -->
              <a :href="Certificate.link" target="_blank" class="certificate-link">اكتشف اعتمادنا</a>
            </div>
            <div class="color"></div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </main>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      Certificates: [
        {
          img: 'https://monglish.b-cdn.net/certificate/1.webp',
          title: 'تم الحصول على الشهادة بناء على تسجيل منهجنا الحصري لتدريس اللغات "Mongi-Immersion"',
          title_2: 'حاصلون على شهادة التسجيل بمكتبة الكونجرس بواشنطن (الولايات المتحدة الأمريكية)',
          link: 'https://copyright.gov/'
        },
        {
          img: 'https://monglish.b-cdn.net/certificate/2.webp',
          title: 'هذا الاعتماد يتم منحه بناء على الالتزام بـ 6 معايير للجودة في الدورات التدريبية',
          title_2: 'حاصلون على شعار الاعتماد من المنظمة الدوليةلجودة التدريب (IOTQ) - الولايات المتحدة الأمريكية',
          link: '/ar/accredations'
        },
        {
          img: 'https://monglish.b-cdn.net/certificate/3.webp',
          title: 'هذا الاعتماد يتم منحه بناء على الالتزام بـ 10 معايير في منظومة الإدارة المؤسسية القائمة على الجودة',
          title_2: 'الشهادة مصادقة من SAARA(التابعة لهيئة الاعتماد UAF USA)',
          link: 'https://www.saaracertification.com/'
        },
      ]
    };
  },
};
</script>

<style scoped>
.certificate-link {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 60px;
  font-size: 18px;
  transition: background 0.3s ease;
}
.certificate-link:hover {
  background: linear-gradient(45deg, #f47e23, #fe9b4f);
  color: white;
}

.main {
  width: 100%;
  height: fit-content;
  color: white;
  text-align: center;
  background-image: url('https://monglish.b-cdn.net/home/HomeCertificate.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: "DIN Next LT Arabic-n", Sans-serif;
  overflow-x: hidden;
  padding-top: 50px;
}

.title .t1 {
  font-size: 48px;
  font-weight: 500;
  max-width: 80%;
  margin: 0 auto;
}

.t2 {
  font-size: 20px;
  font-weight: 300;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.color {
  background-color: #f47e23;
  height: 20%;
  width: 100%;
  top: 34%;
  right: 0;
  position: absolute;
  z-index: -1;
}

.Certificate-info {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-block: 75px;
  text-align: start;
  gap: 20px;
}

.Certificate-info div {
  direction: rtl;
  margin: 0;
  margin-inline-end: auto;
}

.description .t1 {
  font-size: 30px;
}

.Certificate-info img {
  border-radius: 30px;
  margin-inline-end: 100px;
  height: 600px;
}

.description {
  width: 100%;
}

@media (max-width: 1000px) {
  .color {
    background-color: #ffffff00;
  }

  .Certificate-info {
    flex-direction: column;
    text-align: center;
  }

  .Certificate-info img {
    height: 400px;
    margin: auto;
  }
  .t1{
    font-size: 26px;
  }
}
</style>
