<template>
  <div :class="{'rtl': isArabic, 'ltr': !isArabic}" class="not-found">
    <div class="container">
      <!-- Language Switcher Button -->
      <div class="language-switcher">
        <button @click="toggleLanguage" class="language-btn">
          {{ isArabic ? 'English' : 'العربية' }}
        </button>
      </div>

      <!-- Section 1 -->
      <div class="section">
        <h3 class="assistance-message">
          {{ isArabic ? 'عذرًا، لا يمكننا العثور على هذه الصفحة!' : 'Sorry, we can\'t find this page!' }}
        </h3>
        <div class="message-box">
          <p class="error-description">
            {{ isArabic 
              ? 'يبدو أن الرابط الذي تحاول الوصول إليه قد تم حذفه أو قد يكون غير صحيح. لا تقلق، نحن هنا لمساعدتك! إذا كنت تواجه أي مشكلة، فلا تتردد في التواصل معنا.' 
              : 'It seems the link you\'re trying to access has been deleted or is incorrect. Don\'t worry, we\'re here to help! If you have any issues, feel free to reach out to us.' }}
          </p>
          <a
            :href="isArabic ? 'https://www.monglish.co.uk/ar/contact' : 'https://www.monglish.co.uk/en/contact'"
            class="contact-btn"
            aria-label="Contact us"
          >
            {{ isArabic ? 'تواصل معنا' : 'Contact Us' }}
          </a>
        </div>
      </div>

      <!-- Section 2 -->
      <div class="section" v-if="suggestedUrl">
        <h3 class="assistance-message">
          {{ isArabic ? 'دعــنا نســاعدك في العــثور على طريـــقك.' : 'Let us help you find your way.' }}
        </h3>

        <div class="suggestion-box">
          <p>
            {{ isArabic ? 'هل كنت تقصد؟' : 'Did you mean?' }} <strong>{{ suggestedPageName }}</strong>
          </p>
          <a
            :href="suggestedUrl"
            class="recommendation-link"
            aria-label="Go to suggested page"
          >
            {{ isArabic ? 'الذهاب إلى الصفحة المقترحة' : 'Go to suggested page' }}
          </a>
        </div>
      </div>

      <!-- Section 3 -->
      <div class="section">
        <h3 class="assistance-message">
          {{ isArabic ? 'هل تبحث عن شيء آخر؟ جرب زيارة هذه الصفحات' : 'Looking for something else? Try visiting these pages' }}
        </h3>

        <div class="recommended-pages">
          <ul>
            <li><a :href="isArabic ? '/ar' : '/'">{{ isArabic ? 'الصفحة الرئيسية' : 'Home Page' }}</a></li>
            <li><a :href="isArabic ? '/ar/monglish-immerse' : '/en/monglish-immerse'">{{ isArabic ? 'منهج المعايشة' : 'Monglish Immerse' }}</a></li>
            <li><a :href="isArabic ? '/ar/monglish-reasons' : '/en/monglish-reasons'">{{ isArabic ? '12 سبب لاختيارنا' : '12 Reasons to Choose Us' }}</a></li>
            <li><a :href="isArabic ? '/ar/kids-courses' : '/en/kids-courses'">{{ isArabic ? 'تعليم الصغار' : 'Kids Courses' }}</a></li>
            <li><a :href="isArabic ? '/ar/adult-courses' : '/en/adult-courses'">{{ isArabic ? 'تعليم الكبار' : 'Adult Courses' }}</a></li>
            <li><a :href="isArabic ? '/ar/ielts-courses' : '/en/ielts-courses'">{{ isArabic ? 'دورات IELTS' : 'IELTS Courses' }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent",
  data() {
    return {
      currentUrl: window.location.pathname,
      suggestedUrl: null,
      suggestedPageName: null,
      isArabic: false, 
      recommendations: {
        "/en/adults-courses": {
          url: "/en/adult-courses",
          name: "Adult Courses",
        },
        "/ar/adults-courses": {
          url: "/ar/adult-courses",
          name: "دورات الكبار",
        },
        "/arr": { url: "/ar", name: "الصفحة الرئيسية (العربية)" },
        "/enn": { url: "/", name: "Home Page (English)" },
        "/en": { url: "/", name: "Home Page (English)" },
        "/ar/monglish-immerse": {
          url: "/ar/monglish-immerse",
          name: "منهج المعايشة",
        },
      },
    };
  },
  created() {
    const suggestion = this.recommendations[this.currentUrl];
    if (suggestion) {
      this.suggestedUrl = suggestion.url;
      this.suggestedPageName = suggestion.name;
    }
  },
  methods: {
    toggleLanguage() {
      this.isArabic = !this.isArabic;
    }
  }
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url("https://monglish.b-cdn.net/404/404.jpg") center/cover no-repeat;
  height: 130vh;
  color: white;
  padding: 20px;
  margin-top: -85px;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  color: #333;
  border-radius: 15px;
  padding: 30px;
  max-width: 800px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 30px;
}

.assistance-message {
  font-size: 25px;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
  color: #2c3e50;
}

/* Section 1: Error message box */
.message-box {
  background: linear-gradient(45deg, #ff9442, #ff6f00);
  color: white;
  border-radius: 15px;
  padding: 25px;
  font-size: 1.1em;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
}

.error-description {
  text-align: right;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.contact-btn {
  align-self: flex-start;
  background: white;
  color: #ff6f00;
  border: none;
  padding: 12px 25px;
  border-radius: 20px;
  font-size: 1em;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

/* Section 2: Suggestion box */
.suggestion-box {
  background: #f8f8f8;
  border-left: 5px solid #ff9442;
  color: #333;
  border-radius: 10px;
  padding: 20px;
  font-size: 18px;
}

.recommendation-link {
  color: #165e84;
  text-decoration: underline;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}

.recommendation-link:hover {
  color: rgb(17, 128, 187);
}

/* Section 3: Recommended pages */
.recommended-pages {
  background: #f8f8f8;
  border-radius: 15px;
  padding: 25px;
  max-width: 700px;
}

.recommended-pages h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.recommended-pages ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommended-pages li {
  margin-bottom: 18px;
  width: 48%;
}

.recommended-pages a {
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 18px;
  padding: 6px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.recommended-pages a:hover {
  color: #fff;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .not-found {
    height: 140vh;
  }

  .container {
    padding: 15px;
  }

  .recommended-pages li {
    width: 100%;
  }
}

/* Language Switcher Button */
.language-switcher {
  text-align: right;
  margin-bottom: 20px;
}

.language-btn {
  background: #ff6f00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s;
}

.language-btn:hover {
  background: #e65c00;
}

/* Right-to-left layout (for Arabic) */
.rtl {
  direction: rtl;
  text-align: right;
}

/* Left-to-right layout (for English) */
.ltr {
  direction: ltr;
  text-align: left;
}
</style>
