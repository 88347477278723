<template>
  <div class="our-team-container-en">
    <div class="our-team-en">
      <div class="padding-full-en">
        <div class="ourfounder-img-mobile-en">
          <img
            src="https://monglish.b-cdn.net/home/founder.png"
            alt="founder"
            class="founder-team-en"
          />
        </div>
        <div class="padding-h3-title-en">
          <h3 class="padding-text-full-en">Monglish International Academy</h3>
        </div>
        <div class="padding-h2-title-en">
          <h2>Distinguished Global Team</h2>
        </div>
        <div class="margin-text-p-en">
          <p class="margin-text-up-en">
            Monglish_frontend boasts a specialized global team exceeding 300
            Success Partners. These partners deliver a unique educational
            experience in English language learning, ensuring the highest
            quality and professionalism. We utilize an innovative curriculum
            that simulates the experience of living among native speakers.
            Monglish is committed to providing an outstanding educational
            journey that empowers learners to achieve success and excellence.
          </p>
        </div>
        <div class="joinus-button-en">
          <router-link to="/en/join-us">
            <button>
              Join the Monglish team
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </router-link>
        </div>
        <div class="ourteam-counter-en">
          <OurTeamCounter />
        </div>
        <div class="ourfounder-img-en">
          <img
            src="https://monglish.b-cdn.net/home/founder-en.png"
            alt="founder"
            class="founder-team-en"
          />
        </div>
      </div>
    </div>

    <div class="team-row-en">
      <h3>Management That Makes a Difference:</h3>
      <p>
        They are the backbone of our success. This dedicated management team
        works tirelessly at every step to ensure an exceptional educational
        experience and the achievement of milestones throughout the learning
        process.
      </p>
    </div>

    <div class="team-managers">
      <div
        v-for="manager in teamManagers"
        :key="manager.id"
        class="manager-card"
      >
        <div class="manager-info">
          <h4>{{ manager.name }}</h4>
          <p>{{ manager.title }}</p>
        </div>
        <img loading="lazy" :src="manager.image" :alt="manager.name" />
      </div>
    </div>

    <EmployeeCards />

    <VideoView />

    <div class="last-section-team-en">
      <div class="ourteam-sec6-en">
        <img
          src="https://monglish.b-cdn.net/home/ourteam-6.jpg"
          alt="ourteam-6"
          class="ourteam-6-en"
        />
      </div>

      <div class="ourteam-text-en">
        <p>Interested in Joining?</p>
        <p>Check Out The Link Below!</p>
        <router-link to="/en/join-us">
          <button>
            Visit the Mongi-Careers Portal
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import OurTeamCounter from "../../components/en/TeamCounter.vue";
import VideoView from "../../components/en/VideoSliderFour.vue";
import EmployeeCards from "../../components/en/EmployeeCards.vue";

export default {
  name: "OurTeam",
  components: {
    OurTeamCounter,
    VideoView,
    EmployeeCards,
  },
  data() {
    return {
      teamManagers: [
        {
          id: 1,
          name: "Dr. Mohamed Al-Najjar",
          title: "Vice Chairman",
          image: "https://monglish.b-cdn.net/team/1.webp",
        },
        {
          id: 2,
          name: "Dr. Hatem Hassanein",
          title: "Academic Director",
          image: "https://monglish.b-cdn.net/team/2.webp",
        },
        {
          id: 3,
          name: "Ms. Heba Hassan",
          title: "Assistant to the Chairman for Sales and Marketing",
          image: "https://monglish.b-cdn.net/team/3.webp",
        },
        {
          id: 4,
          name: "Eng. Mohamed Salem",
          title: "Executive Director of Technology Affairs",
          image: "https://monglish.b-cdn.net/team/4.webp",
        },
        {
          id: 5,
          name: "Mr. Safi Al-Rahman Abaza",
          title: "Deputy Marketing Director",
          image: "https://monglish.b-cdn.net/team/5.webp",
        },
        {
          id: 6,
          name: "Ms. Maysa Al-Sheikh",
          title: "Human Resources Director",
          image: "https://monglish.b-cdn.net/team/6.webp",
        },
        {
          id: 12,
          name: "Dr. Nasr Karkour",
          title: "Assistant Chairman, Alexandria Branch Manager",
          image: "https://monglish.b-cdn.net/team/10.webp",
        },
        {
          id: 11,
          name: "Ms. Samia Anwar",
          title: "Director of Higher Stages",
          image: "https://monglish.b-cdn.net/team/9.webp",
        },
        {
          id: 10,
          name: "Dr. Soha Essam",
          title: "Director of Foundation Stages",
          image: "https://monglish.b-cdn.net/team/8.webp",
        },
        {
          id: 9,
          name: "Mr. Walid Raslan",
          title: "Accounts Manager",
          image: "https://monglish.b-cdn.net/team/7.webp",
        },
      ],
    };
  },
};
</script>

<style src="@/assets/styles/en/OurTeam.css"></style>
<style src="@/assets/styles/ar/OurTeam.css"></style>