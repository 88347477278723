<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>يُعد بناء علاقات صحية بين الأهل والأطفال أمرًا أساسيًا لنمو وتطور الأطفال بشكل سليم. حيث تُساعد هذه
            العلاقات على تعزيز الثقة والاحترام المتبادل بين الطرفين، وتُساهم في تنمية مهارات الأطفال الاجتماعية
            والعاطفية.<br><br></p>

          <h2>يمكن للأهل تعزيز السلوك الإيجابي لدى أطفالهم من خلال اتباع بعض الأساليب، ومنها:<br><br></h2>

          <ul>
            <li aria-level="1">التواصل الفعال: يُعد التواصل الفعال من أهم العوامل التي تساهم في بناء العلاقات الصحية.
              حيث يُمكن للأهل من خلال التواصل الفعال مع أطفالهم فهم احتياجاتهم ومشاعرهم، وبناء الثقة المتبادلة معهم.
            </li>
            <li aria-level="1">تقديم الدعم العاطفي: يُحتاج الأطفال إلى الدعم العاطفي من الأهل، حيث يُساعدهم ذلك على
              الشعور بالأمان والحب. ويمكن للأهل تقديم الدعم العاطفي لأطفالهم من خلال التحدث معهم بحب واهتمام، والتعبير
              عن حبهم لهم، وقضاء الوقت معهم.</li>
            <li aria-level="1">وضع حدود واضحة: يُحتاج الأطفال إلى حدود واضحة لمعرفة ما هو مسموح وما هو غير مسموح. ويمكن
              للأهل وضع حدود واضحة لأطفالهم من خلال توضيح التوقعات لهم، وتحديد عواقب السلوكيات الإيجابية والسلبية.</li>
            <li aria-level="1">تعزيز السلوك الإيجابي: يُمكن للأهل تعزيز السلوك الإيجابي لدى أطفالهم من خلال الثناء عليهم
              عند القيام بسلوكيات إيجابية، وتقديم المكافآت لهم.</li>
          </ul>
          <br><br>

          <h2>فيما يلي بعض الأمثلة على كيفية تعزيز السلوك الإيجابي لدى الأطفال: <br><br></h2>

          <ul>
            <li aria-level="1">عندما يتعاون الطفل مع أخيه في اللعب، يمكن للأهل أن يمدحوا الطفل على تعاونه، ويخبروه بأنه
              فخور به.</li>
            <li aria-level="1">عندما يجمع الطفل ألعابه بنفسه، يمكن للأهل أن يكافئوه بقطعة حلوى أو بلعبة صغيرة.</li>
            <li aria-level="1">عندما يحترم الطفل كبار السن، يمكن للأهل أن يثنيوا عليه على احترامه، ويخبروه بأنه قدوة
              جيدة.</li>
          </ul>
          <br><br>

          <p>يُساهم تشجيع السلوك الإيجابي لدى الأطفال في: <br><br></p>

          <ul>
            <li aria-level="1">بناء الثقة والاحترام المتبادل بين الأهل والأطفال.</li>
            <li aria-level="1">تعزيز مهارات الأطفال الاجتماعية والعاطفية.</li>
            <li aria-level="1">مساعدة الأطفال على النمو والتطور بشكل سليم.</li>
          </ul>
          <br><br>

          <p>
            <b>بالإضافة إلى الأمثلة المذكورة، هناك العديد من الطرق الأخرى التي يمكن للأهل من خلالها تعزيز السلوك
              الإيجابي لدى أطفالهم. على سبيل المثال، يمكن للأهل:</b>
            <br><br>
          </p>

          <ul>
            <li aria-level="1">منح الطفل الاستقلالية والمسؤولية، مثل السماح له باختيار ملابسه أو تحضير وجبة خفيفة له.
            </li>
            <li aria-level="1">التركيز على نقاط القوة لدى الطفل، وإبرازها له.</li>
          </ul>
          <br><br>

          <p>
            <b>من خلال تشجيع السلوك الإيجابي لدى أطفالهم، يمكن للأهل بناء علاقات صحية معهم، ومساعدة أطفالهم على النمو
              والتطور بشكل سليم.</b>
            <br><br>
          </p>

          <p><strong>اتمنى أن يكون المقال نال أعجبكم! لا تترددوا في مشاركة هذا المقال مع الأصدقاء والأهل. حتى المرة
              القادمة!&nbsp;</strong></p>

          <div class="image-row">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p9.webp" class="small-photo-in-row" alt="Image 1">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p10.webp" class="small-photo-in-row"
              alt="Image 2">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p11.webp" class="small-photo-in-row"
              alt="Image 3">
          </div>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "كيفية بناء علاقات صحية بين الأهل والأطفال من خلال تشجيع السلوك الإيجابي",
      date: "01/24/2024",
      img: 'https://monglish.b-cdn.net/blogs/blog4.webp',
      category: "نصائح للآباء",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>