<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <div v-for="post in blogPosts" :key="post.id" class="blog-post">
            <h3 class="blog-post-title" v-html="post.title"></h3>
            <div class="blog-post-body" v-html="post.content"></div>
            <div v-if="post.img">
              <img loading="lazy" :src="post.img" alt="Blog Post Image" class="blog-post-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" :category="blog.language" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogOne",
  components: {
    BlogHead,
    BlogCard
  },

  data() {
    return {
      title: "كيف اتعلم اللغة الانجليزية",
      date: "03/03/2024",
      category: "ثقافة اللغة الانجليزية",
      img: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      blogPosts: [
        {
          id: 17,
          title: "",
          img: 'https://monglish.b-cdn.net/blogs/1.webp',
        },
        {
          id: 1,
          title: "كيف اتعلم اللغة الانجليزية وانضم لـ أكاديمية مونجلش الدولية",
          content: "<p>إذا كنت تسأل نفسك، ” كيف اتعلم اللغة الانجليزية ؟” عليك بالتركيز على المصادر الأكاديمية التي توفر لك الأساس النظري. حضور الدورات، قراءة الكتب، ومتابعة المواد التعليمية على الإنترنت كلها خطوات مهمة في رحلة التعلم. <br><br> لكن، للوصول إلى الطلاقة في التحدث باللغة الإنجليزية، يجب أن يتجاوز التعلم النظري ويشمل التطبيق العملي والمعايشة. التحدث بطلاقة يتطلب ممارسة مستمرة واستخدام اللغة في مواقف حياتية حقيقية. هنا يأتي دور برنامج تعلم الانجليزية والممارسة الحية والتفاعل مع متحدثين أصليين أو مع أشخاص يتحدثون الإنجليزية بطلاقة.</p>",
        },
        {
          id: 2,
          title: "كيف اتعلم اللغة الانجليزية واصل الى الطلاقة اللغوية ",
          content: "<p>تكمن الإجابة في الجمع بين الأسلوب الأكاديمي النظري والتطبيق العملي والمعايشة اليومية للغة. التعلم الأكاديمي يوفر لك الأساس، بينما الطلاقة تأتي من الممارسة المستمرة والتفاعل الواقعي. لتحقيق النجاح الكامل في إتقان اللغة، يجب عليك الجمع بين الإثنين عن طريق  برنامج تعلم الانجليزية لضمان التعلم الشامل والفعال.</p>",
        },
        {
          id: 3,
          title: "كيف اتعلم اللغة الانجليزية في اسرع وقت ممكن",
          content: "<p>برنامج “المعايشة” يعمل على تعزيز الثقة بالنفس وتسرع تعلم الإنجليزية وصولا للطلاقة اللغوية ٤ أضعاف أي برنامج آخر. فرصتك تبدأ اليوم في رحلة تعلم اللغة الإنجليزية مع Monglish International Academy واستمتع بمزايا الطلاقة اللغوية في حياتك اليومية مع برنامج تعلم الانجليزية الحصري المسجل براءة ملكيته الفكرية بمكتبة الكونجرس الأمريكي الذي يشمل الأتي: </p>",
        },
        {
          id: 4,
          title: "المعايشة اليومية للغة",
          content: `<p>الانغماس في برنامج تعلم الانجليزية والتطبيق المستمر للدراسة النظرية هو طريقك ل طلاقة اللغة. سواء كنت تريد احتراف اللغة لأغراض أكاديمية أو مهنية أو شخصية. إلا أن تحقيق الطلاقة في اللغة الإنجليزية يتطلب اتباع منهج المعايشة بطريقة فعالة ومستمرة، وأحد أكثر الطرق فعالية لتحقيق الطلاقة هي المعايشة اليومية والممارسة المستمرة للغة.<br><br>المعايشة اليومية للغة يعني الانغماس الكامل في بيئة برنامج تعلم الانجليزية بشكل مستمر. هذا يمكن تحقيقه من خلال:<br><br> <ul style="list-style-type: disc;"><li>مشاهدة الأفلام والبرامج التلفزيونية الإنجليزية، يساعد هذا على التعرف على النطق الصحيح والاستماع إلى اللغة في سياقات متنوعة.</li><li>قراءة الكتب والمقالات الإنجليزية: القراءة تساعد في تحسين المفردات وفهم البناء اللغوي والنحوي.</li><li>التفاعل مع الناطقين باللغة: يمكن ذلك من خلال السفر إلى بلد ناطق باللغة الإنجليزية، أو من خلال الانخراط في مجتمعات تتحدث الإنجليزية على الإنترنت.</li></ul></p>`,
        },
        {
          id: 5,
          title: "خطوات برنامج تعلم الانجليزية والممارسة اليومية للغة",
          content: `<p>الممارسة اليومية تعني استخدام برنامج تعلم الانجليزية  بشكل نشط في الحياة اليومية. بعض الأساليب لتحقيق ذلك تشمل:<ul style="list-style-type: disc;"><li>كتابة يومية: كتابة مذكرات أو مقالات يومية باللغة الإنجليزية تساعد في تحسين الكتابة والتفكير باللغة الإنجليزية.</li><li>التحدث مع الأصدقاء أو الزملاء: الانخراط في محادثات يومية باللغة الإنجليزية يعزز الثقة ويساعد في تحسين الطلاقة.</li><li>الاستماع إلى البودكاست أو الأغاني: هذا يساعد في تحسين مهارات الاستماع والفهم.</li></ul></p>`,
        },
        {
          id: 6,
          title: " أهمية ممارسة برنامج تعلم الانجليزية مع خبراء",
          content: `<p>بالرغم من أن المعايشة اليومية والممارسة المستمرة في برنامج تعلم الانجليزية هما ركيزتان أساسيتان لتعلم اللغة، إلا أن الاستفادة منه بإشراف خبراء يعد أمراً ضرورياً. الخبراء يقدمون:</p><ul style="list-style-type: disc;"><li>توجيهات محددة وشخصية: تساعد في تصحيح الأخطاء اللغوية وتقديم استراتيجيات تعلم مخصصة.</li><li>توفير موارد تعليمية متقدمة: مثل الكتب، المواد السمعية والبصرية، والتطبيقات التفاعلية.</li><li>دعم مستمر وتحفيز: من خلال جلسات تعليمية منتظمة و تقييمات دورية لتحسين الأداء.</li></u><p>في هذا السياق كانت الإجابة عن كيف اتعلم اللغة الانجليزية، يقدم برنامج تعلم الانجليزية  Mongi-Immerse من Monglish International Academy تجربة تعليمية فريدة من نوعها تعتمد على الانغماس الكامل في اللغة الإنجليزية من خلال 11 نادياً تفاعلياً وجلسات حية مليئة بالتطبيقات العملية. هنا منهج المعايشة  يتيح للمتعلمين من جميع الأعمار ممارسة اللغة بكل سهولة وبشكل ممتع، مما يسهم في تحقيق الطلاقة اللغوية بسرعة وكفاءة.</p>`
        },
        {
          id: 7,
          title: "كيف اتعلم اللغة الانجليزية و انضم لأكاديمية مونجلش ",
          content: "<p>تعلم اللغة الإنجليزية يتطلب التزاماً وجهداً مستمراً. المعايشة اليومية والممارسة المستمرة هما مفتاح الطلاقة، تمكن من الاستفادة مع برنامج تعلم الانجليزية  المتخصص بإشراف خبراء مثل برنامج Mongi-Immerse يضمن لك تحقيق نتائج أسرع وأفضل. لذا، لنبدأ اليوم في رحلة تعلم اللغة الإنجليزية مع Monglish International Academy واستمتع بمزايا الطلاقة اللغوية في حياتك اليومية.</p>",
        },
        {
          id: 8,
          title: "كيف اتعلم اللغة الانجليزية مع تعزيز الثقة بالنفس ",
          content: "<p>تحدث اللغة الإنجليزية بطلاقة ليس مجرد مهارة لغوية بل هو أداة قوية يمكن أن تؤثر بشكل إيجابي على الشخصية والثقة بالنفس. عندما يكتسب الشخص الطلاقة في لغة جديدة، تتجاوز الفوائد حدود التواصل لتشمل تطوير الذات وتعزيز الثقة بطرق متعددة.</p>",
        },
        {
          id: 9,
          title: "تأثير برنامج تعلم الانجليزية “منهج المعايشة” على الشخصية والثقة بالنفس",
        },
        {
          id: 10,
          title: "أولاً  تعزيز الثقة بالنفس",
          content: "<p>عندما يتمكن الشخص من التعبير عن أفكاره وآرائه بلغة جديدة، يشعر بالإنجاز والقوة. هذا الشعور بالإنجاز يتضاعف عند التعامل مع مواقف حياتية تتطلب استخدام اللغة الإنجليزية.</p>"
        },
        {
          id: 11,
          title: " ثانيًا توسيع الآفاق الثقافية",
          content: "<p>تعلم لغة جديدة يفتح الأبواب أمام ثقافات جديدة وتقاليد مختلفة. هذا يساعد في تنمية الشخصية ويجعل الشخص أكثر تفهماً وتسامحاً تجاه الآخرين. الوعي الثقافي المكتسب من خلال برنامج تعلم الانجليزية يعزز الذكاء الاجتماعي والقدرة على التواصل بفعالية مع أشخاص من خلفيات متنوعة.</p>"
        },
        {
          id: 12,
          title: "ثالثًا  تحسين مهارات التفكير النقدي ",
          content: "<p>برنامج تعلم الانجليزية يتطلب تفكيراً نقدياً وتركيزاً عالياً ويواجه الشخص تحديات لغوية ويتغلب عليها، ويكتسب مهارات ذهنية تساعده في مجالات أخرى من الحياة.</p>"
        },
        {
          id: 13,
          title: "",
          content: `<iframe width="100%" height="450" src="https://www.youtube.com/embed/qnE5mSw0NCw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><p>في Monglish International Academy، نؤمن بأن الطلاقة في اللغة الإنجليزية هي بوابة لتعزيز الشخصية والثقة بالنفس. من خلال برنامج Mongi-Immerse الفريد، نقدم بيئة تعليمية مفعمة بالحيوية والتفاعل تساعد المتعلمين على ممارسة اللغة بشكل عملي وفعال. برامجنا مصممة لتشجيع الطلاب على المشاركة الفعالة والتحدث بثقة، مما يعزز مهاراتهم اللغوية ويعزز ثقتهم بأنفسهم.</p>`
        },
      ],
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
        link: '/ar/blog/edulexa-360-group',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
      },
    {
      id: 4,
      title: 'لقاء د. محمد شلبي برنامج بنت البلد',
      image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
      link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
      Date: '9 يونيو ,2024',
      language: 'فعاليات',
    },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: '/ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }
  
  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}
</style>