<template>
  <div class="contact-sec1">
    <h2>
      Easy to reach us<br />
      in every <span>where</span>
    </h2>
    <p>
      We are here to help you, please feel free to contact us. We will be glad
      to receive your message and service.
    </p>
    <div class="carousel">
      <div class="carousel-inner" ref="carousel">
        <div
          class="contact-card"
          v-for="(card, index) in contactCards"
          :key="index"
        >
          <h2>{{ card.palce }}</h2>
          <h3>{{ card.title }}</h3>
          <div class="contact-details">
            <div class="contact-info">
              <p>Email:</p>
              <p class="label">{{ card.email }}</p>
            </div>
            <div class="contact-info">
              <p>Phone:</p>
              <p class="label">+{{ card.phone }}</p>
            </div>
            <div class="contact-info">
              <p class="label">Address:</p>
              <p>{{ card.address }}</p>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control prev" @click="prevSlide">❮</button>
      <button class="carousel-control next" @click="nextSlide">❯</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      contactCards: [
        {
          place: "Egypt Office - Alexandria",
          title: "(Headquarters)",
          address:
            "Abd El-Hameed El-Deeb, Tharwat Plaza San Stefano, Alexandria",
          phone: "+201010178521",
          email: "alex.office@monglish.email",
        },
        {
          place: "UK Office - London",
          title: "(Headquarters)",
          address:
            "71-75 Shelton Street, Covent Garden, West End London, WC2H 9JQ, UK",
          phone: "+442038850766",
          email: "london.office@monglish.email",
        },
        {
          place: "Representative Office in Saudi Arabia - Riyadh",
          title: "(Representative Office)",
          address:
            "Al Faisaliah Tower, 18th Floor, King Fahad Highway, Riyadh, Saudi Arabia",
          phone: "+9668008500770",
          email: "riyadh.office@monglish.email",
        },
        {
          place: "Egypt Office - Cairo",
          title: "(Headquarters)",
          address: "Makram Ebeid, Nasr City, Cairo",
          phone: "+201096269209",
          email: "cairo.office@monglish.email",
        },
        {
          place: "Uzbekistan Office - Tashkent",
          title: "(Headquarters)",
          address: "улица Сеульская, Toshkent, Uzbekistan",
          phone: "+998935500035",
          email: "tashkent.office@monglish.email",
        },
        {
          place: "UAE Office - Dubai",
          title: "(Headquarters)",
          address:
            "Building A1, Dubai Digital Park, Dubai Silicon Oasis, Dubai, United Arab Emirates",
          phone: "+97142285285",
          email: "dubai.office@monglish.email",
        },
        {
          place: "USA Office - New York",
          title: "(Headquarters)",
          address: "228 Park Ave S PMB 94755 New York, New York 10003-1502",
          phone: "+19178190745",
          email: "newyork.office@monglish.email",
        },
      ],
    };
  },
  mounted() {
    this.startSlide();
  },
  beforeUnmount() {
    clearInterval(this.slideInterval);
  },
  methods: {
    startSlide() {
      this.slideInterval = setInterval(this.nextSlide, 5000);
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.contactCards.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.contactCards.length) %
        this.contactCards.length;
    },
  },
  watch: {
    currentSlide() {
      const carousel = this.$refs.carousel;
      const rtlTransform = `translateX(-${this.currentSlide * 100}%)`;
      carousel.style.transform = rtlTransform;
    },
  },
};
</script>

<style scoped>
.contact-sec1 {
  background: url("https://monglish.b-cdn.net/contact-us/contact_bg.png")
    no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 2em 1em;
  color: white;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  margin-top: -85px;
  direction: ltr;
}
.contact-sec1 h2 {
  font-size: 4em;
  letter-spacing: 2px;
  padding: 1em 1em 0 1em;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.contact-sec1 h2 span {
  color: #f47d21;
}
.contact-sec1 p {
  font-size: 1em;
  margin-bottom: 2em;
  margin-top: 20px;
}
.carousel {
  position: relative;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 1em;
}
.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.contact-card {
  background-color: #f47d21;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  min-width: 100%;
  box-sizing: border-box;
}
.contact-card h2 {
  font-size: 2em;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.contact-card h3 {
  font-size: 2em;
  margin-bottom: 1em;
}
.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}
.contact-info .label {
  font-weight: 700;
  font-family: "DIN Next LT Arabic-n";
  letter-spacing: 1px;
}
.contact-info p {
  margin: 5px 0;
  font-size: 1.3em;
}
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
}
.carousel-control.prev {
  left: 10px;
}
.carousel-control.next {
  right: 10px;
}
@media (min-width: 600px) {
  .contact-sec1 {
    padding: 70px 20px;
  }
  .contact-sec1 h2 {
    font-size: 3em;
  }
  .contact-sec1 p {
    font-size: 1.1em;
  }
  .contact-card h2 {
    font-size: 2em;
  }
}
@media (min-width: 900px) {
  .contact-sec1 {
    padding: 100px 20px;
  }
  .contact-sec1 h2 {
    font-size: 3.5em;
  }
  .contact-sec1 p {
    font-size: 1.2em;
  }
  .contact-card h2 {
    font-size: 2.2em;
  }
  .contact-details {
    flex-direction: row;
    justify-content: space-between;
  }
  .contact-info {
    width: 30%;
  }
}
</style>
