<template>
  <div class="accre-sec4">
    <div class="text-sec4-part1">
      <h3>المعايير التي نعتمدها</h3>
      <h2>لتعزيز التميز التعليمي</h2>
      <p>
        معايير الجودة تُمثل المعيار المرجعي الأساسي للتدريب المهني، حيث تضمن حماية مصالح الأطراف المعنية كالمتدربين والمجتمع الأكبر، وتُعزز مكانة مقدمي الخدمات التدريبية المعترف بهم في مجال الجودة. استنادًا إلى ذلك، وضعت المنظمة معايير الجودة الخاصة بها لمواجهة التحديات الكبيرة في مجال التدريب. أكاديمية مونجلش الدولية حازت على اعتماد المنظمة الدولية لجودة التدريب (IOTQ) بفضل التزامها بمعايير IOTQ، المُلخصة أدناه.
      </p>
    </div>

    <div class="part2">
      <div class="part2-column">
        <h3>1. الجودة</h3>
        <p>معايير الجودة التعليمية للدورات المعتمدة تلبي شروط إصدار الشهادات، وتحمل قيمة الشهادات في توافقها مع معايير الجودة المعترف بها في القطاعات المتنوعة، سواء خلال التدريب أو بعد منح الشهادات.</p>
        <h3>2. الغايات</h3>
        <p>الدورات المعتمدة مصممة لتقديم تجربة تعليمية فعّالة مع تقييم دقيق للمخرجات. يُقدم الدعم الكامل للمتدربين من بداية التسجيل حتى الحصول على شهادة الإتمام، لضمان تحقيق أهداف التدريب والنجاح فيه.</p>
        <h3>3. التصميم</h3>
        <p>نتبع سلسلة من الإجراءات الأساسية لضمان التخطيط والتنفيذ الفعّال للعمليات التدريبية، لتحقيق استمرارية سلسة ونتائج مثمرة في التصميم، التطوير، ومنح الشهادات. يتم الاعتماد على المصادر المرجعية الداخلية والخارجية لصياغة المواد التدريبية.</p>
      </div>

      <div class="part2-column">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/acc/sec4.png" alt="Sec4 Image">
      </div>

      <div class="part2-column">
        <h3>4. الدعم</h3>
        <p>نعمل على تطوير خطط مدروسة للتفاعل مع المتدربين، مع الأخذ في الاعتبار توافق هذه الخطط مع تطلعاتهم المهنية والشخصية. كما توضح هذه الجهات سياساتها وإجراءاتها للمتدربين والمدربين، وتوفر الدعم الكامل لنموهم المهني والشخصي.</p>
        <h3>5. التعاون</h3>
        <p>ضمان الجودة الشاملة ومعايير التدريب باستمرار. ستقوم بإدارة والإشراف على تطوير وإدارة التعاون مع الأطراف الخارجية الثالثة، وستراقب بفعالية اتفاقات التعاون من جميع الجوانب.</p>
        <h3>6. التطبيق</h3>
        <p>ستقوم الجهات الحاصلة على الاعتماد بتصميم وتطوير الدورات التدريبية بالتعاون مع أصحاب الأعمال والمتدربين والجهات المعنية الأخرى (عند الحاجة إلى ذلك)، وذلك لضمان تحقيق نتائج ومخرجات تطبيقية مرتبطة بالعالم الواقعي. كما ستقوم بتوفير الفرصة لممارسة "التدريب أثناء العمل" حيثما أمكن ذلك.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.accre-sec4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #165e84;
  background-color: #fff;
  padding: 20px;
  direction: rtl;
}

.text-sec4-part1 h3 {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 34px;
}

.text-sec4-part1 h2 {
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  font-size: 48px;
}

.text-sec4-part1 p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 21px;
  max-width: 1200px;
  color: #8a8a8a;
  margin: 0 auto;
}

.part2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  flex-wrap: wrap;
}

.part2-column {
  flex: 1;
  padding: 0 10px;
  min-width: 300px;
  margin-bottom: 20px;
}

.part2-column p {
  color: #4a4a4a;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 140%;
  max-width: 90%;
  margin-bottom: 50px;
}

.part2-column h3 {
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
}

.part2-column img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .text-sec4-part1 h2 {
    font-size: 36px;
  }

  .text-sec4-part1 h3 {
    font-size: 28px;
  }

  .text-sec4-part1 p {
    font-size: 18px;
  }

  .part2-column h3 {
    font-size: 26px;
  }

  .part2-column p {
    font-size: 16px;
  }

  .part2-column {
    min-width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .text-sec4-part1 h2 {
    font-size: 28px;
  }

  .text-sec4-part1 h3 {
    font-size: 24px;
  }

  .text-sec4-part1 p {
    font-size: 16px;
  }

  .part2-column h3 {
    font-size: 22px;
  }

  .part2-column p {
    font-size: 14px;
  }

  .part2-column img {
    margin: 0 auto;
  }
}
</style>
