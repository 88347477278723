<template>
  <div class="vision-page">
    <div class="vision-sec1">
      <h2>طموحات كبيرة</h2>
      <p>
        أكاديمية مونجلش الدولية، بمواقعها في لندن، نيويورك، الإسكندرية، القاهرة، دبي والرياض، تقدم منهج 'مونجي-إميرس'
        لتعلم الإنجليزية بفعالية. معتمدة من IOTQ وISO 9001:2015، وعضو في AAA، ESQ، وAROQA، تلتزم بأعلى معايير الجودة
        وتقدم تدريبًا عمليًا لكل الأعمار، محققةً نتائج مبهرة. تخدم أكثر من 10,000 عميل منذ 2019 بفريق دولي من المعلمين
        والموظفين المميزين.
      </p>
      <img loading="lazy" src="https://monglish.b-cdn.net/home/vision1.png" alt="mision 1">

      <VisionTwo />
    </div>

    <div class="kids-sec8-row">
      <h4>رؤيتنا <br>ورسالتنا</h4>
      <p>
        في مونجلش، لدينا رؤية لأن نصبح الأكاديمية الرقمية الأفضل التي تقدم تجربة لا مثيل لها في تعلم اللغات الأجنبية، من
        أجل إحداث تأثير إيجابي في حياة عملائنا.
        مهمتنا هي التوسع عالميًا من خلال تقديم تدريب عبر الإنترنت عالي الجودة، استنادًا إلى منهجية مونجي-إميرس، وذلك من
        خلال فريقنا متعدد الجنسيات من الخبراء المؤهلين.
      </p>
    </div>

    <img loading="lazy" src="https://monglish.b-cdn.net/home/vision2.png" alt="mision 2" class="vision-sec2">

    <div class="vision-sec3">
      <h2>فلسفة منهج المعايشة</h2>
      <div class="vision-container">
        <iframe src="https://player.vimeo.com/video/818262813" frameborder="0" allow="autoplay; fullscreen"
          allowfullscreen>
        </iframe>
      </div>
    </div>

    <div class="kids-sec8-row">
      <h4>اهم <br> أهدافنا</h4>
      <p>
        نهدف إلى تحقيق التميز وتعزيز الأداء في تعليم المتعلمين على مختلف المستويات، من خلال تقديم برامج احترافية
        ومتنوعة، تلائم رغبات كل متعلم. نسعى أيضًا لتوفير وقت ومال المتعلمين، بالتركيز على الأهداف التي يرغبون في
        تحقيقها، وتزويدهم بجداول مرنة للاستمتاع بدوراتهم عبر الإنترنت، من خلال جلسات تفاعلية وحية. نسعى لدعم متعلمينا من
        خلال تقديم محتوى مثير للاهتمام، يدفعهم نحو تحقيق طموحاتهم المهنية. نطمح لتوفير دورات إبداعية وعالية الجودة،
        تتجاوز توقعات المتعلمين من جميع المستويات والخلفيات
      </p>
    </div>

    <img loading="lazy" src="https://monglish.b-cdn.net/home/vision3.png" alt="mision 1" class="vision-sec2"
      style=" border-radius: 25px;">
  </div>
</template>

<script>
import VisionTwo from '../../components/ar/VisionTwo.vue';

export default {
  name: 'VisionAndMission',
  components: {
    VisionTwo,
  }
};
</script>

<style scoped>
.vision-page {
  background-color: white;
}

.vision-sec1 {
  text-align: center;
  background-image: url('https://monglish.b-cdn.net/home/vision_bg.jpg');
  background-size: cover;
  background-position: center;
  margin-top: -85px;
  color: white;
  padding: 10em 1em 3em 1em;
  height: auto;
}

.vision-sec1 p {
  padding: 1% 15%;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  font-size: 1.3em;
}

.vision-sec1 h2 {
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 3em;
}

.vision-sec1 img {
  width: 100%;
  padding: 1%;
}

@media (max-width: 768px) {
  .vision-sec1 img {
    height: 35vh;
  }

  .vision-sec1 {
    height: 115vh;
  }
}

.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #165e84;
}

.kids-sec8-row h4 {
  font-size: 3vw;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 75%;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }

  .kids-sec8-row h4 {
    width: 25%;
    margin: 0;
    padding-right: 1%;
  }

  .kids-sec8-row p {
    width: 70%;
    margin: 0;
    padding-left: 2%;
  }
}

@media (max-width: 768px) {
  .kids-sec8-row {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .kids-sec8-row h4 {
    width: 100%;
    margin: 0;
    font-size: 1.5em;
  }

  .kids-sec8-row p {
    width: 100%;
    margin: 0;
    font-size: 1em;
  }
}

.vision-sec2 {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 1%;
}

.vision-sec3 {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}

.vision-sec3 h2 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}

.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}

.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}

@media (max-width: 1200px) {
  .vision-sec3 h2 {
    font-size: 2.2em;
  }

  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}

@media (max-width: 768px) {
  .vision-sec3 h2 {
    font-size: 1.8em;
  }

  .vision-container {
    width: 95%;
    padding-bottom: 56.25%;
  }
}

@media (max-width: 480px) {
  .vision-sec3 h2 {
    font-size: 1.5em;
  }

  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}
</style>