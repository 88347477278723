<template>
  <div class="refund-policy">
    <h3 class="title">الشروط والأحكام الخاصة بأكاديمية مونجلش المحدودة</h3>
    <h2 class="sub-title">
      تطبق هذه الشروط والأحكام على الخدمات التي تقدمها أكاديمية مونجلش المحدودة،
      71-75 شارع شيلتون، كوفنت جاردن، لندن WC2H 9JQ، المملكة المتحدة. رقم تسجيل
      الشركة: 14083316. (“أكاديمية مونجلش المحدودة” أو “نحن” أو “لنا”). تطبق هذه
      الشروط والأحكام على بيع الدورات التدريبية عبر الإنترنت على الموقع
      الإلكتروني https://monglish.co.uk. الرجاء قراءة هذه الشروط والأحكام بعناية
      قبل شراء دورة تدريبية عبر الإنترنت. إخلاء مسؤولية موقع الويب: عند الشراء
      عبر موقعنا الإلكتروني، عن طريق تحديد خانة “لقد قرأت ووافق على شروط وأحكام
      الموقع الإلكتروني”، فإنك توافق على شروط هذه الاتفاقية التي ستلزمك. إذا كنت
      لا توافق على هذه الشروط والأحكام، يجب عليك التوقف عن الاستمرار في عملية
      الشراء
    </h2>

    <h2 class="list-title">1 تعريفات</h2>
    <ul class="list">
      <li>
        “المعلومات السرية” تعني المعلومات التي يقدمها أحد الطرفين للآخر في شكل
        مكتوب أو رسومي أو مسجل أو قابل للقراءة آليًا أو غير ذلك بخصوص الأعمال أو
        العملاء أو الموردين أو الشؤون المالية والمجالات الأخرى لأعمال أو منتجات
        الطرف الآخر، بما في ذلك على سبيل المثال لا الحصر، مواد الدورة، ولكن لا
        تشمل المعلومات المتاحة للجمهور بخلاف الإفصاح الافتراضي للطرف الذي يكشف
        المعلومات، أو المعلومات المطلوبة للإفصاح عنها من قبل أي محكمة أو هيئة
        تنظيمية، أو أي معلومات موجودة بالفعل في حيازة أو سيطرة الطرف المفصح.
        “مواد الدورة” تعني المعلومات التي تقدمها أكاديمية مونجلش المحدودة
        لمرافقة دورة مقدمة كجزء من الخدمات في شكل إلكتروني. “الرسوم” تعني الرسوم
        التي تدفعها إلى أكاديمية مونجلش المحدودة مقابل الخدمات. “الحقوق الملكية
        الفكرية” تعني حقوق المؤلف، والحقوق المتعلقة بقواعد البيانات، وحقوق
        براءات الاختراع، وحقوق الأداء، والتصاميم والتصاميم المسجلة، والعلامات
        التجارية، والحقوق المتعلقة بالمعلومات السرية وغيرها من حقوق الملكية
        الفكرية (المسجلة وغير المسجلة) في جميع أنحاء العالم. “دورة تدريبية عبر
        الإنترنت” تعني تقديمنا لدورة تدريبية عبر الإنترنت بموجبها تتعلم مواد
        الدورة عن بعد. “الخدمات” تعني توفير دورة تدريبية عبر الإنترنت ومواد
        الدورة معًا مع خدمات أخرى يتم الاتفاق عليها من وقت لآخر ويتم شراؤها من
        خلال الموقع الإلكتروني أو عن طريق الهاتف أو عبر WhatsApp أو عن طريق
        التواصل عبر وسائل التواصل الاجتماعي. “الموقع الإلكتروني”
        https://monglish.co.uk/ “أنت” تعني الفرد أو المنظمة التي تشتري الخدمات.
      </li>
    </ul>
    <h2 class="list-title">2 الخدمات</h2>
    <ul class="list">
      <li>
        وصف للخدمات مع تواريخ بدء الخدمات متوفرة على موقعنا الإلكتروني. سنقدم
        الخدمات بعناية ومهارة معقولة وفقًا للوصف المحدد على الموقع الإلكتروني.
      </li>
      <li>
        نحتفظ بحق تعديل أو سحب أي من الخدمات الموضحة على الموقع الإلكتروني بدون
        إشعار.
      </li>
      <li>
        نتوقع منك تأكيد أن الخدمات التي تشتريها ستلبي احتياجاتك. لا نقدم لك أي
        ضمان بأنك ستحصل على نتيجة معينة أو مؤهل مهني أو فرصة عمل من شراء وإكمال
        أي من الخدمات.
      </li>
      <li>طلب الخدمات وسياسات التسليم</li>
    </ul>
    <h2 class="list-title">3 شراء الخدمات عبر الموقع الإلكتروني</h2>
    <ul class="list">
      <li>
        للتسجيل في دورتنا على الإنترنت، يجب عليك التسجيل للحصول على حساب معنا
        عبر الموقع الإلكتروني. إذا كان لديك بالفعل حساب معنا، يمكنك تسجيل الدخول
        إلى حسابك باستخدام اسم المستخدم وكلمة المرور الخاصة بك.
      </li>
      <li>
        يمكنك الشراء بوسائل أخرى منها التواصل المباشر عبر الهاتف أو WhatsApp أو
        عن طريق التواصل عبر وسائل التواصل الاجتماعي، وبعد استكمال الدفع عبر رابط
        الدفع المباشر المقدم.
      </li>
      <li>
        عند تقديمك لطلب خدمة عبر الموقع الإلكتروني، فإنك تعرض شراء الخدمات وفقًا
        لهذه الشروط والأحكام. تحتفظ أكاديمية مونجلش المحدودة بحق إلغاء أو رفض
        طلبك أو أي جزء منه في أي وقت حتى يتم تأكيده وفقًا للبند 3.5 أدناه.
      </li>
      <li>
        بعد استلام طلبك للخدمات عبر الموقع الإلكتروني، سوف نتصل بك عبر البريد
        الإلكتروني أو الهاتف أو WhatsApp، لتأكيد استلام طلبك، وتقديم الخدمة من
        خلال روابط للدخول إلى دروس Zoom وحساب Class DOJO للوصول إلى المواد
        والمصادر. يتم التسليم عبر الإنترنت بنسبة 100٪ حيث أن خدمة الدورات
        التدريبية عبر الإنترنت هي دروس ESL عبر الإنترنت بنسبة 100٪. يبدأ التسليم
        بعد 24-48 ساعة من استلام تأكيد الدفع، أو في تاريخ لاحق إذا استخدم العميل
        خيار التأجيل (الدفع الآن، البدء لاحقًا).
      </li>
      <li>
        تصبح اتفاقية ملزمة قانونًا بيننا وبينك سارية المفعول عندما:نقبل عرضك
        لشراء خدمات منا من خلال تأكيد الشراء استلام الدفع الخاص بالرسوم ذات
        الصلة منك وفقًا للبند 5 أدناه.
      </li>
      <li>
        عندما يتكون طلبك من عدة دورات تدريبية عبر الإنترنت، فإن كل دورة فردية
        ستُعامل من قبلنا كعرض منفصل للشراء. لن يُعد قبول عرضك لشراء دورة واحدة
        أو أكثر قبولًا لعرضك لشراء أي دورات أخرى.
      </li>
    </ul>
    <h2 class="list-title">4 سياسة الإلغاء والرد</h2>
    <ul class="list">
      <li>
        وفقًا للبند 4.2 أدناه، عندما نقبل / نؤكد خدمات تم شراؤها من قبلك ونشكل
        اتفاقية ملزمة قانونيًا معك وفقًا للبند 3.5 أعلاه، يُسمح لك وفقًا لسياسات
        الإلغاء والتأجيل المنشورة، والتي يمكن الوصول إليها من خلال هذا الرابط،
        اعتبارًا من اليوم التالي لتاريخ إبرام اتفاقيتنا وفقًا للبند 3.4، بإلغاء
        شراء الخدمات.
      </li>
      <li>
        إذا كنت قد اشتريت دورة تدريبية عبر الإنترنت وقمت بالفعل بالوصول إلى، كل
        أو جزء من الدورة التدريبية عبر الإنترنت و/أو بدأت في استخدام تلك الدورة
        التدريبية عبر الإنترنت، فلن يكون لديك أي حق في إلغاء طلبك.
      </li>
      <li>
        بغض النظر عن البند 4.1، لا يوجد أي حق آخر لإلغاء أو تعديل شراء الخدمات
        وأي إلغاء و/أو تعديل آخر لتاريخ الدورة سيكون وفقًا لتقدير أكاديمية
        مونجلش المحدودة وحدها.
      </li>
    </ul>
    <h2 class="list-title">5 الرسوم (Fees)</h2>
    <ul class="list">
      <li>
        الرسوم مقابل الخدمات موضحة على الموقع الإلكتروني أو كما تم إبلاغك بها
        عبر الهاتف وقت تقديم طلب الخدمة.
      </li>
      <li>
        سيتم خصم رسوم الخدمة التي تختارها على الموقع الإلكتروني من بطاقة
        الائتمان / الخصم الخاصة بك عبر Stripe UK وقت الشراء. يجب دفع الرسوم
        بالكامل قبل الوصول إلى أي دورة تدريبية عبر الإنترنت.
      </li>
      <li>
        أنت مسؤول عن جميع التكاليف التي تتكبدها فيما يتعلق بمشاركتك في أي دورة
        تدريبية عبر الإنترنت.
      </li>
    </ul>
    <h2 class="list-title">6 المسؤولية (Liability)</h2>
    <ul class="list">
      <li>
        لا يُعتبر أي جزء من تقديم الخدمات بمثابة تقديم نصيحة استثمارية، ولا
        يُقصد منه ولا ينبغي اعتباره كذلك.
      </li>
      <li>
        على الرغم من أن أكاديمية مونجلش المحدودة تهدف إلى تقديم الخدمات وفقًا
        لأعلى معايير الصناعة، فإنها ولا كتاب الدورات الخاصة بها يقع على عاتقهم
        أي مسؤولية عن: (أ) أي معلومات غير دقيقة أو مضللة مقدمة في البرامج أو
        مواد الدورة وأي اعتماد من جانب العميل على أي من هذه المعلومات (ب) أي فقد
        أو تلف للبيانات (ج) أي فقد في الأرباح أو الإيرادات أو السمعة التجارية
        (د) أي خسارة غير مباشرة أو خاصة أو تبعية تنشأ عن أي خرق لشروط هذه
        الاتفاقية
      </li>
      <li>
        باستثناء ما هو منصوص عليه صراحةً في هذه الشروط والأحكام، لا تنطبق أي
        شروط أو ضمانات أو شروط أخرى على الخدمات. وفقًا للبند 6.5، لا تنطبق أي
        شروط أو ضمانات أو شروط ضمنية أخرى (بما في ذلك أي شروط ضمنية تتعلق
        بالجودة المقبولة أو الملاءمة للغرض أو التوافق مع الوصف).
      </li>
      <li>
        وفقًا للبند 6.5 أدناه، فإن إجمالي مسؤولية أكاديمية مونجلش المحدودة
        الناشئة عن أو فيما يتعلق بهذه الشروط والأحكام وفيما يتعلق بأي شيء قد
        فعلناه أو لم نفعله فيما يتعلق بهذه الشروط والأحكام وتقديم الخدمة (وإذا
        كانت المسؤولية تنتج بسبب خرق العقد أو الإهمال أو لأي سبب آخر) تقتصر على
        الرسوم التي تلقيناها فيما يتعلق بالدورة التدريبية عبر الإنترنت ذات الصلة
        التي نشأ بشأنها نزاع.
      </li>
      <li>
        لا شيء في هذه الاتفاقية يستبعد أو يحد من مسؤولية أكاديمية مونجلش
        المحدودة عن:
      </li>
      <li>(أ) الوفاة أو الإصابة الشخصية الناجمة عن الإهمال</li>
      <li>(ب) التحريف الاحتيالي</li>
      <li>
        (ج) أي مسألة أخرى بموجب القانون الإنجليزي لا يجوز تقييدها أو استبعادها
      </li>
      <li>
        لا يجوز تقديم أي مطالبة بعد أكثر من ستة أشهر من تاريخ آخر يوم تم فيه
        الانتهاء من الخدمات المعنية أو التوقف عن تقديمها من قبلنا.
      </li>
    </ul>

    <h2 class="list-title">7 الملكية الفكرية (Intellectual Property)</h2>
    <ul class="list">
      <li>
        جميع حقوق الملكية الفكرية في مواد الدورة والدورات التدريبية عبر الإنترنت
        هي ملك أكاديمية مونجلش المحدودة، وتبقى كذلك، سواء تم تكييفها أو كتابتها
        أو تخصيصها للعميل أم لا.
      </li>
      <li>
        أنت غير مصرح لك بـ: (أ) نسخ أو تعديل أو إعادة إنتاج أو إعادة نشر أو
        ترخيص من الباطن أو بيع أو تحميل أو بث أو نشر أو نقل أي من مواد الدورة
        دون إذن خطي مسبق. (ب) تسجيل الدورة التدريبية عبر الإنترنت على شريط فيديو
        أو شريط تسجيل صوتي أو إعادة إرسالها عبر الهاتف المصور أو أي وسيلة أخرى.
        (ج) استخدام مواد الدورة في تقديم أي دورة أو تدريب سواء من قبلنا أو أي
        مدرب طرف ثالث. (د) إزالة أي إشعار بحقوق الملكية أو أي إشعار آخر من
        أكاديمية مونجلش المحدودة على مواد الدورة. (هـ) تعديل أو تكييف أو دمج أو
        ترجمة أو تفكيك أو فك التجميع أو عكس هندسة (باستثناء ما يسمح به القانون)
        أي برنامج يشكل جزءًا من الدورات التدريبية عبر الإنترنت.
      </li>
      <li>
        سيؤدي خرقك لهذا البند 7.2 إلى السماح لنا بإنهاء هذه الشروط والأحكام معك
        على الفور والتوقف عن تقديم أي خدمات لك، بما في ذلك على سبيل المثال لا
        الحصر، الوصول إلى الدورات التدريبية عبر الإنترنت.
      </li>
      <li>
        مقابل الرسوم التي دفعتها، نمنحك ترخيصًا محدودًا وغير قابل للنقل وغير
        حصري لاستخدام مواد الدورة فيما يتعلق بالدورة التدريبية عبر الإنترنت لغرض
        وحيد هو إكمال الدورة التدريبية عبر الإنترنت خلال فترة الوصول إلى الدورة
        المحددة.
      </li>
    </ul>
    <h2 class="list-title">8 السرية (Confidentiality)</h2>
    <ul class="list">
      <li>
        يلتزم كل طرف بالمحافظة على سرية معلومات الطرف الآخر وعدم استخدامها إلا
        لأغراض هذه الشروط والأحكام، ويجب إعادتها عند الطلب وعدم الاحتفاظ بنسخ
        منها.
      </li>
      <li>
        يجوز لأي طرف الإفصاح بالمعلومات السرية لمستشاريه القانونيين وغيرهم من
        المستشارين لأغراض الحصول على المشورة منهم.
      </li>
      <li>يستمر العمل بهذا البند رغم إنهاء هذه الشروط والأحكام.</li>
    </ul>
    <h2 class="list-title">9 إنهاء العقد</h2>

    <ul class="list">
      <li>
        يحق لنا إنهاء هذه الشروط والأحكام والتوقف عن تقديم أي خدمات لك فورًا في
        حالة:تقصيرك في دفع الرسوم المستحقة في موعدها المحدد.خرقك لأي بند آخر من
        هذه الشروط والأحكام.
      </li>
      <li>
        عند إنهاء العقد بموجب البند 6 (المسؤولية) و 7 (حقوق الملكية الفكرية) و 8
        (السرية) و 10 (القيود)، ستظل هذه البنود سارية المفعول رغم هذا الإنهاء.
      </li>
      <li>يستمر العمل بهذا البند رغم إنهاء هذه الشروط والأحكام.</li>
    </ul>
    <h2 class="list-title">10 التنازل</h2>
    <ul class="list">
      <li>
        تعتبر أي خدمات نقدمها بموجب هذه الشروط والأحكام شخصية لك ولا يمكن نقلها
        أو التنازل عنها لأي شخص آخر.
      </li>
      <li>
        يحق لنا التنازل عن هذه الشروط والأحكام لأي شركة أخرى دون سابق إنذار لك.
      </li>
    </ul>
    <h2 class="list-title">11 الاتفاقية الكاملة</h2>
    <ul class="list">
      <li>
        هذه الشروط والأحكام، إلى جانب إخلاء مسؤولية الموقع الإلكتروني وشروط
        الدورة المحددة، تشكل الاتفاقية الكاملة بين الطرفين وتحل محل أي اتفاقيات
        أو ترتيبات سابقة، سواء كانت مكتوبة أو شفوية. وأنت تؤكد أنك لم تعتمد على
        أي تصريحات عند الدخول في هذه الشروط والأحكام أو أي شروط وأحكام أخرى
        معنا. لا شيء في هذا البند أو الشروط والأحكام سيحد من المسؤولية عن أي
        تحريف احتيالي.
      </li>
    </ul>
    <h2 class="list-title">12 ظروف قاهرية</h2>
    <ul class="list">
      <li>
        لا تتحمل أكاديمية مونجلش المحدودة مسؤولية تجاهك عن أي خرق لالتزاماتها أو
        إنهاء بموجب هذه الشروط والأحكام الناشئة عن أسباب خارجة عن سيطرتها
        المعقولة، بما في ذلك، على سبيل المثال لا الحصر، الحرائق والفيضانات
        والزلازل والبراكين وغيرها من الكوارث الطبيعية والإرهاب والإضرابات
        والتأخير الناجم عن نزاعات النقل أو القوانين أو اللوائح الحكومية
      </li>
    </ul>
    <h2 class="list-title">13 التنازل</h2>
    <ul class="list">
      <li>
        يجوز لنا التنازل أو نقل أو التعاقد من الباطن عن أي من حقوقنا أو التزامات
        لأي طرف ثالث وفقًا لتقديرنا.
      </li>
      <li>
        تضع هذه البنود إطارًا قانونيًا لحالات إنهاء الاتفاقية بين أكاديمية
        مونجلش والعميل، وتحدد حقوق والتزامات لكل طرف في حال حدوث ذلك.
      </li>
      <li>
        ينص البند 13 على أن أكاديمية مونجلش تحتفظ بحق التنازل عن حقوقها
        والتزاماتها لأي طرف ثالث، وهو أمر شائع في العقود التجارية.
      </li>
      <li>
        من المستحسن دائمًا مراجعة الشروط والأحكام بدقة قبل قبولها للتأكد من تفهم
        حقوقك والتزاماتك بموجب الاتفاقية.
      </li>
    </ul>
    <h2 class="list-title">14 حماية البيانات (Data Protection)</h2>
    <ul class="list">
      <li>
        طبيعة الخدمات التي نقدمها تعني أننا سنحصل على واستخدام ونكشف (معاً
        “نستخدم”) معلومات معينة عنك (“البيانات”). يحدد هذا البيان المبادئ التي
        تحكم استخدامنا للبيانات. بشراء الخدمات، فإنك توافق على هذا الاستخدام.
      </li>
      <li>
        عند التسجيل معنا، ستحتاج إلى تقديم بيانات معينة مثل تفاصيل الاتصال
        ومعلوماتك الديموغرافية. سنقوم بتخزين هذه البيانات وقد نستخدمها للاتصال
        بك، وتزويدك بتفاصيل الخدمات التي اشتريتها، وبشكل آخر حسب الحاجة أثناء
        تقديم الدورة بشكل طبيعي.
      </li>
      <li>
        قد نستخدم أيضًا البيانات المذكورة أعلاه والبيانات المماثلة التي تقدمها
        لنا ردًا على الاستطلاعات، لتجميع ملفات تعريف المستخدمين وإرسال رسائل
        إليك. لن نمرّر أي بيانات شخصية لأي شخص خارج أكاديمية مونجلش المحدودة.
      </li>
      <li>
        لتمكيننا من مراقبة وتحسين خدماتنا، نجمع معلومات مجمعة معينة عنك، بما في
        ذلك تفاصيل نظام التشغيل الخاص بك، وإصدار المتصفح، واسم المجال، وعناوين
        IP ، وعنوان URL الذي أتيت منه وذهبت إليه، وأجزاء الموقع الإلكتروني التي
        تزورها.
      </li>
      <li>
        نستخدم معلومات مثل معرف المستخدم ومعرفات الجلسة وكلمة المرور لتمكيننا من
        تحديد ما إذا كنت تستخدم خدماتنا، والمساعدة في تقديم الخدمات والتأكد من
        حصولك على منتجات ذات صلة. سنقرأ فقط ملفات تعريف الارتباط من ملف تعريف
        الارتباط الخاص بك الذي وضعه هناك من خلال تفاعل متصفح الويب الخاص بك مع
        الموقع الإلكتروني.
      </li>
      <li>
        &nbsp;قد تربط منتجاتنا بمواقع إلكترونية تابعة لجهات خارجية ولا نتحمل
        مسؤولية سياسات بياناتها أو إجراءاتها أو محتواها.
      </li>
      <li>
        تسعى أكاديمية مونجلش المحدودة إلى اتخاذ جميع الخطوات المعقولة لحماية
        بياناتك الشخصية بما في ذلك استخدام تقنية التشفير، ولكن لا يمكن ضمان أمان
        أي بيانات تكشف عنها. أنت تقبل المخاطر الأمنية المتأصلة في الوجود
        والتعامل عبر الإنترنت ولن تحمّلنا مسؤولية أي خرق للأمن.
      </li>
      <li>
        قد تكمل أكاديمية مونجلش المحدودة المعلومات التي تقدمها بالمعلومات التي
        نتلقاها من جهات خارجية، مثل هيئات تسجيل الامتحانات أو صاحب العمل.
      </li>
    </ul>
    <h2 class="list-title">15 القانون والاختصاص القضائي</h2>
    <ul class="list">
      <li>
        يخضع هذا الاتفاق للقانون الإنجليزي، ويخضع الطرفان للاختصاص القضائي
        الحصري للمحاكم الإنجليزية فيما يتعلق بأي نزاع بموجبه.
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "TermsCondition",
};
</script>
<style scoped>
.refund-policy {
  width: 80%;
  margin: auto;
  padding: 80px !important;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important ;
  margin-top: -130px;
  margin-bottom: 100px;
}
.title {
  color: #165e84;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;
  margin-bottom: 10px;
}
.sub-title {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 25px;
}
.list {
  list-style: unset;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.5em;
  margin-right: 30px;
}
.list-title {
  color: #434343;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;
}
@media only screen and (max-width: 768px) {
  .refund-policy {
    padding: 20px !important;
  }
  .title {
    font-size: 28px;
  }
}
</style>