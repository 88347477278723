<template>
  <div class="our-founder-sec1">
    <div class="founder-content">
      <h1>A PIONEER</h1>
      <p>
        Dr. Mohamed Shalaby is a pioneering figure in the ESL immers eion methodology. He founded Monglish International
        Academy in 2019 while also co-founding Yanabea Online School, serving learners in Egyptian and international
        curricula.
        Monglish was established by Dr. Shalaby as an opportunity to illuminate the path for learners to experience
        other cultures through language by enabling them to study English with ease, joy, and speed. He created the
        academy to employ his pioneering methodology, registered in his name at the Library of Congress in the United
        States.
        Dr. Shalaby has global experience in e-learning and teaching English at top institutions and private schools in
        the Gulf region and Malaysia. He served as a professional English as a Second Language (ESL) teacher and
        examiner for Cambridge University in the UK. He also pursued postgraduate studies in Teaching English as a
        Foreign Language (TEFL) at Cairo University.
        Monglish International Academy is known for offering many training courses that successfully serve the goals of
        its clients, whether those goals relate to language mastery, career advancement, or professional development.
        Under the supervision of Dr. Shalaby, Monglish has become one of the leading online English as a Second Language
        schools in the region, thanks to its exclusive methodology used by training experts.
      </p>
    </div>
  </div>

  <div class="our-founder-sec2">
    <div class="founder-image">
      <img loading="lazy" src="https://monglish.b-cdn.net/home/our-founder2.jpg" alt="Our Founder Image">
    </div>
    <div class="founder-content-sec2">
      <h1>ACTIVELY PASSIONATE</h1>
      <p>"It started with being a founder CEO of Monglish Academy for Training & Development (Egypt) at 2019. It was
        during the COVID-19 beginnings; an online solution to an offline problem. Prior to that he initiated, as a
        co-founder, Yanabee International Online School (Egypt). Meeting the increased demand for Monglish Academy
        services, he found as CEO Monglish Academy UK (England), followed by Monglish International Academy (USA), and
        this became the inception of Monglish Global Network (MGN), spanning 6 offices on 4 continent’s at London, New
        York, Alexandria, Cairo and representative office at Riyadh and Dubai. As a Foreign Language Teaching Expert and
        Innovator of the Mongi-Immerse Language Teaching Methodology, he registered this exclusive teaching methodology
        at the Library of the Congress (USA)."</p>
    </div>
  </div>

  <div class="our-founder-sec3">
    <div class="founder-text">
      <h1>A BUSINESS LEADER</h1>
      <p>
        "Dr. Shalaby is a person who values scientific methods and practical experience and incorporates them to obtain
        results in the easiest and fastest manner. In addition to that; he has shown that the following traits: A leader
        who deals with challenges systematically with documented plans. An agile learner who mastered the art of
        knowledge acquisition from experts in their field, as well as the best way to communicate with different
        personalities. A proficient lecturer to groups, with the ability to simplify, present and speak fluently. Strong
        knowledge in corporate governance, risk management and strategic planning. Enjoys an extensive experience in
        establishing, launching, and managing SMEs globally. A regular participant in scientific and academic
        conferences in the ESL/TESOL field."
      </p>
    </div>
    <div class="founder-images">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder/1.png" alt="Image 1">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder/2.png" alt="Image 2">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder/3.png" alt="Image 3">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder/5.png" alt="Image 5">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder/4.png" alt="Image 4">
    </div>
  </div>

  <div class="kids-sec3">
    <h1>AN ACHIEVER</h1>
    <div class="kids-iframe-container">
      <iframe src="https://player.vimeo.com/video/818262813" frameborder="0" allow="autoplay; fullscreen"
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <div class="our-founder-sec5">
    <div class="founder-info">
      <h1>A LEARNER</h1>
      <p>
        Being a believer in the wisdom of teamwork and collective knowledge, Dr. Shalaby is a professional member in
        more than ten multinational organizations and associations in the fields of education, English language teaching
        to speakers of other languages, quality and management.
      </p>
      <ul>
        <li>Professional Member of the European Association for Language Testing and Assessment (EALTA) (England)</li>
        <li>Professional Member of the American Management Association (AMA) (America)</li>
        <li>Professional Member of the European Center for Management (MCE) (Belgium)</li>
        <li>Professional Member of the Learning Guild (America)</li>
        <li>Professional Member of the Arab Business Management Association (ARABMA) (Egypt)</li>
        <li>Professional Member of the Egyptian Quality Association (ESQ) (Egypt)</li>
        <li>Professional Member of the Arab Organization for Quality in Education (AROQA) (Belgium and Jordan)</li>
        <li>Professional Member of the International English Language Testing Association (TESOL International)
          (America)</li>
        <li>Professional Member of the International Language Testing Association (ILTA) (America)</li>
        <li>Professional Member of the Association for Distance Learning and Independent Education (ADEIL) (America)
        </li>
        <li>Professional Member of the Association for Language Examinations in Europe (ALTE) (England)</li>
      </ul>
    </div>
    <div class="founder2-images">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/1.png" alt="Image 1">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/2.png" alt="Image 2">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/3.png" alt="Image 3">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/4.png" alt="Image 4">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/5.png" alt="Image 5">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/6.png" alt="Image 6">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/7.png" alt="Image 7">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/8.png" alt="Image 8">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/9.png" alt="Image 9">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/10.png" alt="Image 10">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/11.png" alt="Image 11">
      <img loading="lazy" src="https://monglish.b-cdn.net/founder2/12.png" alt="Image 12">
    </div>
  </div>
</template>

<script>
export default {
  name: "OurFounderSection",
}
</script>

<style scoped>
.our-founder-sec1 {
  background-image: url('https://monglish.b-cdn.net/home/our-founder.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  font-family: 'DIN Next LT Arabic', sans-serif;
  display: flex;
  height: 100vh;
  margin-top: -85px;
  position: relative;
  align-items: center;
  justify-content: center;
  direction: ltr;
  transform: scaleX(-1);
}

.founder-content {
  text-align: left;
  transform: scaleX(-1);
  padding: 1em;
}

.founder-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
}

.founder-content p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  padding-right: 40%;
}

@media (max-width: 1200px) {
  .founder-content {
    max-width: 70%;
  }

  .founder-content h1 {
    font-size: 3rem;
  }

  .founder-content p {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .founder-content {
    max-width: 80%;
    padding-top: 8%;
  }

  .founder-content h1 {
    font-size: 2.5rem;
  }

  .founder-content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .our-founder-sec1 {
    flex-direction: column;
    padding: 20% 5%;
    height: auto;
  }

  .founder-content {
    max-width: 90%;
    text-align: center;
    padding-top: 10%;
  }

  .founder-content h1 {
    font-size: 2rem;
  }

  .founder-content p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .our-founder-sec1 {
    background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
    flex-direction: column;
    padding: 0;
    height: auto;
  }

  .our-founder-sec1::before {
    content: '';
    background-image: url('https://monglish.b-cdn.net/home/our-founder-mob.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
    display: block;
  }

  .founder-content {
    max-width: 100%;
    padding-top: 2%;
    text-align: center;
    padding-bottom: 5%;
  }

  .founder-content h1 {
    font-size: 1.8rem;
  }

  .founder-content p {
    font-size: 0.9rem;
    padding-right: 0%;
  }
}

.our-founder-sec2 {
  background-image: url('https://monglish.b-cdn.net/home/our-founder2_bg.jpg');
  background-size: cover;
  background-position: center;
  color: #165e84 !important;
  font-family: 'DIN Next LT Arabic', sans-serif;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  direction: ltr;
}

.founder-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.founder-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.founder-content-sec2 {
  flex: 1;
  padding: 10%;
}

.founder-content-sec2 h1 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  text-align: left;
}

.founder-content-sec2 p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: left;
}

@media (max-width: 768px) {
  .founder-content-sec2 {
    padding: 0;
  }

  .founder-content-sec2 h1 {
    font-size: 1.5em;
    text-align: center;
  }

  .founder-content-sec2 p {
    font-size: 1em;
    padding: 2%;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .our-founder-sec2 {
    flex-direction: row;
    height: 70vh;
    padding: 0;
  }

  .founder-image {
    justify-content: flex-end;
    margin-bottom: 0;
  }

  .founder-content-sec2 {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .our-founder-sec2 {
    height: 85vh;
  }

  .founder-content-sec2 {
    padding-right: 2%;
  }
}


.our-founder-sec3 {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: auto;
  position: relative;
  padding: 4% 2%;
  direction: ltr;
}

.founder-text {
  flex: 1;
  padding-top: 5%;
  max-width: 50%;
  margin-right: 5%;
  margin-left: 2%;
}

.founder-text h1 {
  font-size: 3.2em;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
}

.founder-text p {
  font-size: 1.2em;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
}

.founder-images {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.founder-images img {
  width: 30%;
  height: auto;
  margin-bottom: 10px;
}

.founder-images img:nth-child(4) {
  width: 30%;
  height: auto;
}

.founder-images img:nth-child(5) {
  width: 65%;
  height: auto;
}

@media (max-width: 1200px) {
  .founder-text {
    max-width: 100%;
    margin: 0;
  }

  .founder-images img {
    width: 48%;
  }

  .founder-images img:nth-child(4),
  .founder-images img:nth-child(5) {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .our-founder-sec3 {
    flex-direction: column;
    align-items: center;
  }

  .founder-text {
    max-width: 100%;
    margin: 0;
    padding-top: 0;
    text-align: center;
  }

  .founder-text h1 {
    font-size: 2.4rem;
  }

  .founder-text p {
    font-size: 1.2rem;
    padding-bottom: 1em;
  }

  .founder-images {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }

  .founder-images img {
    width: 48%;
    height: 150px;
    margin-bottom: 10px;
  }

  .founder-images img:nth-child(4) {
    width: 48%;
    height: 150px;
  }

  .founder-images img:nth-child(5) {
    width: 100%;
  }
}


.our-founder-sec5 {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'DIN Next LT Arabic', sans-serif;
  color: white;
  display: flex;
  height: 70vh;
  padding: 5% 0;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  direction: ltr;
}

.founder-info {
  max-width: 50%;
  padding-left: 3%;
}

.founder-info h1 {
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin-bottom: 10px;
}

.founder-info p {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.founder-info ul {
  padding: 0;
}

.founder-info ul li {
  font-size: 1rem;
  margin-bottom: 5px;
}

.founder2-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  width: 45%;
  background-color: #fff;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  padding: 10px;
}

.founder2-images img {
  display: block;
  width: 80%;
  height: auto;
  object-fit: cover;
  margin: auto;
}

@media (max-width: 1200px) {
  .our-founder-sec5 {
    flex-direction: column;
    height: auto;
  }

  .founder-info {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .founder2-images {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .founder-info h1 {
    font-size: 2rem;
  }

  .founder-info p {
    font-size: 1rem;
  }

  .founder2-images {
    grid-template-columns: repeat(2, 1fr);
    border-radius: 15px;
  }
}

@media (max-width: 480px) {
  .founder-info {
    text-align: center;
  }

  .founder-info h1 {
    font-size: 1.5rem;
  }

  .founder-info p {
    font-size: 0.9rem;
  }

  .founder2-images {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    padding: 0;
  }
}


.kids-sec3 {
  background-image: url('https://monglish.b-cdn.net/kids/sec3-bg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}

.kids-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}

.kids-iframe-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}

.kids-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}

@media (max-width: 1200px) {
  .kids-sec3 h1 {
    font-size: 2.2em;
  }

  .kids-iframe-container {
    width: 90%;
    padding-bottom: 50%;
  }
}

@media (max-width: 768px) {
  .kids-sec3 h1 {
    font-size: 1.8em;
  }

  .kids-iframe-container {
    width: 95%;
    padding-bottom: 56.25%;
  }
}

@media (max-width: 480px) {
  .kids-sec3 h1 {
    font-size: 1.5em;
  }

  .kids-iframe-container {
    width: 100%;
    padding-bottom: 60%;
  }
}
</style>
