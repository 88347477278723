<template>
  <div class="faq-section-n">
    <h4>Study-Related affairs</h4>
    <div class="faq-items-n">
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
          <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
          <button @click="toggleAnswer(index + 6)" :class="{ active: activeIndex === index + 6 }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index + 6 }">
            <span v-if="activeIndex === index + 6">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index + 6" :class="['answer', { 'answer-active': activeIndex === index + 6 }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewThreePartOne',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'Are the study schedules suitable for my timetable?',
          answer: 'The academy strives to offer flexible and multiple scheduling options to accommodate all students as much as possible.'
        },
        {
          question: 'I don’t like studying English?',
          answer: 'The immers eion philosophy aims to address boredom and difficulty for foreign language learners through a unique blend of recreational, academic, and educational clubs, leading students to develop a passion for the language and learn it with ease (Conversation Club, Phonetics, Talents, Debates, Cinema, Reading, Mama Mongi, Life Coach, Social, Art, Writing).'
        },
        {
          question: 'Who are the teachers, are they accredited, and what are their qualifications?',
          answer: 'They are a group of qualified and accredited education professionals with strong experience in teaching English. They have been carefully selected to ensure the best educational experience for students. Each teacher adheres to the quality and competency standards set by the academy, ensuring the highest level of education and support for each student.'
        },
      ],
      faqItems_2: [
        {
          question: 'How are students motivated?',
          answer: 'Monglish adopts a reward system and periodic competitions (Points System - Mongi Points) and uses a motivating teaching approach that is exceptional and unique, fostering a spirit of competition among students. Outstanding and winning students are awarded the "Star of the Month" certificate.'
        },
        {
          question: 'How are individual differences handled?',
          answer: 'Monglish has a team of distinguished educational experts specializing in psychological counseling, behavior modification, and family counseling. They conduct a free interview upon subscribing to any Monglish course to identify personality traits and help achieve better academic results.'
        },
        {
          question: 'Can I communicate with the teacher directly?',
          answer: 'The academy ensures a monthly direct meeting with students’ parents, class supervisors, and educational stage managers. We also provide daily follow-up for parents after each class to monitor the student’s progress and respond to inquiries and suggestions.'
        }
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>

<style scoped>
.faq-section-n{
  direction:ltr;
}
.faq-item-n button , .faq-item-n .answer{
  text-align: left;
}
.faq-section-n h4{
  text-align: left;
  padding-left:30px;
  margin-top: 20px;
}
</style>