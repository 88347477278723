<template>
  <div class="refund-policy">
    <h2 class="title">Refund and Deferal Policies</h2>
    <h2 class="sub-title">
      The client shall be entitled to claim a refund or deferral (rescheduling)
      in the following cases:
    </h2>

    <ul class="list">
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"The client shall be entitled to claim a refund or deferral (rescheduling) in the following cases:"}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >The client shall be entitled to claim a refund or deferral
          (rescheduling) in the following cases:</span
        >
      </li>
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"A full refund will be provided on the actual cost of the course (after deducting 10% as administrative fees). A will be provided up to 48 hours prior to the course start date. Cancellations received less than 48 hours before the course, are not entitled to a refund."}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >A full refund will be provided on the actual cost of the course
          (after deducting 10% as administrative fees). A will be provided up to
          48 hours prior to the course start date. Cancellations received less
          than 48 hours before the course, are not entitled to a refund.</span
        >
      </li>
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"In the event that a new client registers with the freezing option (postponing the start date), a full refund is possible within 14 days of payment (provided that they did not attend a placement test or started training). The freezing option is available for a maximum period of six months’."}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >In the event that a new client registers with the freezing option
          (postponing the start date), a full refund is possible within 14 days
          of payment (provided that they did not attend a placement test or
          started training). The freezing option is available for a maximum
          period of six months’.</span
        >
      </li>
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"Clients are not entitled to a refund after the periods mentioned above."}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >Clients are not entitled to a refund after the periods mentioned
          above.</span
        >
      </li>
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"Refunds are processed within two weeks maximum, after contacting us."}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >Refunds are processed within two weeks maximum, after contacting
          us.</span
        >
      </li>
      <li>
        <span
          data-sheets-root="1"
          data-sheets-value='{"1":2,"2":"The client bears all international transfer costs paid to process the refund."}'
          data-sheets-userformat='{"2":8705,"3":{"1":0},"12":0,"16":10}'
          >The client bears all international transfer costs paid to process the
          refund. It should be noted that it is not possible to assign course
          enrollments to others (siblings or relatives / freeze during courses /
          attend only basic classes without the clubs).</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RefundPolicy",
};
</script>
<style scoped>
.refund-policy {
  direction: ltr;
  width: 80%;
  margin: auto;
  padding: 80px !important;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important;
  margin-top: -130px;
  margin-bottom: 100px;
}

.title {
  color: #165e84;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;
  margin-bottom: 10px;
}

.sub-title {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 25px;
}

.list {
  list-style: unset;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.5em;
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .refund-policy {
    padding: 30px !important;
  }

  .title {
    font-size: 28px;
  }
}
</style>