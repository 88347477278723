import { createRouter, createWebHistory } from 'vue-router';
import NotFoundComponent from '../components/NotFoundComponent.vue';

// English imports
import HomeEn from '../views/en/HomePage.vue';
import OurTeamEn from '../views/en/OurTeamPage.vue';
import KidsCoursesEn from '../views/en/KidsCoursesPage.vue';
import AdultsCoursesEn from '../views/en/AdultsCoursesPage.vue';
import JoinUsEn from '../views/en/JoinUsPage.vue';
import BlogListEn from '../views/en/BlogList.vue';
import BlogOneEn from '../components/en/BlogOne.vue';
import BlogTwoEn from '../components/en/BlogTwo.vue';
import BlogThreeEn from '../components/en/BlogThree.vue';
import BlogFourEn from '../components/en/BlogFour.vue';
import BlogFiveEn from '../components/en/BlogFive.vue';
import BlogSixEn from '../components/en/BlogSix.vue';
import ContactUsEn from '../views/en/ContactUsPage.vue';
import RegisterEn from '../views/en/RegisterPage.vue';
import LoginEn from '../views/en/LoginPage.vue';
import VisionMissionEn from '../views/en/VisionAndMission.vue';
import StudyAbroadEn from '../views/en/StudyAbroad.vue';
import FAQ_En from '../views/en/FAQ_Page.vue';
import AccredationsEn from '../views/en/AccredationsPage.vue';
import PackagesEn from '../views/en/PackagesPage.vue';
import PackagesViewEn from '../views/en/PackagesView.vue';
import PackagesKidsEn from '../views/en/PackagesKids.vue';
import PackagesAdultsEn from '../views/en/PackagesAdults.vue';
import CartEn from '../views/en/CartPage.vue';
import IletsCoursesEn from '../views/en/IletsCoursesPage.vue';
import MonglishImmerseEn from '../views/en/MonglishImmerse.vue';
import MonglishPromiseEn from '../views/en/MonglishPromise.vue';
import MonglishReasonsEn from '../views/en/MonglishReasons.vue';
import CertificationsEn from '../views/en/CertificationsPage.vue';
import OurFounderEn from '../views/en/OurFounder.vue';
import YoutubeVideosEn from '../views/en/YoutubeVideos.vue';
import PhotoGalleryEn from '../views/en/PhotoGallery.vue';
import RefundComponent from '@/components/en/RefundPolicy.vue';
import SecurityComponent from '@/components/en/SecurityPolicy.vue';
import PrivacyPolicyComponent from '@/components/en/PrivacyPolicy.vue';
import TermsConditionComponent from '@/components/en/TermsConditions.vue';
import AllTermsConditionsComponent from '@/components/en/AllTermsConditionsComponent.vue';
import TermsAndConditionsEn from '../views/en/TermsAndConditionsEn.vue';
import JobRegistrationPageEn from '../views/en/JobRegistrationPage.vue';
import TeacherOneEn from '@/components/en/TeacherOne.vue';
import TeacherTwoEn from '@/components/en/TeacherTwo.vue';
import TeacherThreeEn from '@/components/en/TeacherThree.vue';
import TeacherFourEn from '@/components/en/TeacherFour.vue';
import TeacherFiveEn from '@/components/en/TeacherFive.vue';
import TeacherSixEn from '@/components/en/TeacherSix.vue';
import TeacherEightEn from '@/components/en/TeacherEight.vue';
import TeacherNineEn from '@/components/en/TeacherNine.vue';
import TeacherTenEn from '@/components/en/TeacherTen.vue';
import TeacherElevenEn from '@/components/en/TeacherEleven.vue';
import TeacherTwelveEn from '@/components/en/TeacherTwelve.vue';
import TeacherThirteenEn from '@/components/en/TeacherThirteen.vue';
import TeacherFourteenEn from '@/components/en/TeacherFourteen.vue';
import TeacherFifteenEn from '@/components/en/TeacherFifteen.vue';
import TeacherSixteenEn from '@/components/en/TeacherSixteen.vue';
import TeacherSeventeenEn from '@/components/en/TeacherSeventeen.vue';
import TeacherEighteenEn from '@/components/en/TeacherEighteen.vue';
import TeacherNineteenEn from '@/components/en/TeacherNineteen.vue';
import TeacherTwentyEn from '@/components/en/TeacherTwenty.vue';
import TeacherTwentyOneEn from '@/components/en/TeacherTwentyOne.vue';
import TeacherTwentyTwoEn from '@/components/en/TeacherTwentyTwo.vue';
import TeacherTwentyThreeEn from '@/components/en/TeacherTwentyThree.vue';
import TeacherTwentyFourEn from '@/components/en/TeacherTwentyFour.vue';
import TeacherTwentyFiveEn from '@/components/en/TeacherTwentyFive.vue';
import TeacherTwentySixEn from '@/components/en/TeacherTwentySix.vue';
import TeacherTwentySevenEn from '@/components/en/TeacherTwentySeven.vue';
import TeacherTwentyEightEn from '@/components/en/TeacherTwentyEight.vue';
import TeacherTwentyNineEn from '@/components/en/TeacherTwentyNine.vue';
import TeacherThirtyEn from '@/components/en/TeacherThirty.vue';
import TeacherEn from '../views/en/TeachersList.vue';

// Arabic imports
import HomeAr from '../views/ar/HomePage.vue';
import OurTeamAr from '../views/ar/OurTeamPage.vue';
import KidsCoursesAr from '../views/ar/KidsCoursesPage.vue';
import AdultsCoursesAr from '../views/ar/AdultsCoursesPage.vue';
import JoinUsAr from '../views/ar/JoinUsPage.vue';
import BlogListAr from '../views/ar/BlogList.vue';
import BlogOneAr from '../components/ar/BlogOne.vue';
import BlogTwoAr from '../components/ar/BlogTwo.vue';
import BlogThreeAr from '../components/ar/BlogThree.vue';
import BlogFourAr from '../components/ar/BlogFour.vue';
import BlogFiveAr from '../components/ar/BlogFive.vue';
import BlogSixAr from '../components/ar/BlogSix.vue';
import BlogSevenAr from '../components/ar/BlogSeven.vue';
import BlogEightAr from '../components/ar/BlogEight.vue';
import BlogNineAr from '../components/ar/BlogNine.vue';
import BlogTenAr from '../components/ar/BlogTen.vue';
import BlogElevenAr from '../components/ar/BlogEleven.vue';
import BlogTwelveAr from '../components/ar/BlogTwelve.vue';
import BlogThirteenAr from '../components/ar/BlogThirteen.vue';
import BlogFourteenAr from '../components/ar/BlogFourteen.vue';
import BlogFifteenAr from '../components/ar/BlogFifteen.vue';
import BlogSixteenAr from '../components/ar/BlogSixteen.vue';
import BlogSeventeenAr from '../components/ar/BlogSeventeen.vue';
import BlogEighteenAr from '../components/ar/BlogEighteen.vue';
import ContactUsAr from '../views/ar/ContactUsPage.vue';
import RegisterAr from '../views/ar/RegisterPage.vue';
import LoginAr from '../views/ar/LoginPage.vue';
import VisionMissionAr from '../views/ar/VisionAndMission.vue';
import StudyAbroadAr from '../views/ar/StudyAbroad.vue';
import FAQ_Ar from '../views/ar/FAQ_Page.vue';
import AccredationsAr from '../views/ar/AccredationsPage.vue';
import PackagesAr from '../views/ar/PackagesPage.vue';
import PackagesViewAr from '../views/ar/PackagesView.vue';
import PackagesKidsAr from '../views/ar/PackagesKids.vue';
import PackagesAdultsAr from '../views/ar/PackagesAdults.vue';
import CartAr from '../views/ar/CartPage.vue';
import IletsCoursesAr from '../views/ar/IletsCoursesPage.vue';
import MonglishImmerseAr from '../views/ar/MonglishImmerse.vue';
import MonglishPromiseAr from '../views/ar/MonglishPromise.vue';
import MonglishReasonsAr from '../views/ar/MonglishReasons.vue';
import CertificationsAr from '../views/ar/CertificationsPage.vue';
import OurFounderAr from '../views/ar/OurFounder.vue';
import YoutubeVideosAr from '../views/ar/YoutubeVideos.vue';
import PaymentSuccessAr from '../views/ar/PaymentSuccessPage.vue';
import PhotoGalleryAr from '../views/ar/PhotoGallery.vue';
import TermsAndConditions from '../views/ar/termsandconditions.vue';
import JobRegistrationPageAr from '../views/ar/JobRegistrationPage.vue';
import RefundComponentAr from '@/components/ar/RefundPolicy.vue';
import SecurityComponentAr from '@/components/ar/SecurityPolicy.vue';
import PrivacyPolicyComponentAr from '@/components/ar/PrivacyPolicy.vue';
import TermsConditionComponentAr from '@/components/ar/TermsCondition.vue';
import AllTermsConditionsComponentAr from '@/components/ar/AllTermsConditionsComponent.vue';
import TeacherOneAr from '@/components/ar/TeacherOne.vue';
import TeacherTwoAr from '@/components/ar/TeacherTwo.vue';
import TeacherThreeAr from '@/components/ar/TeacherThree.vue';
import TeacherFourAr from '@/components/ar/TeacherFour.vue';
import TeacherFiveAr from '@/components/ar/TeacherFive.vue';
import TeacherSixAr from '@/components/ar/TeacherSix.vue';
import TeacherEightAr from '@/components/ar/TeacherEight.vue';
import TeacherNineAr from '@/components/ar/TeacherNine.vue';
import TeacherTenAr from '@/components/ar/TeacherTen.vue';
import TeacherElevenAr from '@/components/ar/TeacherEleven.vue';
import TeacherTwelveAr from '@/components/ar/TeacherTwelve.vue';
import TeacherThirteenAr from '@/components/ar/TeacherThirteen.vue';
import TeacherFourteenAr from '@/components/ar/TeacherFourteen.vue';
import TeacherFifteenAr from '@/components/ar/TeacherFifteen.vue';
import TeacherSixteenAr from '@/components/ar/TeacherSixteen.vue';
import TeacherSeventeenAr from '@/components/ar/TeacherSeventeen.vue';
import TeacherEighteenAr from '@/components/ar/TeacherEighteen.vue';
import TeacherNineteenAr from '@/components/ar/TeacherNineteen.vue';
import TeacherTwentyAr from '@/components/ar/TeacherTwenty.vue';
import TeacherTwentyOneAr from '@/components/ar/TeacherTwentyOne.vue';
import TeacherTwentyTwoAr from '@/components/ar/TeacherTwentyTwo.vue';
import TeacherTwentyThreeAr from '@/components/ar/TeacherTwentyThree.vue';
import TeacherTwentyFourAr from '@/components/ar/TeacherTwentyFour.vue';
import TeacherTwentyFiveAr from '@/components/ar/TeacherTwentyFive.vue';
import TeacherTwentySixAr from '@/components/ar/TeacherTwentySix.vue';
import TeacherTwentySevenAr from '@/components/ar/TeacherTwentySeven.vue';
import TeacherTwentyEightAr from '@/components/ar/TeacherTwentyEight.vue';
import TeacherTwentyNineAr from '@/components/ar/TeacherTwentyNine.vue';
import TeacherThirtyAr from '@/components/ar/TeacherThirty.vue';
import TeacherAr from '../views/ar/TeachersList.vue';

const englishRoutes = [
  { path: '/', name: 'HomeEn', component: HomeEn, meta: { title: 'Learn English with Our Unique Mongi Immerse Approach', description: 'Discover the Monglish Immersion Method to help your child speak English fluently with professionalism and confidence. Start your child\'s English language journey today!' } },
  { path: '/en/our-team', name: 'OurTeamEn', component: OurTeamEn, meta: { title: 'Monglish Academy Experts | Success at Every Step', description: 'Monglish Academy features a global team of over 300 specialized partners, providing a unique English language teaching experience with top-quality professionalism.' } },
  { path: '/en/join-us', name: 'JoinUsEn', component: JoinUsEn, meta: { title: 'Work with the Best: Explore Careers at Monglish Academy', description: 'Are you passionate about teaching? Monglish offers a dynamic work environment, competitive benefits, and the chance to learn and grow with a supportive team.' } },
  { path: '/en/blog', name: 'BlogsEn', component: BlogListEn, meta: { title: 'Blog - Monglish Global Network' } },
  { path: '/en/contact', name: 'ContactUsEn', component: ContactUsEn, meta: { title: 'Contact Us | Monglish International Academy', description: 'Get in touch! We\'re happy to answer your questions. Phone, email, and contact form available.' } },
  { path: '/en/register', name: 'RegisterEn', component: RegisterEn, meta: { title: 'Register - Monglish Global Network' } },
  { path: '/en/login', name: 'LoginEn', component: LoginEn, meta: { title: 'Login - Monglish Global Network' } },
  { path: '/en/vision-mission', name: 'VisionMissionEn', component: VisionMissionEn, meta: { title: 'Our Vision at Monglish Academy: Bright Future Aspirations', description: 'Our mission & vision are to become the leading digital academy, offering unparalleled language learning experiences for a positive impact on our clients\' lives.' } },
  { path: '/en/study-abroad', name: 'StudyAbroadEn', component: StudyAbroadEn, meta: { title: 'Study Abroad | Exceptional Educational Experience Awaits You', description: 'Enhancing our study abroad program with leading global universities, offering a unique educational opportunity. Overcome language barriers with Monglish.' } },
  { path: '/en/faq', name: 'FAQ_En', component: FAQ_En, meta: { title: 'FAQ - Monglish Global Network' } },
  { path: '/en/accreditations', name: 'AccredationsEn', component: AccredationsEn, meta: { title: 'Certified English Courses for Kids by Monglish!', description: 'Certified courses for your kids! Monglish offers top-quality online English education, accredited by IOTQ. Our goal is to exceed customer expectations.' } },
  { path: '/en/packages', name: 'PackagesEn', component: PackagesEn, meta: { title: 'Packages - Monglish Global Network' } },
  { path: '/en/package', name: 'PackagesViewEn', component: PackagesViewEn, meta: { title: 'Package Details - Monglish Global Network' } },
  { path: '/en/packages-kids', name: 'PackagesKidsEn', component: PackagesKidsEn, meta: { title: 'Kids Packages - Monglish Global Network' } },
  { path: '/en/packages-adults', name: 'PackagesAdultsEn', component: PackagesAdultsEn, meta: { title: 'Adults Packages - Monglish Global Network' } },
  { path: '/en/cart', name: 'CartEn', component: CartEn, meta: { title: 'Cart - Monglish Global Network' } },
  { path: '/en/kids-courses', name: 'KidsCoursesEn', component: KidsCoursesEn, meta: { title: 'English Courses Online', description: 'Interactive English Online Courses for Kids. Build their skills confidently and ignite their passion for language. Enroll now and invest in their future!' } },
  { path: '/en/adult-courses', name: 'AdultsCoursesEn', component: AdultsCoursesEn, meta: { title: 'English Adults Course: Master Your Career with Monglish', description: 'Achieve your career goals with Monglish\'s online English course! Different levels, specialized programs, and language experts. Enroll now for a bright future!' } },
  { path: '/en/monglish-immerse', name: 'MonglishImmerseEn', component: MonglishImmerseEn, meta: { title: 'Monglish Immersion Method: Your Child Speaks English Fluently', description: 'Discover the Monglish Immersion Method to help your child speak English with professionalism and confidence. Start your child\'s English language journey today.' } },
  { path: '/en/monglish-promise', name: 'MonglishPromiseEn', component: MonglishPromiseEn, meta: { title: 'Online English Courses with Native Speakers', description: 'High-quality educational content by experienced instructors and native speakers. Our user-friendly platform offers a seamless learning experience. Join Now!' } },
  { path: '/en/monglish-reasons', name: 'MonglishReasonsEn', component: MonglishReasonsEn, meta: { title: 'Advanced E-learning | Quality, Interactivity, and Mastery', description: '12 Reasons to Choose Monglish: Real job opportunities through English learning. Develop your child for a brighter future. Over 1,941,506 sessions completed.' } },
  { path: '/en/certifications', name: 'CertificationsEn', component: CertificationsEn, meta: { title: 'Monglish | Holder of Numerous Global Quality Certifications', description: 'Monglish International Academy is ISO 9001, 29993, 29994 certified, reflecting our commitment to quality management in our practices.' } },
  {
    path: '/en/our-founder',
    name: 'OurFounderEn',
    component: OurFounderEn,
    meta: {
      title: 'Dr. Mohamed Shalaby | Founder and CEO of Monglish Academy',
      description: 'Dr. Mohamed Shalaby introduced the Monglish Immerse methodology for English language learning, registered in the Library of Congress.'
    }
  },
  {
    path: '/en/ielts-courses',
    name: 'IletssCoursesEn',
    component: IletsCoursesEn,
    meta: {
      title: 'Ilets Packages - Monglish Global Network',
      description: 'Explore the various IELTS preparation packages offered by Monglish Global Network to help you achieve your desired band score.'
    }
  },
  {
    path: '/en/youtube-videos',
    name: 'YoutubeVideosEn',
    component: YoutubeVideosEn,
    meta: {
      title: 'YouTube Videos - Monglish Global Network',
      description: 'Discover educational videos and resources on English language learning from Monglish Global Network on our YouTube channel.'
    }
  },
  {
    path: '/en/photo-gallery',
    name: 'PhotoGalleryEn',
    component: PhotoGalleryEn,
    meta: {
      title: 'Photo Gallery - Monglish Global Network',
      description: 'Browse through our photo gallery showcasing various events and activities at Monglish Academy.'
    }
  },
  {
    path: '/en/job-registration',
    name: 'JobRegistrationPageEn',
    component: JobRegistrationPageEn,
    meta: {
      title: 'Job Registration - Monglish Global Network',
      description: 'Register for a job opportunity at Monglish Global Network and become part of our passionate team dedicated to English education.'
    }
  },
  {
    path: '/en/terms-and-conditions',
    name: 'TermsAndConditionsEn',
    component: TermsAndConditionsEn,
    children: [
      {
        path: 'all',
        name: 'AllTermsConditionsEn',
        component: AllTermsConditionsComponent,
        meta: {
          title: 'All Terms - Monglish Global Network',
          description: 'Read the complete terms and conditions for using the services of Monglish Global Network.'
        },
      },
      {
        path: 'refund',
        name: 'RefundEn',
        component: RefundComponent,
        meta: {
          title: 'Refund Policy - Monglish Global Network',
          description: 'Learn about the refund policy and your rights when making a purchase with Monglish Global Network.'
        }
      },
      {
        path: 'security',
        name: 'SecurityEn',
        component: SecurityComponent,
        meta: {
          title: 'Security Policy - Monglish Global Network',
          description: 'Understand how Monglish Global Network ensures the security of your personal and payment information.'
        }
      },
      {
        path: 'privacy',
        name: 'PrivacyPolicyEn',
        component: PrivacyPolicyComponent,
        meta: {
          title: 'Privacy Policy - Monglish Global Network',
          description: 'Read Monglish Global Network’s privacy policy to understand how we protect and manage your personal data.'
        }
      },
      {
        path: 'terms',
        name: 'TermsConditionsEn',
        component: TermsConditionComponent,
        meta: {
          title: 'Terms and Conditions - Monglish Global Network',
          description: 'Learn about the terms and conditions of services provided by Monglish Global Network.'
        }
      },
      {
        path: '/en/terms-and-conditions/',
        redirect: '/en/terms-and-conditions/all',
      }
    ],
  },
  {
    path: '/en/blog/learning-english-in-early-ages',
    name: 'BlogOneEn',
    component: BlogOneEn,
    meta: {
      title: 'Learning English in Early Ages - Monglish Global Network',
      description: 'Explore the benefits of learning English at an early age and how Monglish Academy fosters language development.'
    }
  },
  {
    path: '/en/blog/celebrating-childrens-language-progress',
    name: 'BlogTwoEn',
    component: BlogTwoEn,
    meta: {
      title: 'Celebrating Children\'s Language Progress - Monglish Global Network',
      description: 'Read about how children’s language skills progress and the impact of early language learning at Monglish Academy.'
    }
  },
  {
    path: '/en/blog/parents-and-children-healthy-relationships/',
    name: 'BlogFourEn',
    component: BlogFourEn,
    meta: {
      title: 'Parents and Children Healthy Relationships - Monglish Global Network',
      description: 'Discover the importance of building healthy relationships between parents and children for better language development.'
    }
  },
  {
    path: '/en/blog/seasons-of-the-year',
    name: 'BlogThreeEn',
    component: BlogThreeEn,
    meta: {
      title: 'Seasons of the Year - Monglish Global Network',
      description: 'Learn about the seasons of the year and how Monglish Academy incorporates seasonal learning in its curriculum.'
    }
  },
  {
    path: '/en/blog/activities-for-positive-behavior',
    name: 'BlogFiveEn',
    component: BlogFiveEn,
    meta: {
      title: 'Activities for Positive Behavior - Monglish Global Network',
      description: 'Read about activities that promote positive behavior in children and their role in effective language learning at Monglish.'
    }
  },
  {
    path: '/en/blog/benefits-of-bilingual-for-children',
    name: 'BlogSixEn',
    component: BlogSixEn,
    meta: {
      title: 'Benefits of Bilingualism for Children - Monglish Global Network',
      description: 'Understand the cognitive and social benefits of raising bilingual children and how Monglish Academy fosters bilingual education.'
    }
  },
  {
    path: '/en/teachers/one', name: 'TeacherOneEn', component: TeacherOneEn
  },
  { path: '/en/teachers/two', name: 'TeacherTwoEn', component: TeacherTwoEn },
  { path: '/en/teachers/three', name: 'TeacherThreeEn', component: TeacherThreeEn },
  { path: '/en/teachers/four', name: 'TeacherFourEn', component: TeacherFourEn },
  { path: '/en/teachers/five', name: 'TeacherFiveEn', component: TeacherFiveEn },
  { path: '/en/teachers/six', name: 'TeacherSixEn', component: TeacherSixEn },
  { path: '/en/teachers/eight', name: 'TeacherEightEn', component: TeacherEightEn },
  { path: '/en/teachers/nine', name: 'TeacherNineEn', component: TeacherNineEn },
  { path: '/en/teachers/ten', name: 'TeacherTenEn', component: TeacherTenEn },
  { path: '/en/teachers/eleven', name: 'TeacherElevenEn', component: TeacherElevenEn },
  { path: '/en/teachers/twelve', name: 'TeacherTwelveEn', component: TeacherTwelveEn },
  { path: '/en/teachers/thirteen', name: 'TeacherThirteenEn', component: TeacherThirteenEn },
  { path: '/en/teachers/fourteen', name: 'TeacherFourteenEn', component: TeacherFourteenEn },
  { path: '/en/teachers/fifteen', name: 'TeacherFifteenEn', component: TeacherFifteenEn },
  { path: '/en/teachers/sixteen', name: 'TeacherSixteenEn', component: TeacherSixteenEn },
  { path: '/en/teachers/seventeen', name: 'TeacherSeventeenEn', component: TeacherSeventeenEn },
  { path: '/en/teachers/eighteen', name: 'TeacherEighteenEn', component: TeacherEighteenEn },
  { path: '/en/teachers/nineteen', name: 'TeacherNineteenEn', component: TeacherNineteenEn },
  { path: '/en/teachers/twenty', name: 'TeacherTwentyEn', component: TeacherTwentyEn },
  { path: '/en/teachers/twenty-one', name: 'TeacherTwentyOneEn', component: TeacherTwentyOneEn },
  { path: '/en/teachers/twenty-two', name: 'TeacherTwentyTwoEn', component: TeacherTwentyTwoEn },
  { path: '/en/teachers/twenty-three', name: 'TeacherTwentyThreeEn', component: TeacherTwentyThreeEn },
  { path: '/en/teachers/twenty-four', name: 'TeacherTwentyFourEn', component: TeacherTwentyFourEn },
  { path: '/en/teachers/twenty-five', name: 'TeacherTwentyFiveEn', component: TeacherTwentyFiveEn },
  { path: '/en/teachers/twenty-six', name: 'TeacherTwentySixEn', component: TeacherTwentySixEn },
  { path: '/en/teachers/twenty-seven', name: 'TeacherTwentySevenEn', component: TeacherTwentySevenEn },
  { path: '/en/teachers/twenty-eight', name: 'TeacherTwentyEightEn', component: TeacherTwentyEightEn },
  { path: '/en/teachers/twenty-nine', name: 'TeacherTwentyNineEn', component: TeacherTwentyNineEn },
  { path: '/en/teachers/thirty', name: 'TeacherThirtyEn', component: TeacherThirtyEn },
  {
    path: '/en/teachers',
    name: 'teachersEn',
    component: TeacherEn,
    meta: {
    title: 'Monglish | Teachers',
    description: 'Monglish International Academy offers a team of highly qualified teachers who bring expertise and commitment into the classroom, enhancing the learning experience.'
    }
    }
];

const arabicRoutes = [
  {
    path: '/ar/', name: 'HomeAr', component: HomeAr,
    meta: {
      title: 'تعلم اللغة الانجليزية بأسلوب ممتع ومؤثر مع أكاديمية مونجلش',
      description: 'اكتشف منهج المعايشة الحصرى من مونجلش، والذي يجمع بين التعلم والمرح. ساعدنا آلاف الطلاب على إتقان تعلم اللغة الانجليزية، ونحن مستعدون لمساعدتك أنت وطفلك أيضًا.'
    }
  },
  {
    path: '/ar',
    redirect: { path: '/' },
  },
  {
    path: '/ar/our-team', name: 'OurTeamAr', component: OurTeamAr,
    meta: {
      title: 'خبراء أكاديمية مونجلش | كوادر إدارية تصنع النجاح في كل خطوة',
      description: 'تتميز أكاديمية مونجلش الدولية بفريق عالمي متخصص يضم أكثر من 300 شريك نجاح، يقدمون تجربة تعليمية فريدة في تعليم اللغة الإنجليزية بأعلى مستويات الجودة والاحترافية'
    }
  },
  {
    path: '/ar/join-us', name: 'JoinUsAr', component: JoinUsAr,
    meta: {
      title: 'انضم إلينا - أكاديمية مونجلش العالمية',
      description: 'انضم إلى مونجلش وابدأ رحلتك.'
    }
  },
  {
    path: '/ar/blog', name: 'BlogsAr', component: BlogListAr,
    meta: {
      title: 'المدونة - أكاديمية مونجلش العالمية',
      description: 'اقرأ آخر المقالات في مدونتنا.'
    }
  },
  {
    path: '/ar/contact', name: 'ContactUsAr', component: ContactUsAr,
    meta: {
      title: 'اتصل بنا - أكاديمية مونجلش العالمية',
      description: 'تواصل معنا لأي استفسارات.'
    }
  },
  {
    path: '/ar/register', name: 'RegisterAr', component: RegisterAr,
    meta: {
      title: 'تسجيل - أكاديمية مونجلش العالمية',
      description: 'سجل لتصبح جزءاً من شبكة مونجلش.'
    }
  },
  {
    path: '/ar/login', name: 'LoginAr', component: LoginAr,
    meta: {
      title: 'تسجيل الدخول - أكاديمية مونجلش العالمية',
      description: 'قم بتسجيل الدخول إلى حسابك في مونجلش.'
    }
  },
  {
    path: '/ar/vision-mission', name: 'VisionMissionAr', component: VisionMissionAr,
    meta: {
      title: 'رسالتنا فى أكاديمية مونجلش | طموحات كبيرة لمستقبل مشرق',
      description: 'رسالتنا و رؤيتنا في مونجلش، أن نصبح الأكاديمية الرقمية الأفضل التي تقدم تجربة لا مثيل لها في تعلم اللغات الأجنبية، من أجل إحداث تأثير إيجابي في حياة عملائنا.'
    }
  },
  {
    path: '/ar/study-abroad', name: 'StudyAbroadAr', component: StudyAbroadAr,
    meta: {
      title: 'الدراسة في الخارج | تجربة تعليمية استثنائية في انتظاركم!!',
      description: `
      نعمل على تطوير برنامج متميز للدراسة في الخارج بالشراكة مع أبرز الجامعات
      العالمية في العديد من الدول، لنقدم لكم فرصة تعليمية فريدة. مونجلش التغلب على حاجز اللغة بثقة
  `
    }
  },
  {
    path: '/ar/faq', name: 'FAQ_Ar', component: FAQ_Ar,
    meta: {
      title: 'الأسئلة الشائعة - أكاديمية مونجلش العالمية',
      description: 'اعثر على إجابات للأسئلة الشائعة.'
    }
  },
  {
    path: '/ar/accredations', name: 'AccredationsAr', component: AccredationsAr,
    meta: {
      title: 'دورات معتمدة لأطفالك لتعلم الانجليزية بأسلوب ممتع مع مونجلش!',
      description: 'شهادات ودورات معتمدة ! وفرلأطفالك أفضل تعليم إنجليزي أونلاين. مونجلش حائزة على اعتماد المنظمة الدولية لجودة التدريب (IOTQ). توجهنا تلبية وتجاوز توقعات العملاء'
    }
  },
  {
    path: '/ar/packages', name: 'PackagesAr', component: PackagesAr,
    meta: {
      title: 'الباقات - أكاديمية مونجلش العالمية',
      description: 'اكتشف باقاتنا وعروضنا.'
    }
  },
  {
    path: '/ar/package', name: 'PackagesViewAr', component: PackagesViewAr,
    meta: {
      title: 'تفاصيل الباقة - أكاديمية مونجلش العالمية',
      description: 'تعرف على تفاصيل الباقة المختارة.'
    }
  },
  {
    path: '/ar/packages-kids', name: 'PackagesKidsAr', component: PackagesKidsAr,
    meta: {
      title: 'باقات الأطفال - أكاديمية مونجلش العالمية',
      description: 'استعرض باقات الأطفال.'
    }
  },
  {
    path: '/ar/packages-adults', name: 'PackagesAdultsAr', component: PackagesAdultsAr,
    meta: {
      title: 'باقات البالغين - أكاديمية مونجلش العالمية',
      description: 'استعرض باقات البالغين.'
    }
  },
  {
    path: '/ar/ielts-courses', name: 'IletsCoursesAr', component: IletsCoursesAr,
    meta: {
      title: 'دورات الآيلتس - أكاديمية مونجلش العالمية',
      description: 'تعرف على دورات الآيلتس لدينا.'
    }
  },
  {
    path: '/ar/cart', name: 'CartAr', component: CartAr,
    meta: {
      title: 'عربة التسوق - أكاديمية مونجلش العالمية',
      description: 'اعرض العناصر في عربة التسوق الخاصة بك.'
    }
  },
  {
    path: '/ar/kids-courses', name: 'KidsCoursesAr', component: KidsCoursesAr,
    meta: {
      title: 'تعلم اللغة الانجليزية للاطفال بأسلوب ممتع مع مونجلش!',
      description: 'تعلم اللغة الانجليزية للاطفال أونلاين بدورات تفاعلية وخبراء أكفاء. نمِّ مهاراتهم بثقة واكتشف شغفهم باللغة. سجل الآن واستثمر في مستقبلهم!'
    }
  },
  {
    path: '/ar/adult-courses', name: 'AdultsCoursesAr', component: AdultsCoursesAr,
    meta: {
      title: 'دورة اللغة الانجليزية: تحكم بحياتك المهنية مع مونجلش',
      description: 'حقق أهدافك المهنية مع دورة اللغة الانجليزية أونلاين! مستويات مختلفة، برامج مخصصة، أساتذة خبراء. طوّر مهاراتك واكتسب ثقة التواصل الدولي. سجل الآن وابني مستقبلا مشرقا!'
    }
  },
  {
    path: '/ar/monglish-immerse', name: 'MonglishImmerseAr', component: MonglishImmerseAr,
    meta: {
      title: 'منهج المعايشة فى مونجلش: اجعل طفلك يتحدث الإنجليزي بإحترافية',
      description: 'هل تبحث عن طريقة فعّالة لتعليم طفلك الإنجليزي؟ انضم إلى Monglish وجرّب منهج المعايشة! المبتكر لتعليم الإنجليزي للأطفال. معنا اجعل طفلك يستمتع، يتعلم، ويتألق! '
    }
  },
  {
    path: '/ar/monglish-promise', name: 'MonglishPromiseAr', component: MonglishPromiseAr,
    meta: {
      title: 'دورات انجليزى عن بعد | مونجلش مرونة التعلم من اى مكان و اى وقت',
      description: 'نقدم محتوى تعليميًا عالي الجودة من قبل مُعلمون مؤهلون وذو خبرة.منصتنا التعليمية سهلة الاستخدام ومصممة لتوفير تجربة تعليمية سلسة. لا تنتظر أكثر! انضم إلى مونجلش اليوم وابدأ رحلتك في تعلم اللغة الإنجليزية!'
    }
  },
  {
    path: '/ar/monglish-reasons', name: 'MonglishReasonsAr', component: MonglishReasonsAr,
    meta: {
      title: 'التعليم الإلكتروني المتطور في مونجلش | جودة، تفاعل، وإتقان',
      description: '12 سبب لإختيارك مونجلش. تعلم الإنجليزية يفتح الأبواب لفرص وظيفية دولية. ساعد و طور طفلك ! لغد مشرق. انجزنا 1,941,506 حصة منتهية. مونجلش التميز في كل رقم.'
    }
  },
  {
    path: '/ar/certifications', name: 'CertificationsAr', component: CertificationsAr,
    meta: {
      title: 'مونجلش | معتمدون دوليا من قِبل المنظمة الدولية لجودة التدريب IOTQ USA',
      description: ' حازت أكاديمية مونجلش المملكة المتحدة على شهادة إدارة الجودة ISO 9001: 2015 والصادرة بمصادقة SAARA Management(التابعة لهيئة الاعتماد UAF USA).'
    }
  },
  {
    path: '/ar/our-founder', name: 'OurFounderAr', component: OurFounderAr,
    meta: {
      title: 'د. محمد شلبى | المؤسس والرئيس التنفيذى لأكاديمية مونجلش',
      description: 'د. محمد شلبى هو رائد منهج المعايشة. أسس أكاديمية مونجلش عام 2019 لتوظيف منهجيته الرائدة المسجلة في مكتبة الكونغرس الأمريكية لدراسة اللغة الإنجليزية بسهولة ويسر. '
    }
  },
  {
    path: '/ar/youtube-videos', name: 'YoutubeVideosAr', component: YoutubeVideosAr,
    meta: {
      title: 'معرض فيديوهات مونجلش | رحلة مميزة عبر الزمن',
      description: 'ستجدون كل شيء من تسجيلات الأحداث المهمة إلى مقاطع فيديو تعليمية تعزز مهاراتكم في اللغة الإنجليزية. نحن نوفر محتوى متنوع يلبي احتياجاتكم التعليمية ويقوي تجربتكم اللغوية، مما يمكنكم من تحقيق تقدم ملحوظ في إتقان الإنجليزية، كل ذلك بمتعة وتفاعل.'
    }
  },
  {
    path: '/ar/photo-gallery', name: 'PhotoGalleryAr', component: PhotoGalleryAr,
    meta: {
      title: 'معرض الصور - أكاديمية مونجلش العالمية',
      description: 'تصفح معرض الصور.'
    }
  },
  {
    path: '/ar/success', name: 'PaymentSuccessAr', component: PaymentSuccessAr,
    meta: {
      title: 'نجاح الدفع - أكاديمية مونجلش العالمية',
      description: 'تمت عملية الدفع بنجاح!'
    }
  },
  {
    path: '/ar/job-registration', name: 'JobRegistrationPageAr', component: JobRegistrationPageAr,
    meta: {
      title: 'تسجيل وظيفة - أكاديمية مونجلش العالمية',
      description: 'سجل في وظيفة لدى مونجلش.'
    }
  },
  {
    path: '/ar/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    children: [
      {
        path: 'all', name: 'All', component: AllTermsConditionsComponentAr,
        meta: {
          title: 'جميع الشروط - أكاديمية مونجلش العالمية',
          description: 'عرض جميع الشروط والأحكام.'
        }
      },
      {
        path: 'refund', name: 'Refund', component: RefundComponentAr,
        meta: {
          title: 'سياسة الاسترداد - أكاديمية مونجلش العالمية',
          description: 'اقرأ سياسة الاسترداد لدينا.'
        }
      },
      {
        path: 'security', name: 'Security', component: SecurityComponentAr,
        meta: {
          title: 'سياسة الأمان - أكاديمية مونجلش العالمية',
          description: 'تعرف على سياسة الأمان لدينا.'
        }
      },
      {
        path: 'privacy', name: 'PrivacyPolicy', component: PrivacyPolicyComponentAr,
        meta: {
          title: 'سياسة الخصوصية - أكاديمية مونجلش العالمية',
          description: 'اقرأ سياسة الخصوصية الخاصة بنا.'
        }
      },
      {
        path: 'terms', name: 'TermsConditions', component: TermsConditionComponentAr,
        meta: {
          title: 'شروط وأحكام - أكاديمية مونجلش العالمية',
          description: 'فهم الشروط والأحكام.'
        },
      },
      { path: '/ar/terms-and-conditions/', redirect: '/ar/terms-and-conditions/all' }
    ],
  },
  { path: '/ar/teachers/one', name: 'TeacherOneAr', component: TeacherOneAr },
  { path: '/ar/teachers/two', name: 'TeacherTwoAr', component: TeacherTwoAr },
  { path: '/ar/teachers/three', name: 'TeacherThreeAr', component: TeacherThreeAr },
  { path: '/ar/teachers/four', name: 'TeacherFourAr', component: TeacherFourAr },
  { path: '/ar/teachers/five', name: 'TeacherFiveAr', component: TeacherFiveAr },
  { path: '/ar/teachers/six', name: 'TeacherSixAr', component: TeacherSixAr },
  { path: '/ar/teachers/eight', name: 'TeacherEightAr', component: TeacherEightAr },
  { path: '/ar/teachers/nine', name: 'TeacherNineAr', component: TeacherNineAr },
  { path: '/ar/teachers/ten', name: 'TeacherTenAr', component: TeacherTenAr },
  { path: '/ar/teachers/eleven', name: 'TeacherElevenAr', component: TeacherElevenAr },
  { path: '/ar/teachers/twelve', name: 'TeacherTwelveAr', component: TeacherTwelveAr },
  { path: '/ar/teachers/thirteen', name: 'TeacherThirteenAr', component: TeacherThirteenAr },
  { path: '/ar/teachers/fourteen', name: 'TeacherFourteenAr', component: TeacherFourteenAr },
  { path: '/ar/teachers/fifteen', name: 'TeacherFifteenAr', component: TeacherFifteenAr },
  { path: '/ar/teachers/sixteen', name: 'TeacherSixteenAr', component: TeacherSixteenAr },
  { path: '/ar/teachers/seventeen', name: 'TeacherSeventeenAr', component: TeacherSeventeenAr },
  { path: '/ar/teachers/eighteen', name: 'TeacherEighteenAr', component: TeacherEighteenAr },
  { path: '/ar/teachers/nineteen', name: 'TeacherNineteenAr', component: TeacherNineteenAr },
  { path: '/ar/teachers/twenty', name: 'TeacherTwentyAr', component: TeacherTwentyAr },
  { path: '/ar/teachers/twenty-one', name: 'TeacherTwentyOneAr', component: TeacherTwentyOneAr },
  { path: '/ar/teachers/twenty-two', name: 'TeacherTwentyTwoAr', component: TeacherTwentyTwoAr },
  { path: '/ar/teachers/twenty-three', name: 'TeacherTwentyThreeAr', component: TeacherTwentyThreeAr },
  { path: '/ar/teachers/twenty-four', name: 'TeacherTwentyFourAr', component: TeacherTwentyFourAr },
  { path: '/ar/teachers/twenty-five', name: 'TeacherTwentyFiveAr', component: TeacherTwentyFiveAr },
  { path: '/ar/teachers/twenty-six', name: 'TeacherTwentySixAr', component: TeacherTwentySixAr },
  { path: '/ar/teachers/twenty-seven', name: 'TeacherTwentySevenAr', component: TeacherTwentySevenAr },
  { path: '/ar/teachers/twenty-eight', name: 'TeacherTwentyEightAr', component: TeacherTwentyEightAr },
  { path: '/ar/teachers/twenty-nine', name: 'TeacherTwentyNineAr', component: TeacherTwentyNineAr },
  { path: '/ar/teachers/thirty', name: 'TeacherThirtyAr', component: TeacherThirtyAr },
  { path: '/ar/blog/learning-english-in-early-ages', name: 'BlogOneAr', component: BlogOneAr },
  { path: '/ar/blog/dr-shalaby-on-bentelbalad-program', name: 'BlogTwoAr', component: BlogTwoAr },
  { path: '/ar/blog/edulexa-360-group', name: 'BlogThreeAr', component: BlogThreeAr },
  { path: '/ar/blog/investing-in-monglish-team', name: 'BlogFourAr', component: BlogFourAr },
  { path: '/ar/blog/monthly-celebration-of-the-ideal-employee', name: 'BlogFiveAr', component: BlogFiveAr },
  { path: '/ar/blog/new-achievement-in-dr-najjars-career', name: 'BlogSixAr', component: BlogSixAr },
  { path: '/ar/blog/innovative-education-methods', name: 'BlogSevenAr', component: BlogSevenAr },
  { path: '/ar/blog/leadership-workshops', name: 'BlogEightAr', component: BlogEightAr },
  { path: '/ar/blog/international-partnerships', name: 'BlogNineAr', component: BlogNineAr },
  { path: '/ar/blog/future-of-digital-education', name: 'BlogTenAr', component: BlogTenAr },
  { path: '/ar/blog/students-achievements', name: 'BlogElevenAr', component: BlogElevenAr },
  { path: '/ar/blog/technology-integration', name: 'BlogTwelveAr', component: BlogTwelveAr },
  { path: '/ar/blog/annual-education-conference', name: 'BlogThirteenAr', component: BlogThirteenAr },
  { path: '/ar/blog/teachers-training-program', name: 'BlogFourteenAr', component: BlogFourteenAr },
  { path: '/ar/blog/community-engagement', name: 'BlogFifteenAr', component: BlogFifteenAr },
  { path: '/ar/blog/parent-involvement-importance', name: 'BlogSixteenAr', component: BlogSixteenAr },
  { path: '/ar/blog/scholarship-programs', name: 'BlogSeventeenAr', component: BlogSeventeenAr },
  { path: '/ar/blog/educational-research-and-innovation', name: 'BlogEighteenAr', component: BlogEighteenAr },
  {
    path: '/ar/teachers',
    name: 'teachersAr',
    component: TeacherAr,
    meta: {
      title: 'مونغليش | المعلمون',
      description: 'تقدم أكاديمية مونغليش الدولية فريقًا من المعلمين المؤهلين للغاية الذين يقدمون الخبرة والالتزام في الفصول الدراسية، مما يعزز تجربة التعلم.'
    }
  }
];

const routes = [
  ...englishRoutes,
  ...arabicRoutes,
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // Set the page title
  document.title = to.meta.title || 'Monglish Global Network';

  // Set the meta description
  const metaDescription = to.meta.description || 'Default description for Monglish Global Network';
  let descriptionMetaTag = document.querySelector("meta[name='description']");

  if (!descriptionMetaTag) {
    descriptionMetaTag = document.createElement("meta");
    descriptionMetaTag.setAttribute("name", "description");
    document.head.appendChild(descriptionMetaTag);
  }

  descriptionMetaTag.setAttribute("content", metaDescription);

  // Set the canonical URL
  const canonicalUrl = window.location.origin + window.location.pathname;
  let link = document.querySelector("link[rel='canonical']");

  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }

  link.setAttribute("href", canonicalUrl);

  next();
});

export default router;