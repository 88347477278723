<template>
  <div class="accreditations_page">
    <div class="vision-mission-row-acc">
      <h3>Internationally Accredited</h3>
      <p>
        monglish_frontend is accredited by IOTQ - USA, covering the entire Monglish global network. The UK branch also
        holds ISO 9001:2015 certification from SAARA (accredited by UAF). At Monglish, we focus on meeting and exceeding
        customer expectations by achieving the highest standards of quality.
        <br>
        . Modifying and developing leadership and educational systems within the academy
        <br>
        . Enhancing learners' knowledge, skills, and psychological and social well-being
        <br>
        . Improving the efficiency and performance of teachers and customer service team members
      </p>
    </div>

    <img loading="lazy" src="https://monglish.b-cdn.net/accr1.png" alt="accreditations 1" class="img_full">
  </div>

  <AccSec2 />
  <AccSec3 />
  <AccSec4 />
</template>

<script>
import AccSec2 from '../../components/en/AccSec2.vue';
import AccSec3 from '../../components/en/AccSec3.vue';
import AccSec4 from '../../components/en/AccSec4.vue';

export default {
  name: 'AccPage',
  components: {
    AccSec2,
    AccSec3,
    AccSec4
  }
}
</script>

<style scoped>
.accreditations_page {
  background-image: url('https://monglish.b-cdn.net/home/accre_bg.jpg');
  background-size: cover;
  background-position: center;
  min-height: 110vh;
  margin-top: -85px;
  padding-top: 140px;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.vision-mission-row-acc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
  direction: ltr;
}

.vision-mission-row-acc h3 {
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  color: white;
  font-size: 2.8rem;
  font-weight: 500;
}

.vision-mission-row-acc p {
  width: 100%;
  margin: 0;
  font-size: 1.2rem;
  color: white;
  padding: 0 20px;
}

.img_full {
  margin-top: 20px;
  width: 100%;
  transform: scaleX(-1);
}

@media (max-width: 768px) {
  .accreditations_page {
    height: auto;
    min-height: 82vh;
  }

  .vision-mission-row-acc {
    text-align: center;
    padding: 0;
    margin-top: 0;
  }

  .vision-mission-row-acc h3 {
    width: 100%;
    font-size: 2rem;
  }

  .vision-mission-row-acc p {
    width: 100%;
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .vision-mission-row-acc {
    flex-direction: row;
    text-align: left;
  }

  .vision-mission-row-acc h3 {
    width: 30%;
    font-size: 2.5rem;
    padding-left: 50px;
    padding-bottom: 0;
  }

  .vision-mission-row-acc p {
    width: 70%;
    font-size: 1.4rem;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  .vision-mission-row-acc h3 {
    font-size: 3rem;
    padding-left: 70px;
  }

  .vision-mission-row-acc p {
    font-size: 1.6rem;
    padding-right: 70px;
  }
}
</style>