<template>
  <header class="header-joinus-en">
    <div class="container-joinus-en">
      <h2>Are You Interested <br>Join Our Team?</h2>
      <div class="search-bar-en">
        <div class="form-container-en">
          <input type="text" placeholder="Search...">
          <button class="search-button-en">
            <div class="icon-svg-container-en">
              <svg fill="#ffffff" height="25px" width="25px" version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 488.4 488.4"
                xml:space="preserve" stroke="#ffffff">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <path
                        d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6 s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2 S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7 S381.9,104.65,381.9,203.25z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ViewFour'
}
</script>

<style scoped>
.header-joinus-en {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #007bff;
  margin-top: -105px;
  height: auto;
  color: white;
  text-align: center;
  padding: 20px;
  direction: ltr;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.container-joinus-en {
  margin-top: 160px;
}

.container-joinus-en h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 110%;
}

.search-bar-en {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.search-bar-en input {
  padding: 10px;
  width: 750px;
  height: 40px;
  border: none;
  border-radius: 20px 0 0 20px;
}

.search-bar-en .form-container-en {
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.form-container-en .icon-svg-container-en {
  display: flex;
  justify-content: center;
}

.search-button-en {
  padding: 10px;
  border: none;
  background: linear-gradient(to right, #ff7f00, #e66900);
  color: white;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
  width: 70px;
  margin: 3px;
}

@media (min-width: 320px) and (max-width: 479px) {
  .header-joinus-en {
    height: 45vh;
  }

  .container-joinus-en h2 {
    font-size: 2rem;
  }

  .search-bar-en .form-container-en {
    width: 90%;
  }
}
</style>