<template>
  <div class="faq-section-n">
    <h3>كل ما تريد معرفته</h3>
    <h2>قسم الأسئلة الشائعة في متناول يديك!</h2>
    <div class="faq-items-n">
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
        <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
        <button @click="toggleAnswer(index + 3)" :class="{ active: activeIndex === index + 3 }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index + 3 }">
            <span v-if="activeIndex === index + 3">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index + 3" :class="['answer', { 'answer-active': activeIndex === index + 3 }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
    </div>
    <MainButton @click="navigateToFAQ" class="my-5">شاهد المزيد</MainButton>
  </div>
</template>

<script>
import MainButton from '../Elements/MainButton.vue';

export default {
  components: {
    MainButton
  },
  name: 'ViewThree',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'الخجل وعدم القدرة على التفاعل مع الآخرين',
          answer: 'تعزز الحصص ثقة الطالب بنفسه والقدرة على التفاعل مع الآخرين من جنسيات وأعمار مختلفة.'
        },
        {
          question: 'مناهج وكتب تعليمية مملة',
          answer: 'نقدم منهجًا مبتكرًا يُلهمهم ويمنحهم الفهم العميق دون الحاجة للكثير من الكتب.'
        },
        {
          question: 'لغتي الإنجليزية ضعيفة جدا, هل يمكنني استيعاب المعلم؟',
          answer: 'ستتمكن من فهم المعلم بوضوح وسيتم إلحاقك بالمستوى المناسب لك تماما.'
        },
      ],
      faqItems_2: [
        {
          question: 'إرهاق من كثرة الواجبات الدراسية',
          answer: 'نستخدم أساليب تعليم ذكية وتتنوع الواجبات التي يمكن للطالب أن يؤديها بنفسه دون مساعده الوالدين مثل (بنك أسئلة إلكترونية – وجبات صوتية مسموعة ومقروءة – تسجيلات صوتية أو فيديو وأساليب أخرى) يتم مراجعه الواجبات عبر تطبيق مونجلش الإلكتروني.'
        },
        {
          question: 'لا أحبذ الدراسة عن بعد',
          answer: 'التعليم عن بعد في مونجلش يجمع بين المرونة والراحة، مع استخدام التقنيات الحديثة لتعزيز التفاعل وتوفير تجربة تعليمية فعالة وشاملة، مما يوفر الوقت والتكاليف مع ضمان جودة التعلم.'
        },
        {
          question: 'أريد حصصاً فردية',
          answer: 'تقدم مونجلش حصصا فردية أسبوعية لتعلم مهارة القراءة مع معلم محترف. مع الأخذ في الاعتبار أن اللغة الإنجليزية لغة تواصل ويتم تعلمها من خلال مجموعات حيث تتميز بعدد محدود (من 6 إلى 8 أفراد).'
        }
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    navigateToFAQ() {
      this.$router.push('/ar/faq');
    }
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>