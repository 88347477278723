<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p class="strong-paragraph">أبطال وراء الكواليس في مونجلش!! بكم حققنا ونحقق المستحيل<br><br></p>

          <p>تسعد أكاديمية مونجلش بالإعلان عن الفعالية الشهرية لتكريم موظف الشهر، حيث نحتفل بجهود وتفاني أعضاء فريقنا
            الرائعين.<br><br></p>

          <p>نود أن نعبّر عن امتناننا العميق لكل فرد من أفراد فريقنا. أنتم القوة الدافعة التي تجعل مونجلش أكثر من مجرد
            أكاديمية تعليم، بل مجتمع ينبض بالحياة والإبداع.<br><br></p>

          <p>هذا الحدث الشهري هو فرصة لنا لنشكر أعضاء فريقنا على روحهم الرائعة وإخلاصهم الذي لا يعرف حدود. بدون جهودهم
            وتفانيهم، لم نكن لنحقق هذه النجاحات.<br><br></p>

          <p>أنتم من يبنون الأسس القوية لمستقبل مشرق ومزدهر، ونحن محظوظون بوجودكم في فريقنا. نعلم أن كل فرد يساهم بطرق
            فريدة ومميزة، وأن تفانيكم وجهودكم اليومية هي ما يحقق التميز الذي نفتخر به.<br><br></p>

          <p>نعدكم بمواصلة دعمكم وتوفير بيئة عمل ملهمة ومليئة بالفرص للنمو والتطور. معاً، سنواصل تحقيق المزيد من
            الإنجازات وكتابة المزيد من قصص النجاح.<br><br></p>

          <p>شكراً لكم مرة أخرى على كل ما تبذلونه من جهد، ونتطلع لمزيد من الإبداع والتميز معكم.<br><br></p>

          <iframe width="100%" height="450" src="https://www.youtube.com/embed/-tUJbsTsBow" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>

        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "الاحتفال الشهرى بالموظف المثالى",
      date: "05/28/2024",
      img: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      category: "فعاليات",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }
  
  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>