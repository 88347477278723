<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>التعلم بطريقة اللعب هو أسلوب تعليمي يعتمد على استخدام الألعاب والنشاطات الترفيهية لمساعدة الأطفال على
            اكتساب المعرفة والمفاهيم الجديدة. وقد أثبت هذا الأسلوب فعاليته في تحفيز الأطفال وزيادة اهتمامهم بالتعلم.</p>

          <p>هناك العديد من الفوائد للتعلم بطريقة اللعب، منها:</p>

          <ul style="text-align: right;">
            <li aria-level="1"><b>تحفيز الأطفال: </b>يساعد التعلم بطريقة اللعب على تحفيز الأطفال وزيادة اهتمامهم
              بالتعلم. فالألعاب والنشاطات الترفيهية تجذب انتباه الأطفال وتجعلهم يرغبون في المشاركة فيها.</li>
            <li aria-level="1"><b>زيادة التفاعل: </b>يساعد التعلم بطريقة اللعب على زيادة التفاعل بين الأطفال ومعلميهم.
              فالألعاب والنشاطات الترفيهية تسمح للأطفال بالمشاركة والتعاون مع بعضهم البعض ومع معلميهم.</li>
          </ul>

          <ul>
            <li style="text-align: right;" aria-level="1"><b>تعزيز المهارات الاجتماعية:</b> يساعد التعلم بطريقة اللعب
              على تعزيز المهارات الاجتماعية للأطفال. فالألعاب والنشاطات الترفيهية تتطلب من الأطفال العمل مع بعضهم البعض
              والتعاون لتحقيق أهداف مشتركة.</li>
            <li style="text-align: right;" aria-level="1"><b>مساعدة الأطفال على تطوير مهارات التفكير النقدي وحل
                المشكلات.</b></li>
            <li style="text-align: right;" aria-level="1"><b>تعزيز الإبداع والابتكار لدى الأطفال.</b></li>
          </ul>

          <p>هناك العديد من الطرق المختلفة التي يمكن من خلالها تطبيق التعلم بطريقة اللعب في العملية التعليمية. إليك بعض
            الأمثلة:</p>

          <ul>
            <li aria-level="1"><b>استخدام الألعاب التعليمية</b> تعد الألعاب التعليمية أداة فعالة لتحفيز الأطفال ومساعدة
              على تعلم المفاهيم والمهارات الجديدة. على سبيل المثال، يمكن استخدام لعبة تعليمية لمساعدة الأطفال على تعلم
              الحروف الأبجدية أو الأرقام.</li>
          </ul>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog15-1.webp" alt="Blog Image15">

          <ul>
            <li aria-level="1"><b>الأنشطة الحركية</b> يمكن أن تساعد الأنشطة الحركية، مثل اللعب في الهواء الطلق أو الرقص،
              على تطوير مهارات الأطفال الحركية والتفكير الإبداعي.</li>
          </ul>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog15-2.webp" alt="Blog Image15">

          <ul>
            <li aria-level="1"><b>اللعب التخيلي:</b> يمكن أن يساعد اللعب التخيلي الأطفال على تطوير مهارات الخيال
              والإبداع.</li>
          </ul>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog15-3.webp" alt="Blog Image15">

          <h2><b>كيف يمكن استخدام التعلم بطريقة اللعب لتحفيز الأطفال؟</b></h2>

          <p>يمكن استخدام التعلم بطريقة اللعب في العديد من المجالات التعليمية، مثل:</p>

          <ul>
            <li aria-level="1">تعلم الحروف والأرقام: يمكن استخدام الألعاب المختلفة، مثل ألعاب الذاكرة، وألعاب التركيب،
              لمساعدة الأطفال على تعلم الحروف والأرقام.</li>
            <li aria-level="1">تعلم القراءة والكتابة: يمكن استخدام الألعاب التي تتضمن قراءة القصص، وكتابة الكلمات،
              لمساعدة الأطفال على تعلم القراءة والكتابة.</li>
            <li aria-level="1">تعلم الرياضيات: يمكن استخدام الألعاب التي تتضمن حل المسائل الرياضية، وألعاب المطابقة،
              لمساعدة الأطفال على تعلم الرياضيات.</li>
            <li aria-level="1">تعلم العلوم: يمكن استخدام الألعاب التي تتضمن إجراء التجارب العلمية، وألعاب الألغاز،
              لمساعدة الأطفال على تعلم العلوم.</li>
            <li aria-level="1">تعلم اللغة الأجنبية: يمكن استخدام الألعاب التي تتضمن ممارسة اللغة الأجنبية، وألعاب
              المحادثة، لمساعدة الأطفال على تعلم اللغة الأجنبية.</li>
          </ul>

          <h2>أمثلة على ألعاب يمكن استخدامها لتحفيز الأطفال</h2>

          <p>فيما يلي بعض الأمثلة على الألعاب التي يمكن استخدامها لتحفيز الأطفال:</p>

          <ul>
            <li aria-level="1">لعبة المكعبات: تساعد هذه اللعبة على تطوير المهارات الحركية، والمهارات المعرفية، والمهارات
              الاجتماعية.</li>
            <li aria-level="1">لعبة البازل: تساعد هذه اللعبة على تطوير المهارات الحركية، والمهارات المعرفية، والمهارات
              الإدراكية.</li>
            <li aria-level="1">لعبة صيد الأسماك: تساعد هذه اللعبة على تطوير المهارات الحركية، والمهارات المعرفية،
              والمهارات الاجتماعية.</li>
            <li aria-level="1">لعبة الورق: تساعد هذه اللعبة على تطوير المهارات المعرفية، والمهارات الاجتماعية.</li>
            <li aria-level="1">لعبة الشطرنج: تساعد هذه اللعبة على تطوير المهارات المعرفية،</li>
          </ul>

          <p>يمكن للوالدين والمعلمين الاستفادة من التعلم بطريقة اللعب لمساعدة الأطفال على التعلم والنمو. من خلال جعل
            التعلم ممتعًا ومثيرًا للاهتمام، يمكننا تحفيز الأطفال وجعلهم أكثر انخراطًا في العملية التعليمية.</p>

          <h2>كيف نستخدم التعلم بطريقة اللعب في المنزل؟</h2>

          <p>يمكننا استخدام التعلم بطريقة اللعب في المنزل من خلال القيام بأنشطة ترفيهية بسيطة مع أطفالنا، مثل:</p>

          <ul>
            <li aria-level="1">قراءة القصص للأطفال: تساعد قراءة القصص للأطفال على تنمية خيالهم وتعزيز مهاراتهم اللغوية.
            </li>
            <li aria-level="1">ممارسة الألعاب العائلية: تساعد الألعاب العائلية على تعزيز التفاعل بين أفراد الأسرة وتنمية
              المهارات الاجتماعية للأطفال.</li>
            <li aria-level="1">الذهاب إلى الحديقة أو المتحف: تساعد زيارة الحدائق والمتاحف على تعليم الأطفال عن العالم
              المحيط بهم بطريقة ممتعة.</li>
          </ul>

          <p>فيما يلي بعض النصائح لتطبيق التعلم بطريقة اللعب في المنزل:</p>

          <ul>
            <li aria-level="1"><b>وفر للأطفال مساحة آمنة للعب.</b> يجب أن يكون لدى الأطفال مساحة كافية للتحرك واللعب دون
              خوف من الإصابة.</li>
            <li aria-level="1"><b>قدم للأطفال مجموعة متنوعة من الألعاب والمواد التعليمية.</b> سيساعد ذلك الأطفال على
              اكتشاف اهتماماتهم وتطوير مهاراتهم.</li>
            <li aria-level="1"><b>شارك الأطفال في اللعب.</b> يمكن أن يساعد المشاركة في اللعب الأطفال على تعلم مهارات
              جديدة والتعرف على العالم من حولهم.</li>
            <li aria-level="1"><b>اجعل الألعاب ممتعة ومثيرة للاهتمام للأطفال</b>: يجب أن تكون الألعاب التي تستخدمها
              لتحفيز الأطفال ممتعة ومثيرة للاهتمام بالنسبة لهم، حتى يتمكنوا من التعلم بشكل فعال.</li>
            <li aria-level="1"><b>اجعل الألعاب مناسبة لعمر الأطفال</b>: يجب أن تكون الألعاب التي تستخدمها لتحفيز الأطفال
              مناسبة لعمرهم، حتى يتمكنوا من فهمها واللعب بها بشكل صحيح.</li>
            <li aria-level="1"><b>اسمح للأطفال بالمشاركة في تصميم الألعاب</b>: يمكن أن يساعد السماح للأطفال بالمشاركة في
              تصميم الألعاب على زيادة اهتمامهم باللعب بها، وبالتالي زيادة فاعلية التعلم.</li>
          </ul>

          <p>من خلال اتباع هذه النصائح، يمكنك مساعدة أطفالك على التعلم بطريقة ممتعة وفعالة. التعلم بطريقة اللعب هو أسلوب
            تعليمي فعال يمكن استخدامه لتحفيز الأطفال على التعلم واكتساب المعرفة. يمكن استخدام هذا الأسلوب في العديد من
            المجالات التعليمية، ويجب مراعاة اختيار الألعاب المناسبة لعمر الأطفال واهتماماتهم.</p>

          <p><strong>هل استمتعتم بقراءة المقال؟ اذا وجدت المقال مفيد نرجوا منكم مشاركته مع الاهل والأصدقاء حتى تعم
              الفائدة…</strong></p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "التعلم بطريقة اللعب: استراتيجية فعالة لتحفيز الأطفال",
      date: "01/22/2024",
      img: 'https://monglish.b-cdn.net/blogs/ar-15.webp',
      category: "ثقافة اللغة الانجليزية, نصائح للآباء ",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>