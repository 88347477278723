<template>
  <div class="our-team-container">
    <div class="our-team">
      <div class="padding-full">
        <div class="ourfounder-img-mobile">
          <img
            loading="lazy"
            src="https://monglish.b-cdn.net/home/founder.png"
            alt="founder"
            class="founder-team"
          />
        </div>
        <div class="padding-h3-title">
          <h3 class="padding-text-full">خبراء أكاديمية</h3>
        </div>
        <div class="padding-h2-title">
          <h1>مونجلش الدولية</h1>
        </div>
        <div class="margin-text-p">
          <p class="margin-text-up">
            تتميز أكاديمية مونجلش الدولية بفريق عالمي متخصص يضم أكثر من 300 شريك
            نجاح، يقدمون تجربة تعليمية فريدة في تعليم اللغة الإنجليزية بأعلى
            مستويات الجودة والاحترافية، معتمدين على منهج مبتكر يحاكي تجربة العيش
            بين الناطقين الأصليين للغة. نحن ملتزمون بتقديم تجربة تعليمية متميزة
            تعزز من نجاح وتميز المتعلمين.
          </p>
        </div>
        <div class="joinus-button">
          <router-link to="/ar/join-us">
            <button>
              انضم لفريق مونجلش
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </router-link>
        </div>
        <div class="ourteam-counter">
          <OurTeamCounter />
        </div>
        <div class="ourfounder-img">
          <img
            loading="lazy"
            src="https://monglish.b-cdn.net/home/founder.png"
            alt="founder"
            class="founder-team"
          />
        </div>
      </div>
    </div>

    <div class="team-row">
      <h3>
        كوادر إدارية
        <br />
        <span>تصنع النجاح في كل خطوة</span>
      </h3>
      <p>
        العمود الفقري لنجاحنا؛ إذ يعملون بجد وتفانٍ في كل خطوة لضمان توفير تجربة
        تعليمية استثنائية وتحقيق الإنجازات في كل مرحلة من مراحل العملية
        التعليمية.
      </p>
    </div>

    <div class="team-managers">
      <div
        v-for="manager in teamManagers"
        :key="manager.id"
        class="manager-card"
      >
        <div class="manager-info">
          <h4>{{ manager.name }}</h4>
          <p>{{ manager.title }}</p>
        </div>
        <img loading="lazy" :src="manager.image" :alt="manager.name" />
      </div>
    </div>

    <EmployeeCards />

    <VideoView />

    <div class="last-section-team">
      <div class="ourteam-sec6">
        <img
          loading="lazy"
          src="https://monglish.b-cdn.net/home/ourteam-6.jpg"
          alt="ourteam-6"
          class="ourteam-6"
        />
      </div>
      <div class="ourteam-text">
        <p>تبحث عن فرصة وظيفية؟</p>
        <p>وترغب في الانضمام إلى أسرة مونجلش</p>
        <router-link to="/ar/join-us">
          <button>
            انضم لفريق مونجلش
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import OurTeamCounter from "../../components/ar/TeamCounter.vue";
import VideoView from "../../components/ar/VideoView.vue";
import EmployeeCards from "../../components/ar/EmployeeCards.vue";

export default {
  name: "OurTeam",
  components: {
    OurTeamCounter,
    VideoView,
    EmployeeCards,
  },
  data() {
    return {
      teamManagers: [
        {
          id: 1,
          name: "د. محمد النجار",
          title: "نائب رئيس مجلس الإدارة",
          image: "https://monglish.b-cdn.net/team/1.webp",
        },
        {
          id: 2,
          name: "د. حاتم حسنين",
          title: "المدير الأكاديمي",
          image: "https://monglish.b-cdn.net/team/2.webp",
        },
        {
          id: 3,
          name: "أ. هبة حسن",
          title: "مساعدة رئيس مجلس الإدارة للمبيعات والتسويق",
          image: "https://monglish.b-cdn.net/team/3.webp",
        },
        {
          id: 4,
          name: "م. محمد سالم",
          title: "المدير التنفيذي للشئون التكنولوجية",
          image: "https://monglish.b-cdn.net/team/4.webp",
        },
        {
          id: 5,
          name: "ا. صفي الرحمن أباظه",
          title: "نائب مدير التسويق",
          image: "https://monglish.b-cdn.net/team/5.webp",
        },
        {
          id: 6,
          name: "ا. مايسة الشيخ",
          title: "مدير قسم الموارد البشرية",
          image: "https://monglish.b-cdn.net/team/6.webp",
        },
        {
          id: 12,
          name: "د. نصر كركور",
          title: "مساعد رئيس مجلس الإدارة مدير فرع الإسكندرية",
          image: "https://monglish.b-cdn.net/team/10.webp",
        },
        {
          id: 11,
          name: "ا. سامية أنور",
          title: "مدير المراحل العليا",
          image: "https://monglish.b-cdn.net/team/9.webp",
        },
        {
          id: 10,
          name: "د. سها عصام",
          title: "مدير المراحل التأسيسية",
          image: "https://monglish.b-cdn.net/team/8.webp",
        },
        {
          id: 9,
          name: "ا. وليد رسلان",
          title: "مدير الحسابات",
          image: "https://monglish.b-cdn.net/team/7.webp",
        },
      ],
      images: Array.from(
        { length: 74 },
        (_, index) => `https://monglish.b-cdn.net/employee/ar/${index + 1}.webp`
      ),
      currentIndex: 0,
    };
  },
};
</script>

<style src="@/assets/styles/ar/OurTeam.css"></style>