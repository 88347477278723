<template>
  <div class="head-container">
    <h2 class="blog-headline">Your Gate to Monglish</h2>
    <div class="blog-grid">
      <div v-for="(post, index) in blogPosts" :key="post.id"
        :class="['blog-card', { 'first-card': index === 0, 'regular-card': index !== 0 }]"
        @click="navigateTo(post.link)">
        <img loading="lazy" :src="post.image" alt="Blog Image" class="blog-image" />
        <div class="blog-info">
          <div class="blog-lang">{{ post.language }}</div>
          <div class="blog-title">{{ post.title }}</div>
          <div class="blog-date">{{ post.Date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {
      blogPosts: [
        {
          id: 2,
          title: "Celebrating Children’s Language Progress.",
          image: 'https://monglish.b-cdn.net/blogs/blog-2.webp',
        link: '/en/blog/celebrating-childrens-language-progress',
        Date: 'March 3, 2024',
        language: 'Uncategorized',
        },
    {
      id: 4,
      title: 'Parents and Children Healthy Relationships',
      image: 'https://monglish.b-cdn.net/blogs/blog4.webp',
      link: '/en/blog/parents-and-children-healthy-relationships/',
      Date: 'March 3, 2024',
      language: 'Uncategorized',
    },
    {
      id: 5,
      title: 'Activities For Positive Behavior',
      image: 'https://monglish.b-cdn.net/blogs/blog5.webp',
      link: '/en/blog/activities-for-positive-behavior',
      Date: 'February 29, 2024',
      language: 'Uncategorized',
    },
    {
      id: 6,
      title: 'Benefits Of Bilingual For Children',
      image: 'https://monglish.b-cdn.net/blogs/blog6.webp',
      link: '/en/blog/benefits-of-bilingual-for-children',
      Date: 'February 29, 2024',
      language: 'Uncategorized',
    },
    {
      id: 3,
      title: 'Seasons of The Year',
      image: 'https://monglish.b-cdn.net/blogs/blog3.webp',
      link: '/en/blog/seasons-of-the-year',
      Date: 'February 28, 2024',
      language: 'Uncategorized',
    },
      ],
  };
},
methods: {
  navigateTo(link) {
    this.$router.push(link);
  }
}
};
</script>

<style scoped>
.head-container {
  background-image: url('https://monglish.b-cdn.net/blogs/bg.webp');
  background-size: cover;
  background-position: center;
  height: 60vh;
  margin-top: -85px;
  padding-top: 105px;
  text-align: center;
  direction: ltr;
}

.blog-headline {
  color: white;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 42px;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3px;
  margin: 20px auto;
  padding: 10px;
  max-width: 1200px;
}

.blog-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  height: 260px;
}

.blog-card.first-card {
  grid-column: span 2;
  height: 260px;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: white;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #165E84 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
}

.blog-lang {
  font-size: 14px;
}

.blog-title {
  font-size: 19px;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.blog-date {
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .head-container {
    height: auto;
    padding-top: 20px;
    margin-top: 0;
  }

  .blog-headline {
    font-size: 28px;
    padding: 10px;
  }

  .blog-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 5px;
  }

  .blog-card {
    height: 200px;
  }

  .blog-card.first-card {
    grid-column: span 1;
    height: 200px;
  }

  .blog-info {
    padding: 5px;
  }

  .blog-title {
    font-size: 16px;
  }

  .blog-date,
  .blog-lang {
    font-size: 12px;
  }
}
</style>