<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>أصبح التعلم عن بُعد و الدراسة الاونلاين خيارًا شائعًا وفعّالًا للكثير خاصة في ظل التطور التكنولوجي السريع
            مما يتيح للطلاب حول العالم فرصة الوصول إلى المحتوى التعليمي من خلال منصات ودورات للتعلم بغض النظرعن موقعهم
            الجغرافي، مما يسهم في توسيع نطاق التعلم. تقدم هذه الطريقة الجديدة للتعلم فرصًا هائلة وتحديات فريدة.&nbsp;
          </p>

          <p>دعونا نستكشف فوائد التعلم عن بعد بصفه عامه والمنهج التفاعلى لمونجلش بصفة خاصة وكيف يمكن تحسين تجربة الطلاب
            في هذا السياق.</p>

          <h2><b>تأثير التعلم عن بعد على التوجه للدراسة الأكاديمية:</b></h2>

          <p>مع التقدم التكنولوجي، يشهد العالم تحولًا من الفصول الدراسية التقليدية إلى المنصات الإلكترونية. هذا التغيير
            يعكس تبني التعليم الأونلاين واعترافه بمؤهلات الطلاب الذين اختاروا هذا النهج.</p>

          <h2><b>مخاوف أولياء الأمور</b></h2>

          <p>تواجه أولياء الأمور العديد من المخاوف بشأن التعليم الأونلاين، خاصة عندما يتعلق الأمر بالاطفال صغار السن</p>

          <p><b>&nbsp;إليك بعض المخاوف الشائعة:</b></p>

          <p><b>التركيز والانتباه</b>: يثير التساؤل حول قدرة الأطفال الصغار على الانتباه والتركيز عبر الشاشة مما قد
            ينعكس بشكل سلبي على تجربتهم التعليمية ونموهم العقلي.</p>

          <p><b>الأمان الرقمي:</b> قلق الأهل حيال سلامة أطفالهم على الإنترنت، حيث يخشون من تعرضهم لمحتوى غير مناسب أو
            التفاعل مع أشخاص غير معروفين.</p>

          <p><b>التفاعل الاجتماعي:</b> يشعر بعض الأهل بقلق بسبب انخراط أطفالهم الضعيف في التفاعل الاجتماعي، حيث يتم
            التعلم عبر الإنترنت دون الفرصة للتواصل مع أقرانهم بشكل مباشر.</p>

          <p><b>الإشراف الفردي:</b> يثير التحدي الذي يواجهه أولياء الأمور في مراقبة ومساعدة أطفالهم أثناء التعلم عن بُعد
            مخاوف حول فعالية هذا الإشراف والدعم.</p>

          <p><b>الكم الزائد من الشاشات:</b> قلق بشأن مقدار الوقت الذي يقضيه الأطفال أمام الشاشات وتأثير ذلك على صحتهم
            البصرية والجسدية.</p>

          <p><b>التفاوت في جودة التعليم:</b> يشعر بعض الأهل بأن جودة التعلم عن بعد قد تكون متفاوتة، مما قد يعوق تحقيق
            التقدم الذي يمكن الأطفال تحقيقه في بيئة تعليمية تقليدية.</p>

          <p>على عكس مخاوف الأهل من التعلم عن بعد فهناك عدة فوائد ومميزات تعود بالإيجاب على الدارسين والأباء معا لنستعرض
            بعضا منها:</p>

          <h2><strong><b>فوائد التعلم عن بعد:</b></strong></h2>

          <h3 class="has-text-align-right">توفير الوقت والمجهود</h3>

          <p>تعد مرونة الجداول الزمنية أحد أبرز فوائد التعلم عن بُعد، حيث يمكن للطلاب تنظيم وقتهم وفقًا لالتزاماتهم
            الشخصية والاكاديمية. مما يوفر لهم المزيد من الوقت والمجهود خاصة بعد يوم دراسي طويل.</p>

          <h3>مرونة الحضور من اى مكان وعدم التقييد بمكان محدد</h3>

          <p>تتيح لهم الفرصة للوصول إلى مجموعة واسعة من الدورات والتخصصات التي قد لا تكون متاحة في المؤسسات التقليدية.
          </p>

          <h3>الرقابة الكاملة لولى الأمر</h3>

          <p>يعززهذا النهج التواصل والتعاون بين المؤسسة التعليمية والأهل، ويساهم في خلق بيئة تعليمية آمنة وفعّالة للطلاب
            عند استخدامهم لمنصات التعليم الأونلاين. هذا بالاضافة الى تمكن الأهل من فحص وفهم المحتوى المقدم للطالب.</p>

          <p>إمكانية التواصل بين ولي الأمر والمعلمين لمتابعة تقدم الطلاب وحل المشكلات المحتملة.</p>

          <p>نتيجة للاسباب السابق ذكرها قامت مونجلش بتطوير استراتيجيات فعّالة لمواجهة هذه المخاوف وضمان جودة التعليم
            الأونلاين وتوفير بيئة آمنة وداعمة لتعلم الأطفال عن بعد.</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/ar-10.webp" alt="Image11">

          <h2><b>١٢سبب لاختيارك مونجلش لأطفالك للتعلم عن بعد</b></h2>

          <p><b>التطور الدراسي السريع: </b>تتبع مونجلش طرق غير تقليدية للتدريس بحيث تناسب جميع المناهج فى الوطن العربى
            عموما والخليج والمجتمع السعودى خصيصا وذلك من خلال ممارسات واندية تفاعلية تعكس اسلوب المعايشة الحصرى الخاص
            بنا.</p>

          <p>يهدف هذا المنهج الى توسيع مدارك الطفل الدارس ليس فقط فى تعلم اللغة الانجليزية ولكن ايضا فى العديد من المواد
            الدراسية الأخرى كالرياضيات، الدراسات الأجتماعية واى مادة آخرى تدرس باللغة الانجليزية</p>

          <p><b>التطور الشخصي والسلوكى للدارس: </b>نسعى جاهدين فى منهج المعايشة واكاديمية مونجلش لتطوير الطفل سلوكيا
            وتربويا مما يمكنه من التواصل مع الأخرين بطريقة فعالة هذا بالاضافة لتطوير جوانب التخطيط وتحديد الأهداف لديه
            والعديد من الخبرات الحياتية الآخرى التى سوف تساعده فيما بعد<span style="color: #165e84;"> <a
                style="color: #165e84;"
                href="https://www.monglish.co.uk/2024/01/18/%d8%a7%d9%87%d9%85%d9%8a%d8%a9-%d8%a7%d9%84%d9%84%d8%ba%d8%a9-%d8%a7%d9%84%d8%a7%d9%86%d8%ac%d9%84%d9%8a%d8%b2%d9%8a%d8%a9-%d9%84%d9%85%d8%b3%d8%aa%d9%82%d8%a8%d9%84-%d8%b7%d9%81%d9%84%d9%83/">للنجاح
                والتميز فى حياته العملية والعلمية فى المستقبل</a></span>.</p>

          <p><b>الاستراتيجية التعليمية فى مونجلش: </b>النظام التعليمي فى مونجلش متناسب تماما مع المناهج المدرسية. توجهنا
            فى اكاديمية مونجلش موحد ومناسب لجميع المراحل التعليمية .. مناهج عالمية – مواعيد مرنة – جدول متناسب مع اليوم
            الدراسي.</p>

          <p><b>٧ كورسات فى كورس واحد: </b>مونجلش هى افضل مكان يساعد ابنك على تطوير مهاراته التعليمية. التنوع فى الانشطة
            الدراسية يعزز من فاعلية دورتنا حيث نعتمد على منهجيات مدروسة تضمن لطلابنا الاستفادة القصوى وتحقيق التقدم
            السريع فى مهاراتهم اللغوية . كورس <a href="https://www.monglish.co.uk/cart/"><span
                style="color: #165e84;">&nbsp;٦أشهر من مونجلش</span> </a>&nbsp;يعادل الدراسة ٣ سنوات فى المدارس
            الانترناشونال المتطورة</p>

          <p><b>المرح والحماس خلال العملية التعليمية:</b> هدفنا هو خلق جو عام للتعلم يتسم بالاثارة والتشويق فنحن نؤمن
            بأن بيئة التعلم الممتعه والمحفزه تزيد من قدرة الطالب على التركيز ،الاستيعاب والرغبة المستمرة فى التعلم بفضول
            وشغف.&nbsp;</p>

          <p><b>خبراء فى التدريس: </b>اكثر من ۲۰۰ متخصص يجمع بين الجودة العالية والتميز فى مجاله بالاضافة لسنوات الخبرة
            العديدة فى تدريس اللغه الاجنبية هذا بالاضافة لحصولهم على شهادات دولية معتمده فى التدريس</p>

          <p><b>تعليم ذكى بتقنيات متطورة: </b>التزامنا بالتطور التكنولوجى و مواكبة العصر دفعنا لاستخدام ادوات الذكاء
            الاصطناعى فى تقييم الامتحانات والاختبارات مما ينعكس على جودة ودقة نتائج طلبنا الصغار.</p>

          <p><b>۲۲ دولة حول العالم: </b>هذا الانتشار الواسع منح الاكاديمية بعدا عالميا فريدا وأكسبها خصائص فريدة أبرزها
            الانفتاح على ثقافات وبيئات متنوعة. ايمانا منا بضرورة تقديم برامج تعليمية متنوعة تناسب وتلبي احتياجات طلابنا
            فى كل مكان.</p>

          <p><b>منهج المعايشة الحصرى: </b>تم تصميم هذا المنهج لتعليم الصغار اللغة الانجليزية اونلاين بأسهل، أسرع وأمتع
            طريقة تناسب الأطفال. قام بتطويره مجموعة من خبراء تعليم الانجليزية ليشمل دراسة يومية عن طريق<span
              style="color: #165e84;"> <a style="color: #165e84;"
                href="https://www.monglish.co.uk/monglish-immerse/">أندية المعايشة</a></span> المتنوعة بالاضافة لحصتين
            اسبوعيا لضمان المتابعة وتحديد مستوى الطالب وتوجيه لاساسيات تعلم اللغة الانجليزية.</p>

          <p>في مونجلش، نفخر بتقديم مجموعة واسعة ومتنوعة من النوادي المتخصصة في تعلم اللغة الإنجليزية: نادي القراءة،
            الكتابة، والمحادثة بالاضافة إلى نادي السينما، المناظرات، والمواهب. نقدم أيضًا نوادي متخصصة مثل نادي
            الصوتيات، القواعد، نادي الرسم، وحتى نادي الأم الاجتماعي وغيرها. كل نادٍ يُعزز التجربة التعليمية الشاملة
            والتى تتضمن الوصول إلى أعلى النتائج وتجعلنا الأكاديمية الرائدة لتعلم الإنجليزية.</p>

          <p><b>معتمدين دوليا: </b>منهج المعايشة الخاص بنا معتمد ومسجل دوليا فى مكتبة الكونجرس الأمريكية تحت رقم (#TXU
            002361898) وهذا المنهج محمى بموجب قوانين حقوق النشر فى الولايات المتحدة الأمريكية وجميع أنحاء العالم</p>

          <p><b>سرعة إتقان اللغة: </b>القدرة على التحدث بطلاقة مذهلة خلال فتره قصيره تتراوح بين ٣ الى ٦ شهور بفضل منهجنا
            الفريد فى التعليم القائم على المعايشة اليومية</p>

          <p><b>الأهتمام بولى الامر: </b>المتابعة المستمرة مع ولى الامر لضمان تسليم الفروض المنزلية وحضور الحصص
            الاسبوعية&nbsp; والمشاركة فى الاندية التفاعلية. مع الحرص على التواصل الدائم مع ولى الأمر لحل اى مشكله طارئه
            مع الأخذ بعين الاعتبار الملاحظات والمقتراحات المقدمه لتحسين جودة خدماتنا رمنظورنا لتطوير العملية التعليمية
          </p>

          <p>توفر مونجلش مجموعة من النوادى التى تهم الأسرة كنادى الأمهات، اللايف كوتش والنادى الاجتماعى، حيث مساحة
            للتواصل ومشاركة التحديات في بيئة داعمة وترفيهية.</p>

          <p>&nbsp;هذه النوادي الحصرية تُمكّننا من تقديم تجربة تعلم استثنائية، ما يجعل مونجلش الوجهة المثالية لتعلم
            اللغة الإنجليزية عبر الإنترنت.</p>

          <h2><b>التحديات التي قد تواجه الطلاب:</b></h2>

          <h3><b>1. التحديات التقنية:</b></h3>

          <p>تشمل صعوبات في التكنولوجيا والاتصالات، والتي يجب على الطلاب التغلب عليها لضمان استمرارية تجربتهم التعليمية.
          </p>

          <h3><b>2. نقص التفاعل الشخصي:</b></h3>

          <p>تفتقد التعلم عن بُعد للتفاعل الشخصي والوجهات الفعّالة، مما قد يؤثر على تجربة الطلاب.</p>

          <h3><b>3. صعوبات إدارة الوقت:</b></h3>

          <p>يحتاج الطلاب إلى تطوير مهارات إدارة الوقت لضمان تحقيق الفاعلية القصوى في التعلم.</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog14-2.webp" alt="Blog Image14">

          <h2><b>نصائح لتحسين تجربة التعلم عن بعد:</b></h2>

          <h3>1. إنشاء مكان دراسي مخصص:</h3>

          <p>تحقيق تركيز أفضل عن طريق إنشاء بيئة دراسية هادئة ومخصصة للتعلم.</p>

          <h3>2. وضع جدول زمني:</h3>

          <p>تنظيم الوقت بشكل فعّال يساعد الطلاب على إدارة وقتهم بشكل أفضل وتحقيق أهدافهم.</p>

          <h3>3. الاستفادة من الموارد المتاحة:</h3>

          <p>استخدام الموارد الإلكترونية والتكنولوجيا المتاحة لتعزيز عملية التعلم.</p>

          <h2><b>تأثير اللغة على عملية التعلم عن بعد:</b></h2>

          <h3>1. التغلب على حواجز اللغة:</h3>

          <p>استخدام خدمات الدعم اللغوي ونظام المكافأت لتجاوز عقبات تعلم اللغة.</p>

          <h3>2. تعزيز مهارات اللغة:</h3>

          <p>تطوير مهارات اللغة من خلال التفاعل اللغوي في بيئة التعلم الإلكترونية.</p>

          <h2><b>التوجهات المستقبلية للتعلم عن بعد:</b></h2>

          <h3>1. دمج التقنيات الناشئة:</h3>

          <p>اعتماد التقنيات المستقبلية لتحسين جودة وكفاءة التعلم عن بُعد.</p>

          <h3>2. التحسين المستمر في التعليم الأونلاين:</h3>

          <p>تطوير الأساليب التعليمية الإلكترونية باستمرار لتحقيق تحسين مستمر.</p>

          <p>مستقبل ابنك بين ايديك، لاتتردد فى الاستثمار فيه اليوم لجنى الثمارغدا. مونجلش اختيارك الصائب.. لمعرفة المزيد
            عن باقاتنا الشهرية تواصل معنا : <a
              href="https://www.monglish.co.uk/contact/">https://monglish.co.uk/ar/contact/&nbsp;</a></p>

          <p><strong>هل استمتعتم بقراءة المقال؟ اذا وجدت المقال مفيد نرجوا منكم مشاركته مع الاهل والأصدقاء حتى تعم
              الفائدة…</strong></p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "التعلم عن بعد – نظرة عامة على المنهج التفاعلي في مونجلش",
      date: "01/21/2024",
      img: 'https://monglish.b-cdn.net/blogs/ar-11.webp',
      category: "ثقافة اللغة الانجليزية",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }
  
  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>