<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>تعتبر اللغة الإنجليزية من اللغات الهامة التي يجب على الأطفال تعلمها في وقت مبكر. يعتبر تحقيق التوازن في
            تعلم اللغتين، العربية والإنجليزية، أمرًا حيويًا لتطوير مهاراتهم اللغوية وتعزيز قدراتهم الفهمية. في هذا
            المقال، سنتناول أهمية تعلم اللغة الإنجليزية للأطفال وكيفية تحقيق ذلك بطرق فعّالة ومحفزة</p>

          <p>تعتبر فترة الطفولة مهمة جدًا لاكتساب المهارات واللغات. يستعرض هذا المقال أهمية تعلم الاطفال اللغة
            الإنجليزية في سن مبكرة وكيف يمكن أن يؤثر ذلك إيجابًا على مستقبل الأطفال. سنلقي نظرة على الفوائد العديدة
            وكيفية تحفيز الأطفال على هذا الاكتساب اللغوي الحيوي.</p>

          <h2><strong>اهمية اللغة الانجليزية</strong>&nbsp;فى الحاضر</h2>

          <p>اللغة الانجليزية تحمل أهمية كبيرة في عدة جوانب من الحياة. إليك بعض النقاط التي تبرز أهمية هذه اللغة</p>

          <h3><strong>لغة التواصل العالمية</strong></h3>

          <p>الإنجليزية هي لغة التواصل العالمية، وتُستخدم على نطاق واسع في مجالات الأعمال والدبلوماسية والتكنولوجيا</p>

          <h3>الوصول إلى المعرفة</h3>

          <p>الكثير من المعلومات والموارد العلمية والأدبية متاحة باللغة الإنجليزية، مما يجعلها لغة أساسية للبحث والدراسة
          </p>

          <h3 class="wp-block-heading has-text-align-right"><strong>إتاحة فرص العمل والتوظيف</strong></h3>

          <p>معظم الشركات الكبيرة والمؤسسات تتوقع من موظفيها القدرة على التحدث باللغة الإنجليزية، مما يجعلها مهارة حيوية
            في سوق العمل</p>

          <h3><strong>التواصل الثقافي</strong></h3>

          <p>تعزز الإنجليزية التفاهم الثقافي والتواصل بين الأفراد من مختلف الثقافات والخلفيات</p>

          <h3><strong>التقنية والابتكار</strong></h3>
          <p>معظم التطورات في مجال التكنولوجيا والعلوم تُنشر باللغة الإنجليزية، مما يجعلها لغة رئيسية للعلم والابتكار
          </p>

          <h3><strong>التعليم العالي</strong></h3>
          <p>الكثير من الجامعات الرائدة في العالم تقدم برامج دراسية باللغة الإنجليزية، مما يجعلها ضرورية للطلاب الراغبين
            في متابعة تعليمهم العالي</p>

          <h3><strong>الفرص الاقتصادية</strong></h3>
          <p>إلمام الفرد باللغة الإنجليزية يفتح أفقًا أوسع للمشاركة في الاقتصاد العالمي وتحقيق فرص اقتصادية أفضل</p>

          <h3><strong>الترفيه والثقافة</strong></h3>
          <p>الكثير من الأفلام والمسلسلات والأغاني العالمية تكون باللغة الإنجليزية، مما يجعلها لغة ترفيهية هامة</p>

          <p><strong>هذا الى جانب أهميتها ودورها الفعال خصوصا عند تعلم الاطفال فى سن مبكره حيث انها تساعد على</strong>
          </p>

          <h3><strong>تحسين الاتصال</strong></h3>
          <p>تساعد اللغة الإنجليزية في تعزيز مهارات التواصل، سواء كان ذلك في التحدث أو الاستماع. يساعد ذلك الأطفال في
            التعبير عن أفكارهم بوضوح وبثقة</p>

          <h3><strong>زيادة الفهم الثقافي</strong></h3>
          <p>يمكن لتعلم اللغة الإنجليزية أن يفتح أفق الأطفال لفهم ثقافات مختلفة. يصبحون قادرين على التفاعل بشكل أفضل مع
            العالم المحيط بهم</p>

          <h3><strong>تعزيز التفكير الابتكاري</strong></h3>
          <p>تشجع اللغة الإنجليزية على التفكير الابتكاري وحل المشكلات. يمكن للأطفال تطوير مهاراتهم الإبداعية من خلال
            استخدام اللغة في العديد من السياقات</p>

          <h2><strong>٥ فوائد ستعود على طفلك فى المستقبل من تعلم الانجليزية</strong></h2>

          <p>إن تعلم اللغة الإنجليزية ليس ضروريًا فقط ولكنه مثير أيضًا. تُشار إلى الإنجليزية باسم “اللغة العالمية” لأنها
            اللغة الرسمية في أكثر من 67 دولة. سترون كم هو مهم تعلم الانجليزية في أي موقف، سواء كان لدراسة في الخارج، أو
            إجراء مقابلات، أو حضور محاضرات، أو التسلية مع الأصدقاء، أو حتى الحصول على وظيفة بعد التخرج&nbsp;</p>

          <p><strong>التفاعل العالمي</strong>: الإنجليزية هي لغة عالمية، تسمح للأفراد بالتفاعل مع الناس من جميع أنحاء
            العالم</p>

          <p><strong>فرص متنوعة في مجال الوظائف</strong>: الأشخاص ذوي مهارات التواصل القوية بالإنجليزية لديهم وصول إلى
            فرص وظيفية عديدة</p>

          <p><strong>تحقيق التواصل المثالي</strong>: تعلم الإنجليزية يمكّن من التواصل الفعّال، مما يثير إعجاب الآخرين
            بمهاراتك اللغوية</p>

          <p><strong>رفع مستوى الثقة بالنفس:</strong>&nbsp;مهارات التواصل الجيدة تؤدي إلى زيادة الثقة والاطمئنان بالنفس
          </p>

          <p><strong>تسريع الأهداف المهنية:</strong>&nbsp;إتقان مهارات اللغة الإنجليزية يساعد في تحقيق الأهداف المهنية
            بشكل أسرع</p>

          <p><strong>تعزيز الشخصية:</strong>&nbsp;التواصل الفعّال والواثق يجعل الشخص أكثر جاذبية وجذبًا للآخرين</p>

          <p>الانجليزية التي يتحدث بها حوالي 360 مليون شخص في جميع أنحاء العالم وتُدرس في أكثر من 118 دولة، تحمل قيمة
            هائلة كلغة للتعلم. إتقان الإنجليزية يمكن ابنك فى المستقبل من التواصل الفعّال مع أشخاص من خلفيات متنوعة، مما
            يجعل السفر أسهل ويعزز التقدير العميق لثقافات مختلفة. وعلاوة على ذلك، تلعب دورًا حيويًا في عالم الأعمال، حيث
            تعتبر اللغة الأكثر استخدامًا وشرطًا أساسيًا للكثير من أصحاب العمل، مما يجعل&nbsp;<span
              style="color: #165e84;"><a style="color: #165e84;"
                href="https://monglish.co.uk/ar/%D8%A7%D9%84%D8%A7%D9%84%D8%AA%D8%AD%D8%A7%D9%82-%D8%AC%D8%AF%D9%8A%D8%AF/">دورات
                الإنجليزية</a></span>&nbsp;ذات جودة واستثمارًا قيمًا لتعزيز فرص التوظيف</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog13-1.webp" alt="Blog Image13">

          <h2><strong>اهمية اللغة الانجليزية في التعليم الجامعى عند السفر&nbsp;<span style="color: #165e84;"><a
                  style="color: #165e84;" href="https://www.monglish.co.uk/study-abroad/">للدراسة فى
                  الخارج</a></span></strong></h2>

          <p>يعتبر فهم اللغة الإنجليزية أمرًا أساسيا سواء كنت تقدم للحصول على تأشيرة أو تطمح للدراسة في الخارج. حيث
            ستكون مطالبًا بحضور مقابلات للحصول على التأشيرة و سيُطلب منك شرح سبب سفرك إلى الخارج للدراسة. إذا كنت تتواصل
            بشكل جيد، فإن فرص قبول طلب التأشيرة تكون أعلى بشكل كبير. وليس ذلك فقط، ولكن عند حجز سكن الطلاب، تحتوي غالبية
            المساكن المتاحة على معلومات مكتوبة باللغة الإنجليزية. بالمثل، عند حجز تذكرة طيران، والتنقل إلى الجامعة،
            والخروج لتناول الطعام، يتطلب الأمر التواصل باللغة الإنجليزية. معرفة اهمية اللغة الانجليزية ضرورية ليس فقط
            بعد الانضمام إلى الجامعة، ولكن أيضًا خلال التحضيرات السابقة للجامعة مثل إجراء الاختبارات، وإجراء المقابلات،
            والتقديم للمنح الدراسية، وما إلى ذلك</p>

          <h2><strong>اهمية اللغة الانجليزية للطلاب في الجامعات</strong></h2>

          <p>الإنجليزية هي وسيلة الاتصال في معظم المدارس والكليات والجامعات، خاصة إذا كنت تدرس في الخارج لفهم الكتب
            والمحاضرات والواجبات. يطمح معظم الطلاب إلى الدراسة في بلدان أخرى، ومن الصعب جدًا النجاح في أماكن أخرى بدون
            تعلم والتحدث باللغة. العديد من تخصصات العلوم، بالإضافة إلى التكنولوجيا الجديدة والاختراعات، يتم تدريسها
            أيضًا باللغة الإنجليزية. العلوم الطبية والهندسة والقانون هي ثلاث مجالات دراسية رئيسية حيث ستدرك اهمية اللغة
            الانجليزية في الجامعة</p>
          <p>الإنجليزية هي اللغة الأكثر أهمية في العالم، يتحدث بها أكثر من ثلث سكان العالم وهي لغة رسمية في العديد من
            البلدان. يسمح لك تعلم الانجليزية بالتواصل مع مزيد من الأشخاص وسهولة إقامة صداقات معهم. ستتعرف على ثقافات
            متنوعة في جميع أنحاء العالم من خلال التحدث مع الناطقين باللغة المحلية أو قراءة الكتب لأشهر الأدباء الغرب</p>
          <p>تعتبر الإنجليزية لغة عالمية في مجالات العلوم والتكنولوجيا والسياحة وغيرها، مما يسهل التعاون والتواصل السلس
            بين المحترفين. اعتماد&nbsp;<span style="color: #165e84;"><a style="color: #165e84;"
                href="https://monglish.co.uk/">دورات اللغة الإنجليزية</a></span>&nbsp;سيكون مفيدًا خاصة لأولئك الذين
            يطمحون للعمل في الشركات الدولية، حيث توفر مهارات الاتصال الأساسية وفرص التواصل للنمو الشخصي والمهني</p>

          <h2>الإنترنت والانجليزية</h2>

          <p>اللغة المفضلة على الإنترنت هي الإنجليزية. الأفراد الذين يستطيعون التحدث بالإنجليزية لديهم المزيد من الفرص
            لاستخدام الإنترنت. معظم محركات البحث، مثل جوجل و بينج و ياهو هي باللغة الإنجليزية. في الغالب، تكون معظم
            المعلومات على الإنترنت مكتوبة ايضا باللغة الإنجليزية. حتى عند الدراسة في الجامعة، ستتلقى مواد الدراسة في شكل
            ملفات أو وثائق ورد أو رسائل إلكترونية. وغالباً ما يتم نقل هذا المواد للطلاب باللغة الإنجليزية، اللغة المعترف
            بها على نطاق واسع من قبل الطلاب الدوليين والسكان المحليين على حد سواء. هنا تظهر أهمية الإنجليزية في الجامعة
            والتعليم العالي</p>

          <h2>لغة الاتصال الدولي</h2>

          <p>اللغة هي الوسيلة الرئيسية التي يتواصل بها الناس ويتفاعلون بها اجتماعياً. لكل بلد ومنطقة مجموعة خاصة من
            اللغات الوطنية واللغات الفرعية. بما أن هناك أكثر من 100 لغة في جميع أنحاء العالم، يكون تعلم وفهم كل واحدة
            منها تحديًا . الإنجليزية هي لغة مشتركة تمكن صاحبها من التواصل مع الأفراد من جميع أنحاء العالم. يمكن توضيح
            أهمية الإنجليزية في التفاعل الأكاديمي بعدة طرق. قد ترغب في التفاعل مع أقرانك، وأصدقائك، وزملائك في الجامعة،
            أو هيئة التدريس . يجب أن تفهم أن الإنجليزية هي اللغة العالمية التي ستكسر الحواجز وتساعد على التغلب على
            الفجوات بسهولة من خلال المحادثة. تُستخدم بعض مصطلحات العامية الإنجليزية التي يستخدمها طلاب الجامعة على نطاق
            واسع وستساعدك عند التفاعل الاجتماعي</p>

          <h2>تفتح الإنجليزية أبوابًا لفرص الحياة المهنية</h2>

          <p>الأفراد الذين يتحدثون ويفهمون الإنجليزية يمكنهم بسهولة العثور على وظائف في العديد من الشركات الكبيرة
            والعالمية. من التدريبات والعمل بدوام جزئي والتدريب إلى تأمين وظيفة بدوام كامل تعتمد تمامًا على مهاراتك في
            اللغة الإنجليزية، يمكنك رؤية اهمية اللغة الانجليزية في كل المجالات والتخصصات. إليك بعض الوظائف الأكثر شيوعًا
            التي يمكنك شغلها فقط من خلال فهم اللغة : التدريس، الكتابة، المبيعات، التسويق، تقديم البرامج التلفزيونية
            والإذاعية، التقارير وخدمة العملاء</p>

          <h2>التواصل مع العالم</h2>

          <p>تعتبر اللغة الإنجليزية جسرًا بين الأفراد من ثقافات وبلدان وقارات مختلفة. أكثر من 60 دولة اعتبرتها لغتها
            الرسمية. إنها لغة عالمية يستخدمها الناس للتواصل مع بعضهم من جميع أنحاء العالم. لا يمكن التنويه بمدى أهمية
            تعلم الانجليزية، حيث تتيح لك التواصل مع أشخاص يتحدثون لغات مختلفة عن لغتك الأم</p>

          <h2>توفير تعليم أفضل</h2>

          <p>الإنجليزية هي اللغة الأكثر استخدامًا كلغة تعليمية. تُستخدم في العديد من الدول حول العالم، حتى في تلك التي
            تمتلك لغاتها الرسمية الخاصة. الإنجليزية تُستخدم أيضًا في مجالات العلوم والتكنولوجيا والبحث والتطوير. علاوة
            على ذلك، تُعتبر الإنجليزية لغة بارزة تُستخدم للتواصل بين أولئك الذين لا يتحدثون نفس اللغة</p>

          <h2>تفتح الإنجليزية أبوابًا لمجالات مختلفة</h2>

          <p>الإنجليزية هي لغة عالمية يتحدث بها في جميع أنحاء العالم. لأن الإنجليزية هي اللغة الرسمية في العديد من
            البلدان والمنظمات الدولية، تتيح لك الوصول إلى مجموعة واسعة من الوظائف. تحتاج الشركات والقطاعات إلى موظفين
            يمكنهم التفاعل مع عملائها ومورديها وعملاءها باللغة الإنجليزية. الإنجليزية توفر أيضًا فرصًا للتعليم في
            الكليات والجامعات والمؤسسات الأخرى التي تدرس باللغة الإنجليزية في جميع أنحاء العالم</p>

          <h2>أداة قوية</h2>

          <p>الإنجليزية هي أداة قوية تعزز حياتنا. الإنجليزية هي اللغة الأكثر تحدثًا على وجه الأرض وتم استخدامها كلغة
            عالمية لآلاف السنين. كلما تعلمت المزيد من الإنجليزية، كلما زاد مستوى حياتك</p>

          <img loading="lazy" src="https://monglish.b-cdn.net/blogs/blog13-2.webp" alt="Blog Image13">

          <h2>كيف تعزز إلمام اللغة الإنجليزية لإتاحة الفرص المستقبلية</h2>

          <p>إلمام الطفل باللغة الإنجليزية يعزز فرصه المستقبلية بشكل كبير. إليك بعض الطرق التي يمكنك من خلالها تعزيز
            إلمام طفلك باللغة الإنجليزية</p>

          <h2>التعليم المبكر</h2>

          <p>
            تعريف الطفل باللغة الإنجليزية منذ صغره يساعد في تكوين أساس قوي للتعلم المستمر
            استخدام القصص الإنجليزية البسيطة والألعاب التعليمية لتعزيز فهمهم
          </p>

          <h2>التفاعل اليومي</h2>

          <p>
            تشجيع الطفل على استخدام اللغة الإنجليزية في المواقف اليومية، مثل اللعب مع الأصدقاء أو تواصل مع أفراد العائلة
            مشاهدة البرامج التعليمية بالإنجليزية والألعاب التفاعلية
          </p>

          <h2>التحفيز بالمكافآت</h2>

          <p>
            تحفيز الطفل بوسائل مثل مكافأت صغيرة عند استخدامهم اللغة الإنجليزية بشكل نشط
            إنشاء جدول زمني لتحديد أوقات محددة لاستخدام اللغة الإنجليزية
          </p>

          <h2>دعم التعلم الخارجي</h2>

          <p>
            التسجيل في دورات إنجليزية للأطفال أو الانضمام إلى أنشطة تعليمية باللغة الإنجليزية
            استخدام تطبيقات وموارد على الإنترنت المصممة لتحسين مهارات اللغة
          </p>

          <h2>التحفيز من خلال القراءة</h2>

          <p>
            تشجيع القراءة بانتظام باللغة الإنجليزية من خلال توفير كتب ملائمة للفئة العمرية
            مناقشة القصص وطرح أسئلة لتعزيز فهم اللغة وتوسيع المفردات
          </p>

          <h2>السفر والتواصل مع ثقافات مختلفة</h2>

          <p>
            إذا كان بإمكانكم، قدموا للطفل فرصة للسفر والتفاعل مع الناطقين بالإنجليزية
            التعرف على ثقافات مختلفة يعزز فهم اللغة ويحفز الاهتمام
          </p>

          <h2>صعوبات تعلم اللغه الانجليزية بالرغم من تعدد سنوات الدراسة </h2>

          <p>المعلمين فى المنظومة التعليمية فى وطننا العربى غير مؤهلين لتدريس اللغة الانجليزية حيث يتم الأعتماد على وهو
            اسلوب قديم فى التعلم The Grammar-Translation Method</p>

          <p>تعلم اللغه يتم من اجل الاختبار وذلك بدوره لا يساهم فى بناء وتطوراللغة لدى الدارس Exam-Oriented Education
          </p>

          <p>تكدس الفصول الدراسية مما لا يتيح للطالب فرصة للمارسة بشكل فعال</p>

          <p>غياب دعم الأسرة فى المشاركة التعليمية والأعتماد الكلى على المدارس فقط</p>

          <p>ومن هنا نشأت فكره التدريس بمنهج المعايشة الحصرى للدكتور محمد شلبي وهو رائد منهج المعايشة ومؤسس أكاديمية
            مونجلش الدولية في عام 2019 ، بينما كان أيضًا شريكًا لمدرسة ينابيع الإلكترونية ، التي تخدم المتعلمين في
            المناهج المصرية والدولية</p>

          <p>تأسست مونجلش على يد د. شلبي كفرصة تنير الطريق للمتعلمين ، لتجربة الثقافات الأخرى من خلال اللغة ، من خلال
            تمكينهم من دراسة اللغة الانجليزية بسهولة ومرونة. أنشأ الأكاديمية لتوظيف منهجيته الرائدة ، المسجلة باسمه في
            مكتبة الكونغرس في الولايات المتحدة</p>

          <p>أصبحت مونجلش من بين المدارس الرائدة في مجال تعليم اللغة الانجليزية كلغة ثانية على الإنترنت في الخليج
            والمجتمع السعودي بالأخص ، وذلك بفضل منهجها الحصري الذي يستخدمه خبراء في تدريس اللغة</p>

          <p>شاركونا آرائكم بخصوص التعلم عن بُعد “أونلاين” للأطفال الصغار. هل أنتم مع أم ضد هذه الفكرة؟ ترقبوا المقال
            القادم لإستكمال مناقشة هذا الموضوع الشيق والهام</p>

          <p>ندعوكم لاستكشاف منهج المعايشة الحصرى في “مونجلش“. احصلوا على تجربة تعلم فريدة وشيقة تساعدكم على تحسين
            مهارات أطفالكم باللغة الانجليزية. لا تترددوا في زيارة موقعنا لمزيد من المعلومات حول دوراتنا والفرص المتاحة.
          </p>

          <p># مستقبل أبناءك بإيدك</p>

          <p><strong>هل استمتعتم بقراءة المقال؟ اذا وجدتك المقال مفيد نرجوا منكم مشاركته مع الاهل والأصدقاء حتى تعم
              الفائدة…</strong></p>
        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "اهمية اللغة الانجليزية فى إتاحة الفرص المستقبلية لطفلك",
      date: "01/18/2024",
      img: 'https://monglish.b-cdn.net/blogs/ar-10.webp',
      category: "نصائح للآباء",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>