<template>
  <div class="study-sec1-en">
    <div class="study-sec1-content">
      <h3>Study Abroad</h3>
      <h1>An exceptional learning experience awaits you soon!</h1>
      <h4>In cooperation with major international universities</h4>
      <p>
        We are working to develop a distinguished program for studying abroad in partnership with the most prominent
        international universities in many countries, to offer you a unique educational opportunity.
      </p>
    </div>
  </div>

  <ViewThree />
</template>

<script>
import ViewThree from '../../components/en/ViewThree.vue';

export default {
  name: 'StudyAbroadPage',
  components: {
    ViewThree,
  },
}
</script>

<style scoped>
.study-sec1-en {
  background-image: url('https://monglish.b-cdn.net/home/study_bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  margin-top: -85px;
  padding: 40px;
  font-family: 'DIN Next LT Arabic';
  transform: scaleX(-1);
}

.study-sec1-content {
  transform: scaleX(-1);
  text-align: left;
  direction: ltr;
  max-width: 40%;
}

.study-sec1-en h3 {
  font-size: 2rem;
  padding: 8em 0 0 0;
}

.study-sec1-en h4 {
  font-size: 2.2rem;
}

.study-sec1-en h1 {
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 120%;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.study-sec1-en p {
  padding-right: 10%;
  font-size: 1.3rem;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .study-sec1-en h1 {
    font-size: 3.5rem;
  }

  .study-sec1-content {
    max-width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .study-sec1-en h1 {
    font-size: 3rem;
  }

  .study-sec1-en p {
    font-size: 1.2rem;
  }

  .study-sec1-content {
    max-width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .study-sec1-en h1 {
    font-size: 2.5rem;
  }

  .study-sec1-en h3,
  .study-sec1-en h4 {
    font-size: 1.8rem;
  }

  .study-sec1-content {
    max-width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .study-sec1-en h1 {
    font-size: 2rem;
  }

  .study-sec1-en h3,
  .study-sec1-en h4 {
    font-size: 1.5rem;
  }

  .study-sec1-content {
    max-width: 90%;
    padding: 20px;
  }

  .study-sec1-en p {
    font-size: 1rem;
    padding-right: 5%;
  }
}

@media screen and (max-width: 400px) {
  .study-sec1-en h1 {
    font-size: 1.8rem;
  }

  .study-sec1-en h3,
  .study-sec1-en h4 {
    font-size: 1.2rem;
  }

  .study-sec1-en p {
    font-size: 0.9rem;
  }
}
</style>
