<template>
  <div class="kids-carousel-container">
    <Carousel
      ref="carousel"
      v-model="currentSlide"
      :items-to-show="itemsToShow"
      :wrap-around="true"
      snap-align="center"
      class="carousel"
    >
      <Slide v-for="club in clubs" :key="club.id">
        <div class="kids-card">
          <img loading="lazy" :src="club.image" alt="" class="kids-card-image" />
        </div>
      </Slide>
    </Carousel>

    <div class="pagination">
      <button
        v-for="(_, index) in clubs"
        :key="index"
        @click="goToSlide(index)"
        :class="{ active: currentSlide === index }"
      ></button>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

export default {
  components: { Carousel, Slide },
  setup() {
    const carousel = ref(null);
    const currentSlide = ref(0);
    const itemsToShow = ref(3); 
    const clubs = ref([
      { id: 1, image: 'https://monglish.b-cdn.net/ielts/en/1.webp' },
      { id: 2, image: 'https://monglish.b-cdn.net/ielts/en/2.webp' },
      { id: 3, image: 'https://monglish.b-cdn.net/ielts/en/3.webp' },
      { id: 4, image: 'https://monglish.b-cdn.net/ielts/en/4.webp' },
      { id: 5, image: 'https://monglish.b-cdn.net/ielts/en/5.webp' },
      { id: 6, image: 'https://monglish.b-cdn.net/ielts/en/6.webp' },
      { id: 7, image: 'https://monglish.b-cdn.net/ielts/en/7.webp' },
      { id: 8, image: 'https://monglish.b-cdn.net/ielts/en/8.webp' },
    ]);

    let autoplayInterval = null;

    const updateItemsToShow = () => {
      itemsToShow.value = window.innerWidth < 768 ? 1 : window.innerWidth < 1024 ? 2 : 3;
    };

    const goToSlide = (index) => {
      currentSlide.value = index;
    };

    const autoplay = () => {
      currentSlide.value = (currentSlide.value + 1) % clubs.value.length;
    };

    watch(currentSlide, (newValue) => {
      console.log('Current slide changed to:', newValue);
    });

    onMounted(() => {
      updateItemsToShow();
      window.addEventListener('resize', updateItemsToShow);
      autoplayInterval = setInterval(autoplay, 4000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateItemsToShow);
      clearInterval(autoplayInterval);
    });

    return {
      carousel,
      currentSlide,
      itemsToShow,
      clubs,
      goToSlide,
    };
  },
};
</script>

<style scoped>
.kids-carousel-container {
  width: 100%;
  margin: 0 auto;
  direction: rtl;
  padding: 0 1rem;
}
.carousel {
  position: relative;
}

.kids-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  width: 100%;
  height: 400px; 
  color: #165e84;
  transition: transform 0.3s;
}
.kids-card-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 1em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}
.pagination button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  background-color: #e0e0e0;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.pagination button:hover {
  background-color: #d5d5d5;
}
.pagination button.active {
  background-color: #f77919;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .kids-card {
    height: 300px; 
    width: 100%;
  }
  .pagination button {
    margin: 0 0.25rem;
    padding: 0.4rem;
  }
  .pagination button.active {
    padding: 0.4rem 0.8rem;
  }
}

@media (min-width: 768px) {
  .kids-card {
    height: 300px; 
  }
}

@media (min-width: 1024px) {
  .kids-card {
    height: 350px;
  }
}

@media (min-width: 1440px) {
  .kids-card {
    height: 400px; 
  }
}
</style>
