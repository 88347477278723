<template>
  <BlogHead :title="title" :img="img" :date="date" />

  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>
            Language is a fundamental skill for learning, communication, and success in life. It is essential for
            interacting with others, expressing oneself, and acquiring knowledge. Therefore, it is important to
            celebrate children’s achievements in their language goals and progress.
          </p>

          <h3>Importance of celebrating language goals:</h3>
          <p class="strong-paragraph">Celebrating children’s language goals helps to:</p>
          <p>
            Boost the child’s self-confidence: When the child feels their achievements are appreciated, their
            self-confidence increases, making them more motivated to achieve more.
            Encourage the child to continue excelling: When the child feels their achievements are valued, they become
            more eager to continue excelling and achieving further success.
            Strengthen the relationship between the child and parents: When parents celebrate their child’s
            accomplishments, it strengthens their feelings of love and connection.
          </p>

          <h3>Ways to celebrate a child’s achievements:</h3>
          <p>There are many ways to celebrate children’s language goals. Here are some ideas:</p>

          <ul>
            <li>Expressing positive emotions:
              <ul>
                <li>Express your joy and happiness for your child’s achievements, whether big or small.</li>
                <li>Use positive phrases like “Congratulations!” and “I’m proud of you!” and “You’re amazing!”</li>
              </ul>
            </li>
            <li>Giving gifts:
              <ul>
                <li>Present a symbolic gift to the child as a token of appreciation and encouragement.</li>
                <li>Ensure the gift is suitable for the child’s age and interests.</li>
              </ul>
            </li>
            <li>Spending quality time together:
              <ul>
                <li>Set aside time to play with your child or engage in a fun activity they enjoy.</li>
                <li>Use this time to talk to your child about their achievements and how they accomplished them.</li>
              </ul>
            </li>
            <li>Expressing gratitude:
              <ul>
                <li>Thank your child for their efforts and perseverance.</li>
                <li>Express gratitude for having them in your life.</li>
              </ul>
            </li>
            <li>Sharing the child’s achievements with others:
              <ul>
                <li>Share your child’s accomplishments with family and friends.</li>
                <li>Post pictures or videos of your child’s achievements on social media.</li>
              </ul>
            </li>
          </ul>

          <img loading="lazy" src='https://monglish.b-cdn.net/blogs/blog2-1.webp' alt="Blog Image" />

          <h3>Examples of celebrating a child’s achievements:</h3>
          <p>Here are some examples which could be useful in encouraging your kids:</p>
          <ul>
            <li>If your child learns a new word, you can celebrate by playing a game or reading a book about the concept
              represented by the word.</li>
            <li>If your child starts forming complete sentences, you can celebrate by writing a story about them or
              creating an artwork that reflects their progress.</li>
            <li>If your child begins reading books on their own, you can celebrate by going to the library or a book
              fair.</li>
          </ul>

          <h3>Tips for encouraging a child’s development:</h3>
          <ul>
            <li>Provide a supportive environment:
              <ul>
                <li>Provide a safe and supportive environment for the child where they feel comfortable and free to
                  express themselves.</li>
                <li>Encourage your child to try new things and discover their skills and abilities.</li>
              </ul>
            </li>
            <li>Set realistic goals:
              <ul>
                <li>Help your child set realistic goals that are achievable.</li>
                <li>Break down big goals into small achievable steps.</li>
              </ul>
            </li>
            <li>Motivation and encouragement:
              <ul>
                <li>Encourage your child to put in more effort to achieve their goals.</li>
                <li>Focus on their strengths and skills, and help them overcome their weaknesses.</li>
              </ul>
            </li>
            <li>Appreciation and recognition:
              <ul>
                <li>Express your appreciation for your child’s efforts, even if they did not succeed in achieving their
                  goal.</li>
                <li>Focus on learning and experimenting, not just the end result.</li>
              </ul>
            </li>
          </ul>

          <p>
            Celebrating a child’s achievements and encouraging their development are among the most important things
            that contribute to developing their skills and abilities, boosting their self-confidence, and making them
            positive and successful individuals in the future.
          </p>

          <p class="strong-paragraph">Remember:</p>
          <ul>
            <li>Every child is unique, with different abilities and potentials.</li>
            <li>Compare your child only with themselves, not with others.</li>
            <li>Enjoy your child’s journey and learn with them, be patient and supportive.</li>
          </ul>

          <p class="strong-paragraph">I hope you liked the article! Feel free to share it with friends and family. Until
            next time!</p>
        </div>
      </div>

      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>

    <h1>You may like it</h1>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/en/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogTwo",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "Celebrating Children’s Language Progress.",
      date: "03/03/2024",
      img: 'https://monglish.b-cdn.net/blogs/blog-2.webp',
      blogCards: [
        {
          id: 1,
          title: 'Learning English in Early Ages',
          image: 'https://monglish.b-cdn.net/blogs/blogone.webp',
          link: '/en/blog/learning-english-in-early-ages',
        },
        {
          id: 2,
          title: "Celebrating Children’s Language Progress.",
          image: 'https://monglish.b-cdn.net/blogs/blog-2.webp',
          link: '/en/blog/celebrating-childrens-language-progress',
        },
        {
          id: 3,
          title: 'Seasons of The Year',
          image: 'https://monglish.b-cdn.net/blogs/blog3.webp',
          link: '/en/blog/seasons-of-the-year',
        },
        {
          id: 4,
          title: 'Parents and Children Healthy Relationships',
          image: 'https://monglish.b-cdn.net/blogs/blog4.webp',
          link: '/en/blog/parents-and-children-healthy-relationships',
        },
        {
          id: 5,
          title: 'Activities For Positive Behavior',
          image: 'https://monglish.b-cdn.net/blogs/blog5.webp',
          link: '/en/blog/activities-for-positive-behavior',
        },
        {
          id: 6,
          title: 'Benefits Of Bilingual For Children',
          image: 'https://monglish.b-cdn.net/blogs/blog6.webp',
          link: '/en/blog/benefits-of-bilingual-for-children',
        },
      ],
    };
  },
};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: ltr;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

 

.blog-view {
  margin-left: 2.5%;
  text-align: left;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: left;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}
</style>
