<template>
  <div class="section-two">
    <div class="title my-5">
      <div>Our services meet all needs!</div>
      <div>The academy offers comprehensive educational and training courses for all those eager to learn.</div>
    </div>
    <div class="cards-container">
      <HomeCard
        :img-src="'https://monglish.b-cdn.net/home/2.webp'"
        title="Preparing for the Test"
        subtitle="( IELTS )"
        description="Comprehensive training covering all test skills, with personalized support to ensure you pass confidently and achieve the highest scores!"
        @click="goToPackagesPage"
      >
        <template #button>
          <MainButton @click="goToPackagesPage">Explore Preparation Packages</MainButton>
        </template>
      </HomeCard>
      <HomeCard
        :img-src="'https://monglish.b-cdn.net/home/3.webp'"
        title="Foundations of English (Ages 5-18)"
        subtitle="( Courses for Kids )"
        description="The Living Curriculum enhances English skills for children and youth through daily interaction and ongoing support for personal and professional success."
        @click="goToKidsCoursesPage"
      >
        <template #button>
          <MainButton @click="goToKidsCoursesPage">Explore Kids' Courses</MainButton>
        </template>
      </HomeCard>
      <HomeCard
        :img-src="'https://monglish.b-cdn.net/home/1.webp'"
        title="English Language Teaching"
        subtitle="( Courses for Adults )"
        description="Improve your English language skills and increase your work success tenfold through online learning with experienced trainers!"
        @click="goToAdultsCoursesPage"
      >
        <template #button>
          <MainButton @click="goToAdultsCoursesPage">Explore Adult Courses</MainButton>
        </template>
      </HomeCard>
    </div>
  </div>
</template>

<script>
import HomeCard from '../Elements/HomeCard.vue'; 
import MainButton from '../Elements/MainButton.vue'; 

export default {
  name: 'ViewTwo',
  components: {
    HomeCard,
    MainButton, 
  },
  methods: {
    goToKidsCoursesPage() {
      this.$router.push({ path: '/en/kids-courses', hash: '#packages-section' });
    },
    goToAdultsCoursesPage() {
      this.$router.push({ path: '/en/adult-courses', hash: '#packages-section' });
    },
    goToPackagesPage() {
      this.$router.push({ path: '/en/ielts-courses', hash: '#packages-section' });
    }
  },
}
</script>

<style src="@/assets/styles/ar/ViewTwo.css" scoped></style>
