<template>
    <div class="job-container-en">
        <main class="job-main-en">
            <div class="job-card-en">
                <h2>Register for the job</h2>
                <form @submit.prevent="registerJob" class="card-en" enctype="multipart/form-data">
                    <p v-if="errorMessage" class="error-en">{{ errorMessage }}</p>
                    <div class="form-row-en">
                        <div class="form-group-en">
                            <label for="job_apply">Position (Required) <span class="error-en">*</span></label>
                            <select name="job_apply" id="job_apply" required="required" v-model="job_apply">
                                <option value="ESL Instructor">ESL Instructor</option>
                                <option value="Reading &amp; Accent Reduction Teacher">Reading &amp; Accent Reduction
                                    Teacher</option>
                                <option value="Speaking Club Trainer">Speaking Club Trainer</option>
                                <option value="Writing Teacher">Writing Teacher</option>
                                <option value="Student Counselor">Student Counselor</option>
                                <option value="Video Editor">Video Editor</option>
                                <option value="Social Media moderator ">Social Media moderator </option>
                                <option value="Media buyer ">Media buyer </option>
                                <option value="Content Writer ">Content Writer </option>
                                <option value="Graphic designer">Graphic designer</option>
                                <option value="UI / UX designer">UI / UX designer</option>
                                <option value="HR Coordinator">HR Coordinator</option>
                                <option value="Personal &amp; payroll">Personal &amp; payroll</option>
                                <option value="Recruitment specialist">Recruitment specialist</option>
                                <option value="Sales Team Leader  ">Sales Team Leader </option>
                                <option value="Education Advisor ">Education Advisor </option>
                                <option value="Class Supervisor">Class Supervisor</option>
                                <option value="Admission Agent">Admission Agent</option>
                                <option value="Help desk">Help desk</option>
                                <option value="PHP Laravel Full Stack Developer">PHP Laravel Full Stack Developer
                                </option>
                                <option value="Network engineer">Network engineer</option>
                                <option value="receptionist">receptionist</option>
                                <option value="Purchasing Officer">Purchasing Officer</option>
                                <option value="Accountant">Accountant</option>
                                <option value="Quality Specialist ">Quality Specialist </option>
                                <option value="Internal audit specialist ">Internal audit specialist </option>
                            </select>
                            <p v-if="errors.job_apply" class="error-en">{{ errors.job_apply }}</p>
                        </div>
                        <div class="form-group-en">
                            <label for="experience">Experience (required)</label>
                            <input type="number" name="experience" id="experience" v-model="experience"
                                placeholder="Number of years of experience">
                            <p v-if="errors.experience" class="error-en">{{ errors.experience }}</p>
                        </div>
                    </div>
                    <div class="form-row-en">
                        <div class="form-group-en">
                            <label for="fullname">Full name (required) <span class="error-en">*</span></label>
                            <input type="text" name="fullname" id="fullname" v-model="fullname" placeholder="Full name">
                            <p v-if="errors.fullname" class="error-en">{{ errors.fullname }}</p>
                        </div>
                        <div class="form-group-en">
                            <label for="email">Email (required) <span class="error-en">*</span></label>
                            <input type="text" name="email" id="email" v-model="email"
                                placeholder="example@example.com">
                            <p v-if="errors.email" class="error-en">{{ errors.email }}</p>
                        </div>
                    </div>
                    <div class="form-row-en">
                        <div class="form-group-en">
                            <label for="phone">Phone number (required) <span class="error-en">*</span></label>
                            <vue-tel-input class="pop-input" v-model:phone="phone" v-model:country="country"
                                @input="onPhoneInput" @validate="onPhoneValidate" mode="international" name="phone"
                                :preferred-countries="[
                                    'SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ', 'US', 'UZ', 'AD', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO',
                                    'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BI', 'BJ', 'BL', 'BM', 'BN',
                                    'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM',
                                    'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EH', 'ER',
                                    'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN',
                                    'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN',
                                    'IO', 'IR', 'IS', 'IT', 'JE', 'JM', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KY', 'KZ', 'LA', 'LB',
                                    'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM',
                                    'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI',
                                    'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW',
                                    'PY', 'RE', 'RO', 'RS', 'RU', 'RW', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO',
                                    'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR',
                                    'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'UY', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YT',
                                    'ZA', 'ZM', 'ZW'
                                ]" defaultCountry="SA" :inputOptions="inputOptions" :inputprops="{
                                    name: 'phone',
                                    id: 'phone',
                                    required: true,
                                    placeholder: 'phone number',
                                }" :disabled="loading" style="direction: ltr" />
                            <p v-if="errors.phone" class="error-en">{{ errors.phone }}</p>
                        </div>
                        <div class="form-group-en">
                            <label for="whatsappno">whatsapp number (required) <span class="error-en">*</span></label>
                            <vue-tel-input class="pop-input" v-model:whatsappno="whatsappno" v-model:country="country"
                                @input="onWhatsappNoInput" @validate="onWhatsappNoValidate" mode="international"
                                name="whatsapp_no" :preferred-countries="[
                                    'SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ', 'US', 'UZ', 'AD', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO',
                                    'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BI', 'BJ', 'BL', 'BM', 'BN',
                                    'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM',
                                    'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EH', 'ER',
                                    'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN',
                                    'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN',
                                    'IO', 'IR', 'IS', 'IT', 'JE', 'JM', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KY', 'KZ', 'LA', 'LB',
                                    'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM',
                                    'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI',
                                    'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW',
                                    'PY', 'RE', 'RO', 'RS', 'RU', 'RW', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO',
                                    'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR',
                                    'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'UY', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YT',
                                    'ZA', 'ZM', 'ZW'
                                ]" defaultCountry="SA" :inputOptions="inputOptions" :inputprops="{
                                    name: 'whatsapp_no',
                                    id: 'whatsappno',
                                    required: true,
                                    placeholder: 'رقم الهاتف',
                                }" :disabled="loading" style="direction: ltr" />
                            <p v-if="errors.whatsappno" class="error-en">{{ errors.whatsappno }}</p>
                        </div>
                    </div>
                    <div class="form-row-en">
                        <div class="form-group-en">
                            <label for="cv">CV (required) <span class="error-en">*</span></label>
                            <input type="file" name="cv" id="cv" v-on:change="handleFileChange">
                        </div>
                    </div>
                    <!-- <div class="form-row-en">
                        <div class="form-group-en">
                            <label for="notes">Note</label>
                            <textarea name="note" id="note" rows="4" placeholder="Hello!"></textarea>
                        </div>
                    </div> -->
                    <button type="submit" class="register-button-en" :disabled="isSubmitting">Apply For The Job</button>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

export default {
    components: {
        VueTelInput,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const job_apply = ref('');
        const experience = ref('');
        const fullname = ref('');
        const email = ref('');
        const phone = ref('');
        const whatsappno = ref('');
        const cv = ref(null);
        const notes = ref('');
        const errors = ref({ job_apply: '', experience: null, fullname: '', email: '', phone: '', whatsappno: '', cv: '' });
        const errorMessage = ref('');
        const isSubmitting = ref(false);
        const isValidPhone = ref(false);
        const isValidPhone2 = ref(false);

        const handleFileChange = (event) => {
            cv.value = event.target.files[0];
        };

        const validate = () => {
            errors.value.job_apply == job_apply.value.trim() === '' ? 'Email is required' : '';
            errors.value.experience == experience.value === null ? 'Experience is required' : '';
            errors.value.fullname == fullname.value.trim() === '' ? 'Full name is required' : '';
            errors.value.email = email.value.trim() === '' ? 'Email address is required' : '';
            errors.value.phone = phone.value.trim() === '' ? 'Phone is required' : '';
            errors.value.whatsappno = whatsappno.value.trim() === '' ? 'Whatsapp no is required' : '';
            return !errors.value.job_apply && !errors.value.experience && !errors.value.fullname && !errors.value.email && !errors.value.phone && !errors.value.whatsappno;
        };

        const registerJob = async () => {
            if (!validate()) return;

            isSubmitting.value = true;
            errorMessage.value = '';

            console.log('phone: ', phone.value);
            console.log('whatsapp no: ', whatsappno.value);

            try {
                const formData = new FormData();
                formData.append('position', job_apply.value);
                formData.append('experience', experience.value);
                formData.append('name', fullname.value);
                formData.append('email', email.value);
                formData.append('phone', phone.value);
                formData.append('whatsapp_no', whatsappno.value);
                formData.append('cv', cv.value); // Append the file
                formData.append('notes', notes.value);

                const response = await axios.post('/api/job-application-forms', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                const userInfo = response.data.data;

                // Update the Vuex store
                store.dispatch('updateUser', userInfo);

                // Store the token and user name in sessionStorage
                sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

                // Redirect to another page, e.g., home page
                router.push({ name: 'HomeAr' }); // Assuming you have a route named 'Home Page'
            } catch (error) {
                console.log(error);
                errorMessage.value = error.response ? error.response.data.data.error : error;
            } finally {
                isSubmitting.value = false;
            }
        };
        return {
            job_apply,
            experience,
            fullname,
            email,
            phone,
            whatsappno,
            cv,
            notes,
            errors,
            errorMessage,
            isSubmitting,
            registerJob,
            handleFileChange,
            isValidPhone,
            isValidPhone2,
        };
    },
    methods: {
        onPhoneInput(formattedNumber, phoneObject) {
            if (phoneObject && phoneObject.number) {
                console.log("Phone Input:", formattedNumber, phoneObject);
                this.phone = phoneObject.number;
            }
        },
        onPhoneValidate(isValid, phoneObject) {
            console.log("Validation Result:", isValid);
            this.isValidPhone = isValid;

            if (isValid && phoneObject && phoneObject.number) {
                this.phone = isValid.number;
            } else if (!isValid) {
                console.warn("Phone number is not valid.");
            }
        },
        onWhatsappNoInput(formattedNumber, phoneObject) {
            if (phoneObject && phoneObject.number) {
                console.log("Phone Input:", formattedNumber, phoneObject);
                this.whatsappno = phoneObject.number;
            }
        },
        onWhatsappNoValidate(isValid, phoneObject) {
            console.log("Validation Result:", isValid);
            this.isValidPhone2 = isValid;

            if (isValid && phoneObject && phoneObject.number) {
                this.whatsappno = isValid.number;
            } else if (!isValid) {
                console.warn("Phone number is not valid.");
            }
        },
    },
}
</script>

<style scoped>
/* job container */
.job-container-en {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;
    margin-top: -100px;
    background-image: url('https://monglish.b-cdn.net/home/job_registration_bg.png');
    background-size: cover;
    background-position: center;
    color: #464646;
}

/* job main */
.job-main-en {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

.job-card-en {
    flex: 1;
    background: #fff;
    padding: 80px 40px;
    border-radius: 20px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 50px;
}

.job-card-en h2 {
    color: #000;
    font-family: "DIN Next LT Arabic-n", Sans-serif;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
}

.card-en {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-group-en {
    display: flex;
    flex-direction: column;
    width: 100%;
    direction: rtl;
}

.form-group-en label {
    margin-top: 15px;
    text-align: left;
    margin-left: 30px;
    color: #8D93A1;
}

.form-group-en input,
.form-group-en select,
.form-group-en textarea {
    width: 90%;
    padding: 10px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #f7f9fc;
    text-align: left;
    direction: ltr;
}

.form-group-en input[type='file'] {
    background-color: transparent;
}

.form-group-en .vue-tel-input {
    width: 90%;
    background-color: #f7f9fc;
    margin-inline: 1.5rem;
}

.form-group-en .vue-tel-input input[type="tel"] {
    background-color: #f7f9fc;
}

.vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
    top: 50px;
    left: 0;
    width: 39.4rem;
    text-align: left;
}

.register-button-en {
    padding: 5px;
    background: linear-gradient(45deg, #fe9b4f, #f47e23);
    color: white;
    border: none;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
    margin-top: 30px;
    font-size: larger;
}

.form-row-en {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.terms-en {
    margin-top: 25px;
}

.error-en {
    color: red;
}

@media (min-width: 320px) and (max-width: 479px) {
    .job-container {
        height: max-content;
    }

    .job-main-en {
        flex-direction: column;
        margin: auto;
        width: 90%;
    }

    .job-container-en .job-info-p-en {
        color: #fff;
        font-size: medium;
        text-align: center;
    }

    .job-container-en .job-card-en {
        margin-top: 110px;
        width: 100%;
        border-radius: 20px;
        padding: 40px 40px;
    }

    .job-card-en h2 {
        font-size: larger;
    }

    .form-row-en {
        flex-direction: column;
    }

    .form-group-en {
        width: 100%;
    }

    .form-group-en label {
        margin-right: 0;
        margin-top: 0;
    }

    .form-group-en input,
    .form-group-en select,
    .form-group-en textarea {
        width: 100%;
        /* padding: 5px; */
        margin: 0;
    }

    .form-group-en .vue-tel-input {
        margin-inline: 0;
    }

    .vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
        right: 0;
        width: 17.2rem;
        text-align: right;
    }

    .job-button-en {
        padding: 5px;
        font-size: medium;
        width: 80%;
    }
}

@media (min-width: 480px) and (max-width: 639px) {

    /* Styles */
    .job-container-en .job-info-p-en {
        color: #fff;
    }

    .job-container-en .job-card-en {
        margin-top: 40px;
    }

    .job-main-en {
        flex-direction: column;
    }

}
</style>