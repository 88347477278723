<template>
  <div class="photo-grid">
    <div
      v-for="(photo, index) in paginatedPhotos"
      :key="'photo-' + index"
      class="photo-wrapper"
    >
      <img
        :src="photo.src"
        :alt="photo.alt"
        class="photo"
        :class="[
          photo.size,
          isLargeNotFirstRow(index) ? 'shifted' : '',
          isSmallFirstRow(index) ? 'small-first-row' : '',
        ]"
      />
    </div>
  </div>

  <div class="pagination">
    <button
      @click="previousPage"
      :disabled="currentPage === 1"
      class="move"
      aria-label="Previous Page"
    >
      <i class="fas fa-long-arrow-alt-left"></i>
    </button>
    <button
      v-for="page in pageNumbers"
      :key="'page-' + page"
      @click="goToPage(page)"
      :class="{ active: page === currentPage }"
    >
      {{ page }}
    </button>
    <button
      @click="nextPage"
      :disabled="currentPage === totalPages"
      class="move"
      aria-label="Next Page"
    >
      <i class="fas fa-long-arrow-alt-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "PhotosPages",
  data() {
    return {
      photos: [
        { src: "https://monglish.b-cdn.net/photos/1.webp", alt: "Photo 1" },
        { src: "https://monglish.b-cdn.net/photos/2.webp", alt: "Photo 2" },
        { src: "https://monglish.b-cdn.net/photos/3.webp", alt: "Photo 3" },
        { src: "https://monglish.b-cdn.net/photos/4.webp", alt: "Photo 4" },
        { src: "https://monglish.b-cdn.net/photos/5.webp", alt: "Photo 5" },
        { src: "https://monglish.b-cdn.net/photos/6.webp", alt: "Photo 6" },
        { src: "https://monglish.b-cdn.net/photos/7.webp", alt: "Photo 7" },
        { src: "https://monglish.b-cdn.net/photos/8.webp", alt: "Photo 8" },
        { src: "https://monglish.b-cdn.net/photos/9.webp", alt: "Photo 9" },
        { src: "https://monglish.b-cdn.net/photos/10.webp", alt: "Photo 10" },
        { src: "https://monglish.b-cdn.net/photos/11.webp", alt: "Photo 11" },
        { src: "https://monglish.b-cdn.net/photos/12.webp", alt: "Photo 12" },
        { src: "https://monglish.b-cdn.net/photos/13.webp", alt: "Photo 13" },
        { src: "https://monglish.b-cdn.net/photos/14.webp", alt: "Photo 14" },
        { src: "https://monglish.b-cdn.net/photos/15.webp", alt: "Photo 15" },
        { src: "https://monglish.b-cdn.net/photos/16.webp", alt: "Photo 16" },
        { src: "https://monglish.b-cdn.net/photos/17.webp", alt: "Photo 17" },
        { src: "https://monglish.b-cdn.net/photos/18.webp", alt: "Photo 18" },
        { src: "https://monglish.b-cdn.net/photos/19.webp", alt: "Photo 19" },
        { src: "https://monglish.b-cdn.net/photos/20.webp", alt: "Photo 20" },
        { src: "https://monglish.b-cdn.net/photos/21.webp", alt: "Photo 21" },
        { src: "https://monglish.b-cdn.net/photos/22.webp", alt: "Photo 22" },
        { src: "https://monglish.b-cdn.net/photos/23.webp", alt: "Photo 23" },
        { src: "https://monglish.b-cdn.net/photos/24.webp", alt: "Photo 24" },
      ],
      currentPage: 1,
      itemsPerPage: 12,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.photos.length / this.itemsPerPage);
    },
    paginatedPhotos() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.photos.slice(start, end).map((photo, index) => ({
        ...photo,
        size: index % 2 === 0 ? "large" : "small",
      }));
    },
    pageNumbers() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    isLargeNotFirstRow(index) {
      const rowIndex = Math.floor(index / 3);
      return this.paginatedPhotos[index].size === "large" && rowIndex > 0;
    },
    isSmallFirstRow(index) {
      return (
        this.paginatedPhotos[index].size === "small" &&
        Math.floor(index / 3) === 0
      );
    },
  },
};
</script>

<style scoped>
.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  margin: 0 5em;
  direction: ltr;
  margin-top: -10%;
}

.photo-wrapper {
  width: 100%;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.photo-wrapper:hover {
  transform: scale(1.02);
}

.photo {
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.photo:hover {
  filter: brightness(1.1);
}

.large {
  height: 80vh;
}

.small {
  height: 60vh;
}

.shifted {
  margin-top: -10vh;
}

.small-first-row {
  margin-top: 10vh;
}

.pagination {
  text-align: center;
  margin: 20px 0;
  direction: ltr;
}

.pagination button {
  border: none;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  font-size: 18px;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button.active {
  background-color: #165e84;
  color: white;
}

.pagination button.move {
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.pagination button.move:hover {
  background-color: #f1f1f1;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .photo-grid {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 2em;
  }

  .large {
    height: 60vh;
  }

  .small {
    height: 40vh;
  }
}

@media (max-width: 768px) {
  .photo-grid {
    grid-template-columns: 1fr;
    margin: 0 1em;
    margin-top: -60%;
  }

  .large {
    height: 50vh;
  }

  .small {
    height: 30vh;
  }

  .shifted,
  .small-first-row {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .large {
    height: 40vh;
  }

  .small {
    height: 25vh;
  }

  .photo-grid {
    margin: 0 1em;
    margin-top: -60%;
  }
}
</style>
