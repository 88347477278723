<template>
    <div class="job-container">
        <main class="job-main">
            <div class="job-card">
                <h2>التسجيل للوظيفة</h2>
                <form @submit.prevent="registerJob" class="card" enctype="multipart/form-data">
                    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="job_apply">الوظيفة (مطلوب) <span class="error">*</span></label>
                            <select name="job_apply" id="job_apply" required="required" v-model="job_apply">
                                <option value="مدرس لغة إنجليزية كلغة ثانية">مدرس لغة إنجليزية كلغة ثانية</option>
                                <option value="مدرس قراءة وتعديل اللهجات">مدرس قراءة وتعديل اللهجات</option>
                                <option value="مدرب نادي التحدث">مدرب نادي التحدث</option>
                                <option value="مدرس كتابة">مدرس كتابة</option>
                                <option value="مستشار طلابي">مستشار طلابي</option>
                                <option value="محرر فيديو">محرر فيديو</option>
                                <option value="مشرف على وسائل التواصل الاجتماعي">مشرف على وسائل التواصل الاجتماعي</option>
                                <option value="مشتري وسائط">مشتري وسائط</option>
                                <option value="كاتب محتوى">كاتب محتوى</option>
                                <option value="مصمم جرافيكي">مصمم جرافيكي</option>
                                <option value="مصمم واجهة مستخدم/تجربة مستخدم">مصمم واجهة مستخدم/تجربة مستخدم</option>
                                <option value="منسق موارد بشرية">منسق موارد بشرية</option>
                                <option value="موظف شخصي ومسؤول عن الرواتب">موظف شخصي ومسؤول عن الرواتب</option>
                                <option value="أخصائي توظيف">أخصائي توظيف</option>
                                <option value="قائد فريق المبيعات">قائد فريق المبيعات</option>
                                <option value="مستشار تعليمي">مستشار تعليمي</option>
                                <option value="مشرف صف">مشرف صف</option>
                                <option value="وكيل قبول">وكيل قبول</option>
                                <option value="مكتب مساعدة">مكتب مساعدة</option>
                                <option value="مطور كامل لـ Laravel من PHP">مطور كامل لـ Laravel من PHP</option>
                                <option value="مهندس شبكات">مهندس شبكات</option>
                                <option value="موظف استقبال">موظف استقبال</option>
                                <option value="مسؤول مشتريات">مسؤول مشتريات</option>
                                <option value="محاسب">محاسب</option>
                                <option value="أخصائي جودة">أخصائي جودة</option>
                                <option value="أخصائي تدقيق داخلي">أخصائي تدقيق داخلي</option>
                            </select>
                            <p v-if="errors.job_apply" class="error">{{ errors.job_apply }}</p>
                        </div>
                        <div class="form-group">
                            <label for="experience">الخبرة (مطلوب)</label>
                            <input type="number" name="experience" id="experience" v-model="experience"
                                placeholder="عدد سنين الخبرة">
                            <p v-if="errors.experience" class="error">{{ errors.experience }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="fullname">الاسم كامل (مطلوب) <span class="error">*</span></label>
                            <input type="text" name="fullname" id="fullname" v-model="fullname"
                                placeholder="الاسم كامل">
                            <p v-if="errors.fullname" class="error">{{ errors.fullname }}</p>
                        </div>
                        <div class="form-group">
                            <label for="email">البريد الالكتروني (مطلوب) <span class="error">*</span></label>
                            <input type="text" name="email" id="email" v-model="email"
                                placeholder="example@example.com">
                            <p v-if="errors.email" class="error">{{ errors.email }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="phone">رقم الهاتف (مطلوب) <span class="error">*</span></label>
                            <vue-tel-input
                                class="pop-input"
                                v-model:phone="phone"
                                v-model:country="country"
                                @input="onPhoneInput"
                                @validate="onPhoneValidate"
                                mode="international"
                                name="phone"
                                :preferred-countries="[
                                    'SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ','US', 'UZ', 'AD', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 
                                    'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BI', 'BJ', 'BL', 'BM', 'BN', 
                                    'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 
                                    'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EH', 'ER', 
                                    'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 
                                    'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 
                                    'IO', 'IR', 'IS', 'IT', 'JE', 'JM', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KY', 'KZ', 'LA', 'LB', 
                                    'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 
                                    'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 
                                    'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 
                                    'PY', 'RE', 'RO', 'RS', 'RU', 'RW', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 
                                    'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 
                                    'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'UY', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YT', 
                                    'ZA', 'ZM', 'ZW'
                                ]"
                                defaultCountry="SA"
                                :inputOptions="inputOptions"
                                :inputprops="{
                                    name: 'phone',
                                    id: 'phone',
                                    required: true,
                                    placeholder: 'رقم الهاتف',
                                }"
                                :disabled="loading"
                                style="direction: ltr"
                            />
                            <p v-if="errors.phone" class="error">{{ errors.phone }}</p>
                        </div>
                        <div class="form-group">
                            <label for="whatsappno">رقم واتساب (مطلوب) <span class="error">*</span></label>
                            <vue-tel-input
                                class="pop-input"
                                v-model:whatsappno="whatsappno"
                                v-model:country="country"
                                @input="onWhatsappNoInput"
                                @validate="onWhatsappNoValidate"
                                mode="international"
                                name="whatsapp_no"
                                :preferred-countries="[
                                    'SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ','US', 'UZ', 'AD', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 
                                    'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BI', 'BJ', 'BL', 'BM', 'BN', 
                                    'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 
                                    'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EH', 'ER', 
                                    'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 
                                    'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 
                                    'IO', 'IR', 'IS', 'IT', 'JE', 'JM', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KY', 'KZ', 'LA', 'LB', 
                                    'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 
                                    'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 
                                    'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 
                                    'PY', 'RE', 'RO', 'RS', 'RU', 'RW', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 
                                    'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 
                                    'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'UY', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YT', 
                                    'ZA', 'ZM', 'ZW'
                                ]"
                                defaultCountry="SA"
                                :inputOptions="inputOptions"
                                :inputprops="{
                                    name: 'whatsappno',
                                    id: 'whatsapp_no',
                                    required: true,
                                    placeholder: 'رقم الهاتف',
                                }"
                                :disabled="loading"
                                style="direction: ltr"
                            />
                            <p v-if="errors.whatsappno" class="error">{{ errors.whatsappno }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="cv">CV (مطلوب) <span class="error">*</span></label>
                            <input type="file" name="cv" id="cv" v-on:change="handleFileChange">
                        </div>
                    </div>
                    <!-- <div class="form-row">
                        <div class="form-group">
                            <label for="notes">ملحوظه</label>
                            <textarea name="note" id="note" rows="4" placeholder="مرحبا"></textarea>
                        </div>
                    </div> -->
                    <button type="submit" class="register-button" :disabled="isSubmitting">تسجيل الدخول</button>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { VueTelInput } from "vue3-tel-input";
    import "vue3-tel-input/dist/vue3-tel-input.css";

    export default {
        components: {
            VueTelInput,
        },
        setup() {
            const router = useRouter();
            const store = useStore();
            const job_apply = ref('');
            const experience = ref('');
            const fullname = ref('');
            const email = ref('');
            const phone = ref('');
            const whatsappno = ref('');
            const cv = ref(null);
            const notes = ref('');
            const errors = ref({ job_apply: '', experience: null, fullname: '', email: '', phone: '', whatsappno: '', cv: '' });
            const errorMessage = ref('');
            const isSubmitting = ref(false);
            const isValidPhone = ref(false);
            const isValidPhone2 = ref(false);

            const handleFileChange = (event) => {
                cv.value = event.target.files[0];
            };

            const validate = () => {
                errors.value.job_apply == job_apply.value.trim() === '' ? 'الوظيفه مطلوبه' : '';
                errors.value.experience == experience.value === null ? 'الخبرة مطلوبه' : '';
                errors.value.fullname == fullname.value.trim() === '' ? 'الاسم مطلوبه' : '';
                errors.value.email = email.value.trim() === '' ? 'البريد الإلكتروني مطلوب' : '';
                errors.value.phone = phone.value.trim() === '' ? 'الهاتف مطلوب' : '';
                errors.value.whatsappno = whatsappno.value.trim() === '' ? 'رقم الواتساب مطلوب' : '';
                return !errors.value.job_apply && !errors.value.experience && !errors.value.fullname && !errors.value.email && !errors.value.phone && !errors.value.whatsappno;
            };

            const registerJob = async () => {
                if (!validate()) return;

                isSubmitting.value = true;
                errorMessage.value = '';

                console.log('phone: ', phone.value);
                console.log('whatsapp no: ', whatsappno.value);

                try {
                    const formData = new FormData();
                    formData.append('position', job_apply.value);
                    formData.append('experience', experience.value);
                    formData.append('name', fullname.value);
                    formData.append('email', email.value);
                    formData.append('phone', phone.value);
                    formData.append('whatsapp_no', whatsappno.value);
                    formData.append('cv', cv.value); // Append the file
                    formData.append('notes', notes.value);

                    const response = await axios.post('/api/job-application-forms', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const userInfo = response.data.data;

                    // Update the Vuex store
                    store.dispatch('updateUser', userInfo);

                    // Store the token and user name in sessionStorage
                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

                    // Redirect to another page, e.g., home page
                    router.push({ name: 'HomeAr' }); // Assuming you have a route named 'Home Page'
                } catch (error) {
                    console.log(error);
                    errorMessage.value = error.response ? error.response.data.data.error : error;
                } finally {
                    isSubmitting.value = false;
                }
            };
            return {
                job_apply,
                experience,
                fullname,
                email,
                phone,
                whatsappno,
                cv,
                notes,
                errors,
                errorMessage,
                isSubmitting,
                registerJob,
                handleFileChange,
                isValidPhone,
                isValidPhone2,
            };
        },
        methods: {
            onPhoneInput(formattedNumber, phoneObject) {
                if (phoneObject && phoneObject.number) {
                    console.log("Phone Input:", formattedNumber, phoneObject);
                    this.phone = phoneObject.number;
                }
            },
            onPhoneValidate(isValid, phoneObject) {
                console.log("Validation Result:", isValid);
                this.isValidPhone = isValid;

                if (isValid && phoneObject && phoneObject.number) {
                    this.phone = isValid.number;
                } else if (!isValid) {
                    console.warn("Phone number is not valid.");
                }
            },
            onWhatsappNoInput(formattedNumber, phoneObject) {
                if (phoneObject && phoneObject.number) {
                    console.log("Phone Input:", formattedNumber, phoneObject);
                    this.whatsappno = phoneObject.number;
                }
            },
            onWhatsappNoValidate(isValid, phoneObject) {
                console.log("Validation Result:", isValid);
                this.isValidPhone2 = isValid;

                if (isValid && phoneObject && phoneObject.number) {
                    this.whatsappno = isValid.number;
                } else if (!isValid) {
                    console.warn("Phone number is not valid.");
                }
            },
        },
    }
</script>

<style scoped>
    /* job container */
    .job-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1000px;
        margin-top: -100px;
        background-image: url('https://monglish.b-cdn.net/home/job_registration_bg.png');
        background-size: cover;
        background-position: center;
        color: #464646;
    }

    /* job main */
    .job-main {
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;
    }

    .job-card {
        flex: 1;
        background: #fff;
        padding: 80px 40px;
        border-radius: 20px;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
        text-align: center;
        margin-top: 50px;
    }

    .job-card h2 {
        color: #000;
        font-family: "DIN Next LT Arabic", Sans-serif;
        margin-bottom: 20px;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-group label {
        margin-top: 15px;
        text-align: right;
        margin-right: 30px;
        color: #8D93A1;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        width: 90%;
        padding: 10px;
        margin-right: 20px;
        border-radius: 10px;
        background-color: #f7f9fc;
        text-align: right;
    }

    .form-group input[type='file'] {
        background-color: transparent;
    }

    .form-group .vue-tel-input {
        width: 90%;
        background-color: #f7f9fc;
        margin-inline: 1.5rem;
    }

    .form-group .vue-tel-input input[type="tel"] {
        background-color: #f7f9fc;
    }

    .vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
        right: 0;
        width: 39.4rem;
        text-align: right;
    }

    .vue-tel-input :deep(.vti__input) {
        text-align: right;
    }

    .register-button {
        padding: 5px;
        background: linear-gradient(45deg, #fe9b4f, #f47e23);
        color: white;
        border: none;
        border-radius: 20px;
        width: 100%;
        cursor: pointer;
        margin-top: 30px;
        font-size: larger;
    }

    .form-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .terms {
        margin-top: 25px;
    }

    .error {
        color: red;
    }

    @media (min-width: 320px) and (max-width: 479px) {
        .job-container {
            height: max-content;
        }

        .job-main {
            flex-direction: column;
            margin: auto;
            width: 90%;
        }

        .job-container .job-info-p {
            color: #fff;
            font-size: medium;
            text-align: center;
        }

        .job-container .job-card {
            margin-top: 110px;
            width: 100%;
            border-radius: 20px;
            padding: 40px 40px;
        }

        .job-card h2 {
            font-size: larger;
        }

        .form-row {
            flex-direction: column;
        }

        .form-group {
            width: 100%;
        }

        .form-group label {
            margin-right: 0;
            margin-top: 0;
        }

        .form-group input, .form-group select, .form-group textarea {
            width: 100%;
            /* padding: 5px; */
            margin: 0;
        }

        .form-group .vue-tel-input {
            margin-inline: 0;
        }

        .vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
            right: 0;
            width: 17.2rem;
            text-align: right;
        }

        .vue-tel-input :deep(.vti__input) {
            text-align: right;
        }

        .job-button {
            padding: 5px;
            font-size: medium;
            width: 80%;
        }
    }

    @media (min-width: 480px) and (max-width: 639px) {

        /* Styles */
        .job-container .job-info-p {
            color: #fff;
        }

        .job-container .job-card {
            margin-top: 40px;
        }

        .job-main {
            flex-direction: column;
        }

    }
</style>