<template>
  <section class="content-slider-container">
    <div class="wrapper">
      <div class="immerse-row">
        <h3>Our Experts from the<br /><span>United States!</span></h3>
        <p>
          We are truly global in every way!
          <br />
          We are proud to have a select group of American English experts on our
          team. Their vast expertise and authentic linguistic culture guarantee
          you a rich and distinguished learning experience. Take advantage of
          their deep knowledge of American English to enhance your language
          skills.
        </p>
      </div>

      <div class="teachers-grid">
        <div v-for="teacher in teachers" :key="teacher.id" class="teacher-card">
          <div class="teacher-info">
            <img
              loading="lazy"
              :src="teacher.src"
              :alt="`img  ${teacher.name}`"
              class="teacher-image"
            />
            <h2>{{ teacher.name }}</h2>
            <p>{{ truncateDesc(teacher.desc) }}</p>
            <a :href="teacher.link" class="btn-view-more" target="_blank">
              Teacher Profile
              <i class="fa-solid fa-circle-arrow-right icon"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.content-slider-container {
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  background-image: url("https://monglish.b-cdn.net/home/bg.jpg");
  background-size: cover;
  background-position: center;
  direction: ltr;
  overflow-y: hidden;
  margin-top: -85px;
}
.wrapper {
  margin-top: 55px;
  background-color: white;
}
.immerse-row {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  gap: 2em;
  margin: 1em 0 2em 0;
  font-family: "DIN Next LT Arabic", sans-serif;
  position: relative;
}
.immerse-row h3 {
  padding-top: 1em;
  color: #165e84;
  font-size: 2.8rem;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.immerse-row h3 span {
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.immerse-row p {
  font-size: 1.375rem;
  color: gray;
  font-weight: 500;
  padding: 0 10%;
  margin: 0 auto;
}
.immerse-row button {
  background-color: transparent;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  color: #165e84;
  font-size: 1.4rem;
  border: 1px solid #165e84;
  border-radius: 25px;
  padding: 0.1em 3em 0.4em 3em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin-left: 50px;
}
.immerse-row button:hover {
  background-color: #165e84;
  color: #fff;
}
@media (max-width: 768px) {
  .immerse-row {
    padding: 0 20px;
    gap: 1em;
  }

  .immerse-row h3 {
    font-size: 2.2rem;
    text-align: center;
    width: 100%;
  }

  .immerse-row p {
    font-size: 1.2rem;
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }

  .immerse-row button {
    position: relative;
    margin-left: 0;
    margin-top: 1em;
    transform: none;
    width: auto;
    padding: 0.5em 2.5em;
    left: auto;
    top: auto;
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .immerse-row h3 {
    font-size: 1.8rem;
  }

  .immerse-row p {
    font-size: 1.1rem;
  }

  .immerse-row button {
    font-size: 1.2rem;
  }
}

.teachers-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 5%;
}

.teacher-card {
  background-color: #f2f4f5;
  border-radius: 20px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  direction: ltr;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.teacher-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1em;
}

.teacher-info {
  text-align: left;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  width: 100%;
}

.teacher-info h2 {
  font-size: 1.8rem;
  color: #283646;
  margin-bottom: 0.5em;
}

.teacher-info p {
  font-size: 1rem;
  color: #77808b;
  margin-bottom: 1em;
}

.btn-view-more {
  background-color: #ffffff;
  color: #000;
  font-size: 1.2rem;
  border: 1px solid #f47d21;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 0.5em 1em;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 0;
}

.btn-view-more:hover {
  background-color: #f47d21;
  color: #ffffff;
}

.icon {
  color: #f47d21;
  margin-left: 10px;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

a.btn-view-more:hover .icon {
  color: #ffffff;
}

@media (max-width: 1200px) {
  .teachers-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 900px) {
  .teachers-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }
}

@media (max-width: 600px) {
  .teachers-grid {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }
}
</style>

<script>
export default {
  data() {
    return {
      teachers: [
        {
          src: "https://monglish.b-cdn.net/experts/13.jpg",
          name: "Noura Al Sharif",
          desc: "My name is Dr. Noura, and I am an English language teacher with over 15 years of experience in the field. I hold a PhD and Masters in Educational Leadership and Management from Cambridge College in the United Kingdom. My professional qualifications include the CELTA and DELTA Unit 1 from Cambridge, the TKT Certificate in Teaching Young People (YL) from Cambridge, in addition to the Level 6 Diploma in TESOL accredited by OFQUAL in the United Kingdom. I am also a certified IELTS preparation trainer, reflecting my commitment to excellence in language teaching and exam preparation.",
          link: "/en/teachers/thirteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/10.jpg",
          name: "Abdulrahman Eid",
          desc: "Hello, I am Abdulrahman, an English teacher at Monglish International Academy. I graduated from the Faculty of Languages ​​and Translation at Pharos University, and hold a Diploma in Teaching English as a Foreign Language from Notting Hill College in the United Kingdom. I have 5 years of experience teaching English to students from diverse backgrounds, and I bring unique strengths and perspectives to the classroom. I am committed to empowering my students to reach their full potential, and I make learning effective and enjoyable through a variety of interactive activities, realistic scenarios, and multimedia resources.",
          link: "/en/teachers/ten",
        },
        {
          src: "https://monglish.b-cdn.net/experts/26.jpg",
          name: "Israa Eid",
          desc: "Hello! I am Israa, an English teacher at Monglish International Academy. I hold a degree in Education specializing in English and a TEFL certificate, and have over a year of experience teaching English online. My classes focus on collaborative learning and a student-centered approach, creating a welcoming environment where students can practice and express themselves confidently. I look forward to starting this exciting language learning journey with you!",
          link: "/en/teachers/twenty-six",
        },
        {
          src: "https://monglish.b-cdn.net/experts/11.jpg",
          name: "Israa Mohamed",
          desc: "I am Israa Mohamed, an English as a Second Language (ESL/EFL) teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, in 2020, and I have been working as an English teacher for 4 years now. I am a TEFL certified teacher. I make sure to build a strong relationship with all my students in each of my classes, which keeps them coming back and keen to achieve the best results in their English learning journey. I use game-based teaching techniques to motivate students and make the learning process fun and interesting. If you are looking for new ways to learn away from boredom, my classes are the perfect destination. What are you waiting for?!",
          link: "/en/teachers/eleven",
        },
        {
          src: "https://monglish.b-cdn.net/experts/28.jpg",
          name: "Amani Mohammed",
          desc: "I am Amani, a passionate teacher who loves teaching and communicating with people and students. I graduated from the Faculty of Education, English Department, and have been working in the field of teaching for 11 years. I have taught many courses, such as TOEFL, speaking skills, phonetics, and conversation, to university students and children. My goal is to be an unforgettable teacher, and to deliver information to my students in the best possible way.",
          link: "/en/teachers/twenty-eight",
        },
        {
          src: "https://monglish.b-cdn.net/experts/17.jpg",
          name: "Omar Gamal",
          desc: "As an English teacher with six years of experience, I deliver dynamic, student-centered lessons by integrating language theory with fun, hands-on activities. I create a supportive environment where students can practice speaking, listening, reading and writing with confidence. Using interactive lessons, life scenarios and storytelling, I aim to build a strong foundation in grammar, vocabulary and pronunciation, with a focus on developing communication skills through activities such as role-playing, games and group projects. Each lesson is tailored to the individual needs of the students to ensure they progress with confidence and success in academic and everyday communication.",
          link: "/en/teachers/seventeen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/14.jpg",
          name: "Jihad El Sayed",
          desc: "Hello! I am Jihad, an English teacher at Monglish International Academy. I graduated from the Faculty of Language and Interpretation, English Department. I have seven years of experience in teaching English and am a TEFL certified teacher. I have also worked as a translator and taught Arabic to native English speakers. I enjoy working with children and helping them express their creativity. To make my lessons fun and engaging, I incorporate fun activities and games into the lessons. I make use of AI tools and make sure to stay up to date with the latest teaching techniques and methods. I look forward to enjoying our educational journey together!",
          link: "/en/teachers/fourteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/16.jpg",
          name: "Nahid Mustafa",
          desc: "Hello, I am Nahid, an English teacher at Monglish International Academy. I graduated from the Faculty of Education, Department of Early Childhood, and have 8 years of teaching experience. I believe that students are at the heart of the learning process, and I strive to create a supportive and engaging environment that makes them play an active role in their own learning. Rather than leading from the front, I act as a guide, providing guidance, monitoring progress, and encouraging growth. My goal is to empower students to explore, ask questions, and develop critical thinking skills, while feeling safe to take risks and learn from mistakes. I strive to inspire confidence in each student, helping them discover their potential and take charge of their academic journey.",
          link: "/en/teachers/sixteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/10.jpg",
          name: "Mahmoud Gamal",
          desc: "Hello.. I am Mahmoud, an English teacher at Monglish International Academy. I graduated from the Faculty of Agriculture, and I have been teaching English for 7 years. I hold a TEFL Diploma in Teaching English as a Foreign Language. My classes feature group work activities, where students exchange ideas and collaborate in a safe and stress-free learning environment. I am excited to see you all in my classes.",
          link: "/en/teachers/fifteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/20.jpg",
          name: "Sara Magdy",
          desc: "I am Sara, an English teacher at Monglish International Academy, graduated from the Faculty of Languages ​​and Interpretation at Al-Azhar University. I have 16 years of experience teaching English in national and semi-international schools and online academies, and I am TEFL certified by Ofqual. I lead a writing club where I use a variety of techniques to enhance students’ writing skills, with an emphasis on teamwork and collaboration in a safe and supportive environment. I look forward to seeing you in my classes!",
          link: "/en/teachers/tweenty",
        },
        {
          src: "https://monglish.b-cdn.net/experts/27.jpg",
          name: "Alaa Mahgoub",
          desc: "I am Alaa Mahgoub, a Cambridge CELTA certified English teacher, working at Monglish International Academy. I have over 6 years of experience teaching English to teenagers and adults, with over 2,450 online sessions and teaching over 300 students from a variety of nationalities. I provide comprehensive IELTS preparation support, helping students gain the essential language skills to succeed in their careers. My classes feature modern techniques that combine fun and interaction, with a focus on developing the four skills (reading, speaking, writing, and listening) through authentic resources and interactive activities.",
          link: "/en/teachers/twenty-seven",
        },
        {
          src: "https://monglish.b-cdn.net/experts/12.jpg",
          name: "Maryam Adel",
          desc: "Hello.. I am Maryam Adel, an English teacher at Monglish International Academy. I graduated from the Faculty of Education, English Department, and have 8 years of teaching experience. I also hold a TEFL Diploma in Teaching English as a Foreign Language. My classes feature group work activities where students exchange ideas and collaborate in a safe and stress-free learning environment. I am excited to see you all in my classes!",
          link: "/en/teachers/twelve",
        },
        {
          src: "https://monglish.b-cdn.net/experts/19.jpg",
          name: "Nada Yahya",
          desc: "Hello... I'm Nada, an ESL teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and have 4 years of experience in teaching English. I also hold a diploma in teaching English as a foreign language. My classes feature group work activities, where students exchange ideas and collaborate in a safe and stress-free learning environment. I'm excited to see you all in my classes!",
          link: "/en/teachers/ninteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/21.jpg",
          name: "Amira Adel",
          desc: "Hello... I'm Amira, an English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and have experience in teaching English For eight years. I also hold a TEFL (Teaching English as a Foreign Language) certificate, and am currently working towards my CELTA. In my classes, I make sure to include group work activities that allow students to exchange ideas and interact in a safe and stress-free learning environment. I am excited to see you all in my classes. During my sessions, I make sure to engage everyone and provide them with space to express themselves, by helping them adopt the right way. I can't wait to see you in my classes and have lots of fun together!",
          link: "/en/teachers/twenty-one",
        },
        {
          src: "https://monglish.b-cdn.net/experts/3.jpg",
          name: "Heba Khalifa",
          desc: "Hello, I am Heba, an English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, Literature Section. I have 13 years of teaching experience, and I am a certified TEFL trainer. In my classes, I use fun and different activities to teach students, and I encourage them to share their ideas and speak freely without fear of making mistakes. I work to teach them how to be confident while using English. Excited to see you all in my classes!",
          link: "/en/teachers/three",
        },
        {
          src: "https://monglish.b-cdn.net/experts/9.jpg",
          name: "Walaa Fayek",
          desc: "Welcome.. I am Walaa Fayek.. A trainer at the Monglish International Academy for Teaching English.. I have had the opportunity to teach English for 11 years in different places around the world such as India, China, Oman and Poland.. Over the past few years, I have obtained many certificates starting with a diploma in teaching English as a foreign language, then a diploma... from the British Council in Poland, as well as a diploma in teaching Arabic as a foreign language and finally several certificates in treating dyslexia and learning difficulties from the University of Arizona.. The classes at Monglish are unparalleled.. We always encourage our students to express themselves freely.. Our role as teachers is to guide students through innovative teaching methods.. I can't Looking forward to seeing you in my classes",
          link: "/en/teachers/nine",
        },
        {
          src: "https://monglish.b-cdn.net/experts/5.jpg",
          name: "Mai Al-Tawil",
          desc: "I am Mai Al-Tawil, a dedicated teacher with over ten years of teaching experience. Throughout my career, I have had the opportunity to work with students of all ages and backgrounds, which has helped me develop my skills in designing dynamic and engaging lessons that cater to individual learning styles. My commitment to teaching excellence is evident in my pursuit of a TEFL certification, which has provided me with valuable insights into effective language teaching methodologies. My teaching philosophy is to create an inclusive and supportive classroom environment that fosters student growth and confidence.",
          link: "/en/teachers/five",
        },
        {
          src: "https://monglish.b-cdn.net/experts/2.jpg",
          name: "Fatima Hassan",
          desc: "Hello.. I'm Fatima, an English teacher at Monglish International Academy. I graduated from the Faculty of Commerce, English Department, and hold a TEFL certificate as well as a certificate in teaching young people from Notting Hill College. I have 13 years of teaching experience. My classes are dynamic and student-focused. I can't wait to see you in my classes!",
          link: "/en/teachers/two",
        },
        {
          src: "https://monglish.b-cdn.net/experts/18.jpg",
          name: "Hajar Fathi",
          desc: "Hello! I am Hager Diab, an English teacher at Monglish International Academy. I graduated from the Faculty of Languages, Ain Shams University, and have one year of experience teaching English. My classes are student-centered, giving students the opportunity to speak and express their thoughts and opinions freely, while building confidence in their language skills. My lessons include group activities in a very friendly and supportive atmosphere. I can't wait to see you all in my classes!",
          link: "/en/teachers/eighteen",
        },
        {
          src: "https://monglish.b-cdn.net/experts/22.jpg",
          name: "Noura Hafez",
          desc: "I am Noura, an English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and have 4 years of teaching experience, in addition to a diploma in teaching English as a foreign language. My classes feature group activities where students exchange ideas and collaborate in a safe, stress-free learning environment. I'm excited to see you all in my classes!",
          link: "/en/teachers/twenty-two",
        },
        {
          src: "https://monglish.b-cdn.net/experts/29.jpg",
          name: "Al-Shaimaa Samir",
          desc: "Hello! I am Shaimaa, an English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and have 7 years of teaching experience. I also hold a General Diploma in Education for Teaching English. My passion is teaching English, and I love playing and having fun with children. I believe that teaching through games and discussions is more effective and fun. My classes are based on group work activities, where students exchange ideas and express themselves better without fear. I am waiting for you to join my classes!",
          link: "/en/teachers/twenty-nine",
        },
        {
          src: "https://monglish.b-cdn.net/experts/25.jpg",
          name: "Eman Mohamed",
          desc: "Hello.. I am Eman, a teacher English at Monglish International Academy. I graduated from the Faculty of Agriculture, and I have two and a half years of experience in teaching English, and I hold a TEFL certificate. I am a hardworking and passionate teacher, and I always strive to develop myself to provide a service that exceeds expectations. My classes are characterized by creative activities, and students have a lot of fun while learning with me.",
          link: "/en/teachers/twenty-five",
        },
        {
          src: "https://monglish.b-cdn.net/experts/23.jpg",
          name: "Fatima Abdelkader",
          desc: "Hello.. I am Fatima Abdelkader, an English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and I have 13 years of experience in teaching English. I also hold a CELTA certificate in teaching English as a foreign language. My classes are characterized by group work activities where students exchange ideas and collaborate in a safe and stress-free learning environment. Excited to see you all in my classes!",
          link: "/en/teachers/twenty-three",
        },
        {
          src: "https://monglish.b-cdn.net/experts/30.jpg",
          name: "Mona Al-Aleem",
          desc: "Hello... I'm Mona, a CELTA certified English teacher from Monglish International Academy. I graduated from the Faculty of Education, English Department, and have 5 years of teaching experience. My classes feature group work activities where students exchange ideas and collaborate in a stress-free learning environment. I strive to create a supportive and engaging environment where everyone feels comfortable sharing their ideas and opinions. Excited to see you all in my classes!",
          link: "/en/teachers/thirty",
        },
        {
          src: "https://monglish.b-cdn.net/experts/24.jpg",
          name: "Hadeer Abdel Ghaffar",
          desc: "Hello! I'm Hadeer Abdel Ghaffar, an English teacher at Monglish International Academy. With over three years of experience and a TEFL certification, I'm passionate about empowering young learners on their English language journey. I'm a proud graduate of the Faculty of Languages, Ain Shams University, where I majored in Chinese with a minor in Spanish, adding a global perspective to my lessons. My lessons are interactive and dynamic, with a focus on creativity and a supportive environment that uses only English. I look forward to helping you reach new levels of confidence and success in English. Excited to embark on this educational journey with you all!",
          link: "/en/teachers/twenty-four",
        },
        {
          src: "https://monglish.b-cdn.net/experts/1.jpg",
          name: "Naela Abdullah",
          desc: "Hello, I'm Naela. I'm a certified EFL trainer from the USA working at Monglish International Academy. I love creating a fun learning environment and helping students on their journey to a bright future through English proficiency. I'm excited to help you on your English learning journey",
          link: "/en/teachers/one",
        },
        {
          src: "https://monglish.b-cdn.net/experts/6.jpg",
          name: "Kaitlyn Annette",
          desc: "Hello, my name is Kaitlyn. I'm a teacher here at Monglish International Academy and I have over 10 years of experience in both America and Egypt.. Specialized in early childhood education and my passion is to make the learning process fun for children.. In my classes I use interactive games, songs and dance with children.. The most important thing for me is that children have fun in the class and that is what I do here at Monglish.. I can't wait to meet you in my classes.. See you there",
          link: "/en/teachers/six",
        },
        {
          src: "https://monglish.b-cdn.net/experts/8.jpg",
          name: "Mona Abdel-Qawy",
          desc: "Hello everyone, I am Ms. Mona, an English teacher at Monglish International Academy. I have a BA in Linguistics and English Literature from Alexandria University. In my classes, I always strive to make learning fun by incorporating videos, games and songs, which keeps my students excited and engaged. I can't wait to see you all in my classes!",
          link: "/en/teachers/eight",
        },
        {
          src: "https://monglish.b-cdn.net/experts/4.jpg",
          name: "Kholoud Refaat",
          desc: "Hello.. I am Kholoud, an IELTS trainer and English teacher at Monglish International Academy. I graduated from the Faculty of Arts, English Department, and have 8 years of experience in teaching English, in addition to a diploma in teaching English as a foreign language. My classes feature group work activities where students exchange ideas and collaborate in a safe and stress-free learning environment. I am excited to see you all in my classes!",
          link: "/en/teachers/four",
        },
      ],
    };
  },
  methods: {
    navigateToTeachers() {
      this.$router.push("/en/teachers");
    },
    truncateDesc(desc) {
      if (desc.length > 200) {
        return desc.slice(0, 130) + "...";
      }
      return desc;
    },
  },
};
</script>