<template>
  <div class="teacher_page">
    <div class="teacher_container">
      <!-- Right Column (Image and Stats) -->
      <div class="right_column">
        <div class="teacher_image">
          <img
            loading="lazy"
            :src="teacher1.src"
            :alt="'Teacher ' + teacher1.name"
          />
        </div>
        <div class="teacher_stats">
          <div>
            <span class="stat_number">{{ teacher1.students }}</span>
            <br />
            <span class="stat_label">Student Satisfaction Rate</span>
          </div>
          <div>
            <span class="stat_number">+{{ teacher1.lessons }} %</span><br />
            <span class="stat_label">Student Success Rate</span>
          </div>
        </div>
      </div>

      <!-- Left Column (Teacher Info and Button) -->
      <div class="left_column">
        <button class="subscribe_button" @click="scrollToPackages">
          Subscribe Now
        </button>
        <div class="teacher_info">
          <h4 class="teacher_greeting">Hello, I'm</h4>
          <h2 class="teacher_name">{{ teacher1.name }}</h2>
          <p class="experience_button">{{ teacher1.experienceText }}</p>
          <hr />
          <p class="teacher_description">{{ teacher1.desc }}</p>
          <hr />
          <h3>My Qualifications</h3>
          <div class="teacher_tags">
            <span v-for="(tag, index) in teacher1.tags" :key="index">
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <PackagesAr ref="packagesAr" />

  <div class="promise-sec3-space">
    <div class="promise-sec3">
      <div class="promise-sec3-inner">
        <div class="text-content-promise">
          <h3>We are always committed to developing our teachers' skills</h3>
          <h4>To reach the best possible level</h4>
          <p>
            That is why CPD training is held every week for all teachers at
            BRITISHY
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PackagesAr from "../../views/en/PackagesPage.vue";

export default {
  name: "TeacherPage",
  data() {
    return {
      teacher1: {
        src: "https://monglish.b-cdn.net/experts/6.jpg",
        name: "Kaitlyn Annette",
        desc: "Hello, my name is Kaitlyn. I'm a teacher here at Monglish International Academy and I have over 10 years of experience in both America and Egypt.. Specialized in early childhood education and my passion is to make the learning process fun for children.. In my classes I use interactive games, songs and dance with children.. The most important thing for me is that children have fun in the class and that is what I do here at Monglish.. I can't wait to meet you in my classes.. See you there",
        students: 9.4,
        lessons: 80,
        experienceText: "9+ years of experience",
        tags: [
          "BA in English Literature",
          "Teaching IGCSE",
          "Teaching ESL/EFL",
          "Native Speaker",
        ],
      },
    };
  },
  components: {
    PackagesAr,
  },
  methods: {
    scrollToPackages() {
      this.$refs.packagesAr.$el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.teacher_page {
  background-image: url("https://monglish.b-cdn.net/home/faq_bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -85px;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 11%;
}

.teacher_container {
  display: grid;
  grid-template-columns: 0.5fr 1.6fr;
  gap: 50px;
  max-width: 95%;
  width: 100%;
}

.left_column,
.right_column {
  display: flex;
  flex-direction: column;
}

.right_column {
  height: 90vh;
}

.teacher_image {
  max-width: 100%;
  height: 45%;
  margin-bottom: 20px;
}

.teacher_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.teacher_stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 55%;
}

.teacher_stats div {
  background-color: white;
  border-radius: 20px;
  padding: 10px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 27%;
}

.stat_number {
  font-size: 2.3rem;
  font-weight: bold;
  color: #f47d21;
}

.stat_label {
  font-size: 1.2rem;
  color: #3d3d3d;
}

.left_column {
  display: flex;
  flex-direction: column;
  background-color: #f2f4f5;
  border-radius: 25px;
  height: 70vh;
  padding-inline: 40px;
}

.teacher_info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.teacher_greeting {
  font-size: 1.8rem;
  color: #165e84;
  margin-top: -40px;
}

.teacher_name_with_button {
  display: flex;
  align-items: center;
  gap: 15px;
}

.teacher_name {
  font-size: 2.4rem;
  font-weight: bold;
  color: #165e84;
}

.subscribe_button_inline {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  width: auto;
  min-width: 120px;
}

.experience_button {
  background-color: transparent;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
  color: #165e84;
  font-size: 1.4rem;
  border: 1px solid #165e84;
  border-radius: 25px;
  padding: 0.1em 1em 0.4em 1em;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  width: 25%;
}

.teacher_description {
  font-size: 1.4rem;
  color: #3d3d3d;
  line-height: 1.6;
}

.teacher_tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.teacher_tags span {
  background-color: transparent;
  border: 1px solid #3d3d3d;
  border-radius: 20px;
  padding: 3px 25px 6px 25px;
  font-size: 0.9rem;
  color: #3d3d3d;
}

.subscribe_button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 30%;
  cursor: pointer;
  font-size: 20px;
  margin-top: 60px;
  align-self: flex-end;
}

h3 {
  color: #555555;
}

/* Mobile View - Media Query */
@media screen and (max-width: 768px) {
  .teacher_container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .right_column,
  .left_column,
  .teacher_page,
  .teacher_stats div {
    height: auto;
  }
  .right_column {
    padding-top: 50px;
  }
  .teacher_image {
    max-width: 100%;
    height: 50%;
    margin-bottom: 20px;
  }
  .teacher_stats {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .teacher_info {
    gap: 10px;
  }

  .subscribe_button {
    width: 100%; /* Make subscribe button take full width */
    margin-top: 20px; /* Reduce top margin for button */
  }

  .experience_button {
    width: 100%; /* Make the experience button full width */
  }

  .teacher_name {
    font-size: 2rem; /* Adjust font size for mobile */
  }

  .teacher_greeting {
    font-size: 1.6rem; /* Adjust font size for mobile */
    margin-top: 0;
  }

  .teacher_description {
    font-size: 1.2rem; /* Adjust font size for mobile */
  }

  h3 {
    font-size: 1.5rem; /* Adjust heading font size */
  }

  .teacher_tags {
    justify-content: center; /* Center the tags on mobile */
  }

  .teacher_tags span {
    font-size: 0.8rem; /* Adjust font size for mobile tags */
    margin-bottom: 5px;
  }
}

.promise-sec3-space {
  padding: 2% 5% 2% 5%;
  background-color: #fff;
}
.promise-sec3 {
  position: relative;
  width: 95%;
  height: 65vh;
  background-image: url("https://monglish.b-cdn.net/home/teacherpp.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row;
  text-align: center;
  background-color: #fff;
}
.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5%;
}
.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: right;
}
.text-content-promise h3 {
  font-size: 2em;
  font-family: "DIN Next LT Arabic";
  font-weight: 500;
}
.text-content-promise h4 {
  font-size: 3em;
  font-family: "DIN Next LT Arabic-n";
  font-weight: 700;
}
.text-content-promise p {
  font-size: 1.1em;
  max-width: 45%;
  margin-top: 15px;
  font-family: "DIN Next LT Arabic", sans-serif;
  font-weight: 500;
}
@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column;
    height: auto;
  }

  .text-content-promise p {
    max-width: 100%;
  }
}
</style>
