<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>تستثمر أكاديمية مونجلش الدولية في نمو فريقها من خلال ورش عمل ديناميكية في القيادة والإدارة. إليكم لمحة عن
            التزامنا بترسيخ ثقافة التطوير المستمر لفريق العمل من القادة والمديرين.<br><br></p>

          <p>نسعى دائماً في أكاديمية مونجلش الدولية إلى تطوير مهارات فريقنا وتعزيز قدراتهم من خلال تقديم ورش عمل متخصصة
            تهدف إلى بناء قادة المستقبل. نحن ندرك أن النجاح يبدأ من الداخل، ولذلك نحرص على توفير بيئة تعليمية ملهمة تشجع
            على الابتكار والتفكير الإبداعي.<br><br></p>

          <p>من خلال هذه الورش، يتمكن أعضاء فريقنا من اكتساب رؤى جديدة واستراتيجيات فعّالة تمكنهم من تحقيق التفوق في
            مجالاتهم المختلفة. نحن نؤمن بأن التعليم المستمر هو أساس التفوق، لذا نعمل بلا كلل لتزويد فريقنا بأحدث الأدوات
            والمعرفة اللازمة للنجاح في عالم سريع التغير.<br><br></p>

          <p>نتطلع دائمًا إلى المستقبل بثقة وإصرار على عن طريق تحقيق أعلي معايير الجودة والتميّز في كل ما نقوم به،
            إيمانًا منا بأن الاستثمار في فريقنا هو استثمار في مستقبلنا.<br><br></p>

          <div class="image-row">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p5.webp" class="small-photo-in-row" alt="Image 1">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p6.webp" class="small-photo-in-row" alt="Image 2">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p7.webp" class="small-photo-in-row" alt="Image 3">
            <img loading="lazy" src="https://monglish.b-cdn.net/blogs/p8.webp" class="small-photo-in-row" alt="Image 4">
          </div>
        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "الأستثمار فى فريق العمل بمونجلش",
      date: "05/27/2024",
      img: 'https://monglish.b-cdn.net/blogs/ar-14.webp',
      category: "فعاليات",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: 'https://monglish.b-cdn.net/blogs/ar-1.webp',
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: 'https://monglish.b-cdn.net/blogs/ar-2.webp',
        link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
        Date: '9 يونيو ,2024',
        language: 'فعاليات',
        },
    {
      id: 5,
      title: 'كيف اتعلم اللغة الانجليزية',
      image: 'https://monglish.b-cdn.net/blogs/ar-3.webp',
      link: 'ar/blog/learning-english-in-early-ages/',
      Date: '29 مايو ,2024',
      language: 'ُقافة اللغة الانجليزية',
    },
    {
      id: 6,
      title: 'انجاز جديد فى مشوار دكتور النجار',
      image: 'https://monglish.b-cdn.net/blogs/ar-4.webp',
      link: '/ar/blog/new-achievement-in-dr-najjars-career',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
    {
      id: 3,
      title: 'الاحتفال الشهرى بالموظف المثالى',
      image: 'https://monglish.b-cdn.net/blogs/ar-5.webp',
      link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
      Date: '28 مايو ,2024',
      language: 'فعاليات',
    },
      ],
  };
},

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
}

.main-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  direction: rtl;
  padding: 5% 15% 0 15%;
}

.content-column {
  padding: 30px;
  border-radius: 12px;
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.blog-cards-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%;
  box-sizing: border-box;
}

.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}

.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}

.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view h2 {
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog-view p {
  margin-bottom: 18px;
  font-size: 18px;
}

.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}

.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}

.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 20px 0;
}

.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }

  .content-column {
    padding: 20px;
  }

  .cards-column {
    display: none;
  }

  .blog-view h3 {
    font-size: 24px;
  }

  .blog-view h4 {
    font-size: 20px;
  }
}

.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.small-photo-in-row {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0;
}
</style>