<template>
  <section class="home-reasons">
    <div class="content">
      <div class="t1">12 سبب <br>لإختيارك لمونجلش</div>
      <div class="t2">تعلم اللغة الإنجليزية يفتح الأبواب لغرض وظيفية وتواصل دولي فعال.</div>
      <div class="one-line gap-3">
        <MainButton @click="goToPackages">قائمة الأسعار</MainButton>
        <a href="/ar/monglish-reasons/">
          <button class="info-btn one-line">
            المزيد من المعلومات <i class="fa-solid fa-arrow-left"></i>
          </button>
        </a>
      </div>
    </div>
    <div class="carousel-container">
      <button @click="next" class="carousel-nav2 nexttttt">
          <i class="fa-solid fa-chevron-right"></i>
      </button>
      <Carousel
          ref="carousel"
          :itemsToShow="itemsToShow"
          :wrapAround="true"
          :mouseDrag="true"
          :autoplay="4000"
          :transition="500"
          :gap="20"
      >
        <Slide v-for="(reason, index) in reasons" :key="index">
          <img :src="reason.img" class="reason-image" :alt="`سبب ${index + 1} لاختيار مونجلش`" />
        </Slide>
      </Carousel>
      <button @click="prev" class="carousel-nav2 prevvvvv">
          <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import MainButton from "@/components/Elements/MainButton.vue";

export default {
  components: {
    Carousel,
    Slide,
    MainButton
  },
  data() {
    return {
      reasons: Array.from({ length: 12 }, (_, i) => ({
        img: `https://monglish.b-cdn.net/home/reason/${i + 1}.webp`
      })),
    };
  },
  computed: {
    itemsToShow() {
      return window.innerWidth <= 768 ? 1 : 3;
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateItemsToShow);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateItemsToShow);
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    goToPackages(){
      this.$router.push({ name: 'PackagesAr' });
    },
    updateItemsToShow() {
      this.itemsToShow = window.innerWidth <= 768 ? 1 : 2;
    }
  }
};
</script>

<style src="@/assets/styles/ar/Reasons.css" scoped></style>