<template>
  <div class="faq_page">
    <div class="faq_text">
      <h3>دليلك الشامل</h3>
      <h2>تصفح قسم الأسئلة الشائعة</h2>
      <p>واحصل على كل المعلومات التي تحتاجها</p>
    </div>
  </div>

  <div class="faq-total-items">
    <ThreePart1 />
    <ThreePart2 />
  </div>
</template>

<script>
import ThreePart1 from "../../components/ar/ThreePart1.vue";
import ThreePart2 from "../../components/ar/ThreePart2.vue";

export default {
  name: "FAQPage",
  components: {
    ThreePart1,
    ThreePart2,
  },
};
</script>

<style src="@/assets/styles/ar/faq.css" scoped></style>
