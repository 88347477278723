<template>
  <BlogListOne />

  <div class="blog-filter">
    <h3 class="subtitle">Latest Posts</h3>
    <div class="language-buttons">
      <button v-for="language in languages" :key="language" @click="selectLanguage(language)"
        :class="{ active: selectedLanguage === language }">
        {{ language }}
      </button>
    </div>
  </div>

  <div class="blog-list">
    <div class="blog-card-container">
      <div v-for="post in filteredBlogCategory" :key="post.id" @click="navigateTo(post.link)" class="blog-card-2">
        <img loading="lazy" :src="post.image" alt="Blog Image" class="blog-card-image" />
        <div class="blog-card-content">
          <h3 class="blog-card-title">{{ post.title }}</h3>
          <span class="blog-card-date">{{ post.Date }}</span>
          <p class="blog-card-description">{{ truncatedDescription(post.description) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogListOne from '@/components/en/BlogListOne.vue';

export default {
  name: 'BlogList',
  components: {
    BlogListOne
  },
  data() {
    return {
      selectedLanguage: 'All blogs',
      languages: ['All blogs', 'Uncategorized'],
      blogPosts: [
        {
          id: 1,
          title: 'Learning English in Early Ages',
          image: 'https://monglish.b-cdn.net/blogs/blogone.webp',
        link: '/en/blog/learning-english-in-early-ages',
        Date: 'February 28, 2024',
        language: 'Uncategorized',
        description: 'Research shows that the period from early childhood to puberty is a sensitive period for language learning in general. Children benefit from an innate ability to absorb languages quickly and easily at this stage.',
        },
    {
      id: 2,
      title: "Celebrating Children’s Language Progress.",
      image: 'https://monglish.b-cdn.net/blogs/blog-2.webp',
      link: '/en/blog/celebrating-childrens-language-progress',
      Date: 'March 3, 2024',
      language: 'Uncategorized',
      description: 'Language is a fundamental skill for learning, communication, and success in life. It is essential for interacting with others, expressing oneself, and acquiring knowledge. Therefore, it is important to celebrate children’s achievements in their language goals and progress.',
    },
    {
      id: 3,
      title: 'Seasons of The Year',
      image: 'https://monglish.b-cdn.net/blogs/blog3.webp',
      link: '/en/blog/seasons-of-the-year',
      Date: 'February 28, 2024',
      language: 'Uncategorized',
      description: 'Hello, adventurous language learners! Are you ready for a journey around the world? No, we won’t be boarding a plane or hopping on a ship. It’s a very special journey—a journey of exploration through the four seasons in English!',
    },
    {
      id: 4,
      title: 'Parents and Children Healthy Relationships',
      image: 'https://monglish.b-cdn.net/blogs/blog4.webp',
      link: '/en/blog/parents-and-children-healthy-relationships',
      Date: 'March 3, 2024',
      language: 'Uncategorized',
      description: 'Building healthy relationships between parents and children is essential for the proper growth and development of children. These relationships help promote mutual trust and respect between the parties involved and contribute to the development of children’s social and emotional skills.',
    },
    {
      id: 5,
      title: 'Activities For Positive Behavior',
      image: 'https://monglish.b-cdn.net/blogs/blog5.webp',
      link: '/en/blog/activities-for-positive-behavior',
      Date: 'February 29, 2024',
      language: 'Uncategorized',
      description: 'Playing is an essential part of children’s lives, helping them to grow, learn, and develop their social and emotional skills. Therefore, it is important to choose games and activities that promote positive behavior and interaction among children.',
    },
    {
      id: 6,
      title: 'Benefits Of Bilingual For Children',
      image: 'https://monglish.b-cdn.net/blogs/blog6.webp',
      link: '/en/blog/benefits-of-bilingual-for-children',
      Date: 'February 29, 2024',
      language: 'Uncategorized',
      description: '“Bilingualism has become an important topic in child rearing in this modern age. Many parents believe that understanding multiple languages enhances children’s cognitive and social abilities. In this article, we will take a comprehensive look at children’s bilingual language learning and review the positives and challenges associated with it. We will delve into the findings of multiple studies shedding light on the importance of raising bilingual children and its impact on their linguistic and cognitive development.',
    },
      ],
  };
},
computed: {
  filteredBlogCategory() {
    if (this.selectedLanguage === 'All blogs') {
      return this.blogPosts;
    }
    return this.blogPosts.filter((post) => post.language === this.selectedLanguage);
  },
},
methods: {
  navigateTo(link) {
    this.$router.push(link);
  },
  selectLanguage(language) {
    this.selectedLanguage = language;
  },
  truncatedDescription(description) {
    return description.length > 120 ? description.substring(0, 120) + '...' : description;
  },
},
};
</script>

<style scoped>
.blog-filter {
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 220px;
}

.subtitle {
  text-align: center;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 10px;
}

.language-buttons {
  display: flex;
  justify-content: center;
  direction: ltr;
  margin-bottom: 0;
}

.language-buttons button {
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin: 0 10px;
  padding: 4px 12px;
  color: #666;
}

.language-buttons button.active {
  background-color: #216488;
  color: #fff;
}

.blog-list {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  direction: ltr;
  margin-top: 0;
}

.blog-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  background-color: #fff;
  transform: scale(0.8);
  margin-top: -5%;
  margin-bottom: -3%;
}

.blog-card-2 {
  max-width: 460px;
  border-radius: 0;
  overflow: hidden;
  direction: ltr;
  cursor: pointer;
}

.blog-card-image {
  width: 100%;
  max-width: 460px;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 15px;
}

.blog-card-content {
  padding: 16px;
}

.blog-card-title {
  margin: 0 0 8px;
  font-size: 1.7em;
  font-weight: bold;
}

.blog-card-date {
  display: block;
  color: #9c9c9c;
  font-size: 1.1em;
  direction: ltr;
  font-family: 'DIN Next LT Arabic';
}

.blog-card-description {
  margin: 8px 0 0 0;
  font-size: 1em;
  color: #666;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 28px;
  }

  .language-buttons button {
    padding: 4px 8px;
    font-size: 14px;
  }

  .blog-list {
    gap: 15px;
  }

  .blog-card-container {
    transform: scale(1);
    margin-top: 0;
    margin-bottom: 0;
  }

  .blog-card-2 {
    max-width: 100%;
  }

  .blog-card-title {
    font-size: 1.4em;
  }

  .blog-card-date {
    font-size: 1em;
  }

  .blog-card-description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .blog-filter {
    padding-top: 0;
    margin-top: -20px;
  }

  .subtitle {
    font-size: 24px;
  }

  .language-buttons {
    flex-direction: column;
  }

  .language-buttons button {
    margin: 0;
  }

  .blog-card-title {
    font-size: 1.2em;
  }

  .blog-card-date {
    font-size: 0.9em;
  }

  .blog-card-description {
    font-size: 0.8em;
  }
}
</style>
