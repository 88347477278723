<template>
  <div class="slider-3d">
    <h2>شركاء النجاح</h2>
    <div class="carousel-container" ref="swiperContainer">
      <button class="slider-nav left" @click="moveLeft">
        <i class="fa-solid fa-circle-chevron-left"></i>
      </button>

      <transition-group name="slide" tag="div" class="carousel-track" mode="out-in">
        <div v-for="(item, index) in generateItems()" :key="index" :class="['item', 'level' + item.level]">
          <img loading="lazy" :src="item.src" :alt="'Image ' + index" class="slider-image" />
        </div>
      </transition-group>

      <button class="slider-nav right" @click="moveRight">
        <i class="fa-solid fa-circle-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurEmployee',
  data() {
    return {
      active: 0,
      items: this.loadImages('employee', 74),
      autoSlideInterval: null,
    };
  },
  mounted() {
    this.startAutoNavigate();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    loadImages(folder, count) {
      return Array.from({ length: count }, (_, index) =>
        `https://monglish.b-cdn.net/${folder}/ar/${index + 1}.webp`
      );
    },
    startAutoNavigate() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 4000);
    },
    generateItems() {
      const items = [];
      const isMobile = window.innerWidth <= 768;
      const totalItems = isMobile ? 1 : 3; 
      for (let i = 0; i < totalItems; i++) {
        let index = (this.active + i) % this.items.length;
        items.push({
          src: this.items[index],
          level: i - 1 
        });
      }
      return items;
    },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    }
  }
}
</script>

<style src="@/assets/styles/employee.css"></style>