<template>
  <div class="immerse-sec1">
    <div class="immerse-sec1-content">
      <h1>Mongi-Immerse</h1>
      <p>The Mongi-Immerse methodology is the ideal solution to ensure that learners receive the highest levels of
        education and training in the English language through daily language acquisition activities. Once you join us,
        you will receive the necessary guidance to put your language skills into practice, which will help you excel in
        real-life situations and study with confidence. We cater to all ages, starting from age 5, and have achieved
        excellent results in the young learners category!</p>
    </div>
  </div>
  <div class="immerse-sec2">
    <div class="immerse-row">
      <h3>Get to Know Monglish Interactive Clubs</h3>
      <p>
        "Discover the unique Mongi-Immerse approach at Monglish, where we fully immerse you in the world of the English
        language. Our learning experience goes beyond traditional boundaries, with intensive and continuous interaction
        with English in real-life scenarios, ensuring enhanced understanding and smooth language mastery."
      </p>
    </div>

    <ImmerseClubs />
  </div>

  <div class="video-immerse">
    <div class="content-slider-container">
      <!-- Text Content Section -->
      <div class="text-content">
        <h1>A Joyful Learning Experience</h1>
        <p>Are you looking for an innovative way to learn English? Monglish Academy is the perfect place for you! We
          offer a cutting-edge educational approach that utilizes the latest and most interactive technologies. By
          employing innovative teaching methods, we make learning an enjoyable and motivating experience. Whether you're
          a beginner or advanced, you'll find everything you need at our academy to achieve your goals.</p>
      </div>
      <!-- Slider Section -->
      <div class="video-slider-component" ref="videoWrapper">
        <div class="slider-container">
          <button class="slider-nav left" @click="prevSlide">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </button>
          <div class="image-container">
            <img :src="currentImage.src" :alt="currentImage.alt" @click="openPopup(currentImage.videoSrc)"
              class="image-thumbnail" />
            <svg height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 491.444 491.444" xml:space="preserve"
              fill="#000000" class="video-icon">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path style="fill:#FF9D52;"
                  d="M0.11,245.721c-1.434,61.225,11.068,122.791,39.645,145.883c80.101,64.726,331.832,64.726,411.932,0c28.577-23.093,41.079-84.659,39.646-145.883H0.11z">
                </path>
                <path style="fill:#F47D21;"
                  d="M451.687,105.91c-72.82-72.82-339.111-72.82-411.932,0C15.231,130.434,1.463,187.925,0.11,245.721h491.223C489.98,187.925,476.213,130.434,451.687,105.91z">
                </path>
                <path style="fill:#FFFFFF;"
                  d="M320.941,232.105L190.51,136.284c-11.159-8.199-26.9-0.231-26.9,13.617v95.82h164.225C327.834,240.6,325.537,235.481,320.941,232.105z">
                </path>
                <path style="fill:#FFFFFF;"
                  d="M190.51,355.161l130.432-95.823c4.595-3.375,6.893-8.496,6.893-13.617H163.609v95.824C163.609,355.391,179.351,363.359,190.51,355.161z">
                </path>
              </g>
            </svg>
          </div>

          <button class="slider-nav right" @click="nextSlide">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Popup Overlay -->
      <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
        <div class="popup-content" @click.stop>
          <button class="close-button" @click="closePopup">&times;</button>
          <iframe v-if="currentVideoSrc" :src="currentVideoSrc" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen class="popup-video"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="immerse-sec4-en">
    <div class="immerse-sec4-content-en">
      <div class="immerse-sec4-text-en">
        <h2>Where Educational Excellence Meets Innovation and Linguistic Advancement</h2>
        <p>By choosing Monglish Academy, you benefit from an innovative teaching methodology, a variety of courses
          suitable for all age groups, and specialized guidance ensuring you achieve the highest levels of success in
          your linguistic and professional journey.</p>

        <div @click="toggleDescription(1)" class="immerse-sec4-title-en" :class="{ active: activeDescription === 1 }">
          <span>Diplomas Coverage</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 1">
          At Monglish Academy, we focus on sustainable education through comprehensive diplomas for all ages, with
          continuous updates to enhance the academic and professional paths of our trainees. We offer professional
          courses suitable for all age levels, enhancing our graduates' standing in life and work. Our distinguished
          learning environment and specialized team support students in achieving linguistic and professional
          excellence.
        </p>

        <div @click="toggleDescription(2)" class="immerse-sec4-title-en" :class="{ active: activeDescription === 2 }">
          <span>Our Innovative Curriculum</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 2">
          Immerse yourself in the English learning journey with Monglish’s innovative immers eion curriculum, where we
          blend enjoyment and effectiveness to help you learn English as a second language faster than expected. At
          Monglish, you'll meet experienced teachers who share their expertise with enthusiasm, providing you with the
          tools to master the language efficiently. We stand out with our commitment to continuous innovation, keeping
          up with the latest educational methods and applying innovative solutions to challenges faced by students. Our
          comprehensive curriculum includes diverse activities in listening, reading, writing, and speaking, offering
          exercises designed for all levels. We provide personalized assessments to help students progress smoothly and
          enjoyably through various language levels, regardless of their current proficiency, ensuring continuous and
          rapid development in mastering English.
        </p>

        <div @click="toggleDescription(3)" class="immerse-sec4-title-en" :class="{ active: activeDescription === 3 }">
          <span>Interactive Clubs</span>
          <span class="caret"><svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon" version="1.1"
              xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#6f8286"></path>
              </g>
            </svg></span>
        </div>
        <p v-if="activeDescription === 3">
          At Monglish, we pride ourselves on offering a wide and diverse range of specialized clubs for learning
          English, including reading, writing, and conversation clubs, as well as cinema, debate, and talent clubs. We
          also provide specialized clubs such as phonics, writing, grammar, dialect competitions, drawing, and a unique
          social club for mothers. Each club enhances the others, creating a comprehensive educational experience that
          ensures the highest results and establishes us as the leading academy for learning English. Our social club
          for mothers offers a space for communication and sharing challenges in a supportive and entertaining
          environment. These exclusive clubs enable us to deliver an exceptional learning experience, making Monglish
          the ideal destination for learning English online.
        </p>
      </div>
      <div class="immerse-sec4-images-en">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/immerse_sec4.jpg" alt="Immerse Section">
      </div>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="progress" :style="{ height: progressHeight + '%' }"></div>
    </div>
  </div>

  <!--Section Four Content-->
  <div class="kids-sec4">
    <h3>Internationally Accredited</h3>
    <h1>High Quality at Every Step</h1>
    <p>Monglish International Academy is internationally accredited by prominent institutions such as the International
      Organization for Training Quality (IOTQ) and is ISO 9001:2015 certified by the American Accreditation Association
      (UAF). We are also members of the American Accreditation Association (AAA), the Egyptian Society for Quality
      (ESQ), and the Arab Organization for Quality (AROQA) under the umbrella of Talal Abu-Ghazaleh (TAG-ORG),
      reflecting our commitment to global standards in higher education.</p>
    <div class="kids-sec4-imgs">
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/6.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/3.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/2.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/1.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/4.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/7.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/5.webp" />
      <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/9.webp" />
    </div>
  </div>

  <ImageVideoSlider />
</template>

<script>
import ImmerseClubs from '../../components/en/ImmerseClubs.vue';
import ImageVideoSlider from '../../components/en/ImageVideoSlider2.vue';

export default {
  name: 'MonglishImmerse',
  components: {
    ImmerseClubs,
    ImageVideoSlider
  },
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      currentSlide: 0,
      images: [
        {
          videoSrc: 'https://www.youtube.com/embed/4qZMlbkrxLo?autoplay=1',
          src: 'https://monglish.b-cdn.net/video/13.webp',
          alt: 'Video 13 Thumbnail',
        },
        {
          videoSrc: 'https://www.youtube.com/embed/XZGeJQK7yxo?autoplay=1',
          src: 'https://monglish.b-cdn.net/video/14.webp',
          alt: 'Video 14 Thumbnail',
        },
      ],
      activeDescription: null,
      totalSections: 3,
      currentIndex: 0,
      isTransitioning: false,
      videos: [
        {
          src: 'https://www.youtube.com/embed/TOQPuaK2_Xw',
          loaded: false
        },
        {
          src: 'https://www.youtube.com/embed/JqnRuJf3gZE',
          loaded: false
        },
        {
          src: 'https://www.youtube.com/embed/_FOV2Spjsy4',
          loaded: false
        },
      ]
    };
  },
  computed: {
    displayedVideos() {
      const totalVideos = this.videos.length;
      return [
        this.videos[(this.currentIndex - 1 + totalVideos) % totalVideos],
        this.videos[this.currentIndex],
        this.videos[(this.currentIndex + 1) % totalVideos]
      ];
    },
    currentImage() {
      return this.images[this.currentSlide];
    },
  },
  methods: {
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.images.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.images.length) % this.images.length;
    },
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    slide(direction) {
      if (this.isTransitioning) return;
      this.isTransitioning = true;

      const wrapper = this.$refs.videoWrapper;
      const slideWidth = wrapper.clientWidth;
      const totalVideos = this.videos.length;

      if (direction === 'right') {
        this.currentIndex = (this.currentIndex + 1) % totalVideos;
        wrapper.style.transform = `translateX(-${slideWidth}px)`;
      } else {
        this.currentIndex = (this.currentIndex - 1 + totalVideos) % totalVideos;
        wrapper.style.transform = `translateX(${slideWidth}px)`;
      }

      setTimeout(() => {
        wrapper.style.transition = 'none';
        wrapper.style.transform = 'translateX(0)';
        setTimeout(() => {
          wrapper.style.transition = 'transform 0.5s ease-in-out';
          this.isTransitioning = false;
        }, 50);
      }, 500);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapper = this.$refs.videoWrapper;
      wrapper.style.width = '100%';
      wrapper.style.display = 'flex';
      wrapper.style.transition = 'transform 0.5s ease-in-out';
    });
  }
}
</script>

<style scoped>
/*Section 1*/
.immerse-sec1 {
  position: relative;
  /* Parent element must have relative position */
  color: white;
  text-align: center;
  background-image: url('https://monglish.b-cdn.net/home/immerse_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -85px;
  font-family: 'DIN Next LT Arabic';
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 500;
  direction: ltr;
}

.immerse-sec1-content {
  position: absolute;
  top: 20px;
  /* Adjust the value to your preference */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 80%;
  padding-top: 120px;
}

.immerse-sec1 h1 {
  font-size: 2.5rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.immerse-sec1 p {
  font-size: 1.375rem;
  font-weight: 500;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .immerse-sec1 {
    background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
    height: auto;
    padding: 0;
  }

  .immerse-sec1::before {
    content: '';
    background-image: url('https://monglish.b-cdn.net/home/immerse_bg.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 80vh;
    display: block;
  }

  .immerse-sec1-content {
    max-width: 100%;
    text-align: center;
    margin-top: -4em;
  }

  .immerse-sec1 h1 {
    font-size: 1.75rem;
  }

  .immerse-sec1 p {
    font-size: 1rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*Section 2*/
.immerse-sec2 {
  padding: 1em 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  direction: ltr;
}

.immerse-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2em;
  margin: 1em 0;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.immerse-row h3 {
  color: #165e84;
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.immerse-row p {
  font-size: 1.375rem;
  color: gray;
  font-weight: 500;
  max-width: 70%;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .immerse-row {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }

  .immerse-row h3 {
    width: 20%;
    margin: 0;
    padding-left: 50px;
  }

  .immerse-row p {
    width: 80%;
    margin: 0;
    padding-right: 25px;
  }
}

/*Section 3*/
.video-immerse {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  padding-left: 2em;
  padding-right: 2em;
}

.video-immerse h3 {
  text-align: center;
  font-size: 2.6em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  color: white;
  padding-bottom: 1em;
}

.immerse-video-frame {
  width: 100%;
  max-width: 890px;
  height: calc(56.25vw);
  max-height: 500px;
  border-radius: 25px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .video-immerse {
    padding: 2em;
  }

  .video-immerse h3 {
    font-size: 2em;
  }

  .immerse-video-frame {
    height: calc(56.25vw);
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .video-immerse {
    padding: 1.5em;
  }

  .video-immerse h3 {
    font-size: 1.6em;
  }

  .immerse-video-frame {
    height: calc(56.25vw);
    max-height: 220px;
  }
}

/*Section 4*/
.immerse-sec4-en {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #4e5354;
  position: relative;
  direction: ltr;
}

.immerse-sec4-content-en {
  display: flex;
  max-width: 1300px;
  margin: auto;
  padding: 20px;
}

.immerse-sec4-images-en img {
  width: 100%;
  height: auto;
}

.immerse-sec4-text-en {
  padding: 20px;
  max-width: 700px;
}

.immerse-sec4-text-en h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #165e84;
  font-family: 'DIN Next LT Arabic-n';
}

.immerse-sec4-text-en .immerse-sec4-title-en {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
  cursor: pointer;
  color: #6f8286;
  font-family: 'DIN Next LT Arabic';
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.immerse-sec4-text-en .immerse-sec4-title-en.active {
  color: #ff6f00;
}

.immerse-sec4-text-en .immerse-sec4-title-en.active {
  color: #ff6f00;
}

.immerse-sec4-text-en .caret svg path {
  fill: #6f8286;
  transition: fill 0.3s ease;
}

.immerse-sec4-text-en .immerse-sec4-title-en.active .caret svg path {
  fill: #ff6f00;
}

.immerse-sec4-text-en .caret {
  transition: transform 0.3s ease;
}

.immerse-sec4-text-en .immerse-sec4-title-en.active .caret {
  transform: rotate(180deg);
}

.immerse-sec4-text-en p {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6;
}

@media (max-width: 769px) {
  .immerse-sec4-content-en {
    width: 100%;
    padding: 5px;
  }

  .immerse-sec4-images-en img {
    display: none;
  }

  .immerse-sec4-text-en {
    width: 100%;
    padding: 0;
  }
}

/*section 6*/
.all {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
}

.home-sec4 {
  background-color: #fff;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  direction: ltr;
  padding: 2em;
}

.t1 {
  color: #165e84;
  font-size: calc(25px + 1vw);
  font-weight: 600;
  line-height: 100%;
  padding: 0 1em 1em 1em;
}

.video-slider {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 60%;
  margin: 0 auto;
  border-radius: 25px;
}

.video-wrapper-immerse {
  display: flex;
  width: 50%;
}

.video-frame-home {
  flex: 0 0 100%;
  border: none;
  height: 50vw;
  max-height: 500px;
  overflow: hidden;
}

.slider-nav {
  color: #165e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  color: #2178a6;
}

.slider-nav:active {
  color: #fff;
}

.size-6 {
  width: 24px;
  height: 24px;
}

.player .vp-video-wrapper-immerse .vp-preview {
  border-right: 20px;
}

@media (max-width: 1000px) {
  .home-sec4 {
    border-radius: 0;
  }

  .video-frame-home {
    width: 80%;
    margin-inline: 10%;
    flex: 0 0 80%;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }
}

.content-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  direction: ltr;
}

.text-content {
  flex: 1;
  padding-right: 5%;
}

.text-content h1 {
  color: white;
  font-size: 48px;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  margin-bottom: 3%;
}

.text-content p {
  color: white;
  font-size: 20px;
  width: 70%;
  margin-bottom: 2%;
}

.text-content button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 40%;
  cursor: pointer;
  font-size: 20px;
}

.video-slider-component {
  flex: 1;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-thumbnail {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: transform 0.3s ease;
  pointer-events: none;
}

.image-container:hover .video-icon {
  transform: translate(-50%, -50%) scale(1.5);
}

.slider-nav {
  color: white;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}

.slider-nav.left {
  left: 5vw;
}

.slider-nav.right {
  right: 5vw;
}

.slider-nav:hover {
  color: #2178a6;
}

.slider-nav:active {
  color: #fff;
}

.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  padding: 0;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  width: 56.25vw;
  /* 9:16 aspect ratio relative to viewport width */
  height: 100vh;
}

.popup-video {
  width: 100%;
  height: calc(100vw * (16 / 9));
  max-height: 100%;
  max-width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  color: #fff;

  cursor: pointer;
}

/* Section 5 style */
.kids-sec4 {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  direction: ltr;
  text-align: center;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2em 2em 8em 2em;
}

.kids-sec4 h3 {
  font-size: 2.5rem;
  padding: 3% 0 0 0;
}

.kids-sec4 h1 {
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.kids-sec4 p {
  font-size: 1.2rem;
  padding: 1% 10% 5% 10%;
}

.kids-sec4-imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.kids-sec4-imgs img {
  width: 9%;
}

@media (max-width: 1200px) {
  .kids-sec4 h3 {
    font-size: 2.2rem;
  }

  .kids-sec4 h1 {
    font-size: 2.5rem;
  }

  .kids-sec4 p {
    font-size: 1.1rem;
    padding: 1% 15% 5% 15%;
  }

  .kids-sec4-imgs img {
    width: 12%;
  }
}

@media (max-width: 992px) {
  .kids-sec4 h3 {
    font-size: 2rem;
  }

  .kids-sec4 h1 {
    font-size: 2.2rem;
  }

  .kids-sec4 p {
    font-size: 1rem;
    padding: 1% 10% 5% 10%;
  }

  .kids-sec4-imgs img {
    width: 15%;
  }
}

@media (max-width: 768px) {
  .kids-sec4 h3 {
    font-size: 1.8rem;
  }

  .kids-sec4 h1 {
    font-size: 2rem;
  }

  .kids-sec4 p {
    font-size: 0.9rem;
    padding: 1% 5% 5% 5%;
  }

  .kids-sec4-imgs img {
    width: 20%;
  }
}

@media (max-width: 576px) {
  .kids-sec4 h3 {
    font-size: 1.5rem;
  }

  .kids-sec4 h1 {
    font-size: 1.8rem;
  }

  .kids-sec4 p {
    font-size: 0.85rem;
    padding: 1% 3% 5% 3%;
  }

  .kids-sec4-imgs img {
    width: 25%;
  }
}

@media (max-width: 400px) {
  .kids-sec4 h3 {
    font-size: 1.2rem;
  }

  .kids-sec4 h1 {
    font-size: 1.5rem;
  }

  .kids-sec4 p {
    font-size: 0.75rem;
    padding: 1% 2% 5% 2%;
  }

  .kids-sec4-imgs img {
    width: 30%;
  }
}
</style>
