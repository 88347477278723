<template>
  <div class="accre-sec4">
    <div class="text-sec4-part1">
      <h3>Standards We Follow</h3>
      <h2>To Enhance Educational Excellence</h2>
      <p>
        Quality standards represent the primary reference standard for vocational training, ensuring the protection of the interests of stakeholders such as trainees and the broader community. They enhance the status of recognized training service providers in the field of quality. Based on this, the organization has established its quality standards to address significant challenges in the training field. monglish_frontend has been accredited by the International Organization for Training Quality (IOTQ) thanks to its commitment to IOTQ standards, summarized below.
      </p>
    </div>

    <div class="part2">
      <div class="part2-column">
        <h3>1. Quality</h3>
        <p>Quality standards for certified courses meet the requirements for issuing certificates and the value of these certificates in aligning with recognized quality standards across various sectors, both during training and after awarding the certificates.</p>
        <h3>2. Objectives</h3>
        <p>Certified courses are designed to provide an effective learning experience with precise evaluation of outcomes. Full support is provided to trainees from registration to obtaining the completion certificate, ensuring the achievement of training goals and success.</p>
        <h3>3. Design</h3>
        <p>We follow a series of fundamental procedures to ensure effective planning and execution of training processes, achieving smooth continuity and fruitful results in design, development, and certification. Internal and external reference sources are relied upon to formulate training materials.</p>
      </div>

      <div class="part2-column">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/acc/sec4.png" alt="Sec4 Image">
      </div>

      <div class="part2-column">
        <h3>4. Support</h3>
        <p>We develop well-studied plans for interacting with trainees, considering the alignment of these plans with their professional and personal aspirations. These entities also clarify their policies and procedures to trainees and trainers, providing full support for their professional and personal growth.</p>
        <h3>5. Collaboration</h3>
        <p>Ensuring comprehensive quality and continuous training standards. It will manage and oversee the development and management of cooperation with third-party entities, effectively monitoring collaboration agreements from all aspects.</p>
        <h3>6. Implementation</h3>
        <p>Accredited entities will design and develop training courses in collaboration with businesses, trainees, and other stakeholders (when needed) to ensure achieving applicable outcomes linked to the real world. They will also provide opportunities for "on-the-job training" wherever possible.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component options
}
</script>

<style scoped>
.accre-sec4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #165e84;
  background-color: #fff;
  padding: 20px;
  direction: ltr;
}

.text-sec4-part1 h3 {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 34px;
}

.text-sec4-part1 h2 {
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 48px;
}

.text-sec4-part1 p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 21px;
  max-width: 1200px;
  color: #8a8a8a;
  margin: 0 auto;
}

.part2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  flex-wrap: wrap;
}

.part2-column {
  flex: 1;
  padding: 0 10px;
  min-width: 300px;
  margin-bottom: 20px;
}

.part2-column p {
  color: #4a4a4a;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 140%;
  max-width: 90%;
  margin-bottom: 50px;
}

.part2-column h3 {
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
}

.part2-column img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .text-sec4-part1 h2 {
    font-size: 36px;
  }

  .text-sec4-part1 h3 {
    font-size: 28px;
  }

  .text-sec4-part1 p {
    font-size: 18px;
  }

  .part2-column h3 {
    font-size: 26px;
  }

  .part2-column p {
    font-size: 16px;
  }

  .part2-column {
    min-width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .text-sec4-part1 h2 {
    font-size: 28px;
  }

  .text-sec4-part1 h3 {
    font-size: 24px;
  }

  .text-sec4-part1 p {
    font-size: 16px;
  }

  .part2-column h3 {
    font-size: 22px;
  }

  .part2-column p {
    font-size: 14px;
  }

  .part2-column img {
    margin: 0 auto;
  }
}
</style>