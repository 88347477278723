<template>
  <VideoSliderFour />

  <div class="gallery-section">
    <p>عائلة مونجلش</p>
    <p>روح الفريق لدينا.. قصة نجاح مستمرة</p>
    <div class="gallery-grid">
      <img loading="lazy" src="https://monglish.b-cdn.net/home/gallery.png" class="full-height-img">
    </div>
  </div>
</template>

<script>
import VideoSliderFour from '../../components/ar/VideoSliderFour.vue';

export default {
  name: 'VideoView',
  components: {
    VideoSliderFour,
  },
};
</script>

<style scoped>
.gallery-section {
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  text-align: center;
}

.gallery-section p {
  font-size: 48px;
  font-weight: 400;
  line-height: 62px;
}

.gallery-grid {
  display: grid;
  margin: 40px 0 0 0;
  direction: ltr;
}

.gallery-section img {
  border-radius: 25px;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
}

.middle-left-column,
.middle-right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.full-height-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.top-img,
.bottom-img {
  object-fit: cover;
  width: 100%;
}

.middle-left-column .top-img {
  height: 65%;
  /* Adjusted for better fit */
}

.middle-left-column .bottom-img {
  height: 35%;
}

.middle-right-column .top-img {
  height: 65%;
  /* Adjusted for better fit */
}

.middle-right-column .bottom-img {
  height: 35%;
}

.right-column .full-height-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 320px) and (max-width: 479px) {

  .gallery-grid-mobile {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  .gallery-section {
    padding-inline: 0;
  }

  .gallery-section p {
    line-height: 20px;
  }

  .right-column .full-height-img {
    margin-top: 0%;
  }
}
</style>